import { request } from "../utils/request";
export namespace API_REPORT {
  export namespace v100001 {
    export namespace backUp {
      export interface HouseBackUpParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  业务人员id
         */
        businessId?: number;
        /**
         * @description  协助人员2
         */
        businessId2?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 收房业绩资料备份
       * @param params
       */
      export function houseBackUp(params: HouseBackUpParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/backUp/houseBackUp",
          { params }
        );
      }
      export interface HouseExitBackUpParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        endTimeStart?: string;
        /**
         * @description  1 正常退房 2没有签成 3房东违约 4公司违约
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房东退房审批列表资料备份
       * @param params
       */
      export function houseExitBackUp(params: HouseExitBackUpParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/backUp/houseExitBackUp",
          { params }
        );
      }
      export interface TenantsBackUpParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  协助人员2
         */
        businessName2?: string;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  户型/房型id
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  门牌号（集中楼层）
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 出房业绩资料备份
       * @param params
       */
      export function tenantsBackUp(params: TenantsBackUpParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/backUp/tenantsBackUp",
          { params }
        );
      }
      export interface TenantsExitBackUpParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  退房人
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  退房性质id
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
         */
        isMark?: number;
        /**
         * @description  超期退租标记月份
         */
        isMark1Month?: string;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 租客退房审批列表资料备份
       * @param params
       */
      export function tenantsExitBackUp(params: TenantsExitBackUpParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/backUp/tenantsExitBackUp",
          { params }
        );
      }
    }
    export namespace bargain {
      export interface ReportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  租客身份证
         */
        idCard?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  租客电话
         */
        phone?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1未签约，2已签约，3已退  4失效
         */
        statusId?: number;
        /**
         * @description  状态：1未签约，2已签约，3已退  4失效
         */
        statusShow?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  定金有效期
         */
        validityEndTime?: string;
      }
      /**
       * @name 定金记录报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<Bargain>>>(
          "/report-v100001/bargain/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  租客身份证
         */
        idCard?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  租客电话
         */
        phone?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  状态：1未签约，2已签约，3已退  4失效
         */
        statusId?: number;
        /**
         * @description  状态：1未签约，2已签约，3已退  4失效
         */
        statusShow?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  定金有效期
         */
        validityEndTime?: string;
      }
      /**
       * @name 定金记录报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/bargain/reportExport", { params });
      }
    }
    export namespace broadband {
      export interface ReportParams {
        /**
         * @description  宽带账号
         */
        account?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  到期查询时间
         */
        endTime?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  安装日期
         */
        installationTime?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  服务商id(系统字典)
         */
        serviceProvidersId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1，正常；2，停网；3，注销
         */
        status?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 宽带报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<Broadband>>>(
          "/report-v100001/broadband/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  宽带账号
         */
        account?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  到期查询时间
         */
        endTime?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  安装日期
         */
        installationTime?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  服务商id(系统字典)
         */
        serviceProvidersId?: number;
        /**
         * @description  状态：1，正常；2，停网；3，注销
         */
        status?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 宽带报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/broadband/reportExport", {
          params
        });
      }
    }
    export namespace broadbandSub {
      export interface AuditParams {
        /**
         * @description  审批备注
         */
        auditRemark?: string;
        /**
         * @description  审批状态
         */
        isAudit?: number;
        /**
         * @description  序号
         */
        serialNum?: string;
      }
      /**
       * @name 审批
       * @param id 审批
       * @param data
       */
      export function audit(id: string | number, data: AuditParams) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/broadbandSub/audit/${id}`,
          data
        );
      }
      export interface CancellationParams {
        /**
         * @description  取消备注
         */
        cancellationRemark?: string;
      }
      /**
       * @name 取消接口
       * @param id 取消接口
       * @param data
       */
      export function cancellation(
        id: string | number,
        data: CancellationParams
      ) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/broadbandSub/cancellation/${id}`,
          data
        );
      }
      export interface ExportParams {
        /**
         * @description  部门（大区）id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  审批状态：1已审核  2驳回  3未审核 4结束
         */
        isAudit?: number;
        /**
         * @description  是否跟进：1未跟进；2已跟进
         */
        isFollowUp?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  报送人id
         */
        submittedId?: number;
      }
      /**
       * @name 宽带报送表列表导出
       * @param params
       */
      export function exports(params: ExportParams) {
        return request.get("/report-v100001/broadbandSub/export", { params });
      }
      export interface ExportV1Params {
        /**
         * @description  部门（大区）id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  审批状态：1已审核  2驳回  3未审核 4结束
         */
        isAudit?: number;
        /**
         * @description  是否跟进：1未跟进；2已跟进
         */
        isFollowUp?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  报送人id
         */
        submittedId?: number;
      }
      /**
       * @name 宽带报送模板导出
       * @param params
       */
      export function exportV1(params: ExportV1Params) {
        return request.get("/report-v100001/broadbandSub/exportV1", { params });
      }
      export interface FollowUpParams {
        /**
         * @description  是否安装：1，未安装；2，已安装
         */
        isInstall?: number;
        /**
         * @description  光猫反面照片
         */
        opticalModemImg?: string;
        /**
         * @description  光猫串码
         */
        opticalModemNum?: string;
        /**
         * @description  备注
         */
        remark?: string;
      }
      /**
       * @name 跟进
       * @param id 跟进
       * @param data
       */
      export function followUp(id: string | number, data: FollowUpParams) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/broadbandSub/followUp/${id}`,
          data
        );
      }
      /**
       * @name 获取详情
       * @param id 获取详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<BroadbandSub>>(
          `/report-v100001/broadbandSub/info/${id}`
        );
      }
      /**
       * @name 宽带报送光猫串号导入
       */
      export function insertOpticalModemNum(data: any) {
        return request.post<ResponseResult<object>>(
          "/report-v100001/broadbandSub/insertOpticalModemNum",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
      export interface PageParams {
        /**
         * @description  部门（大区）id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  审批状态：1已审核  2驳回  3未审核 4结束
         */
        isAudit?: number;
        /**
         * @description  是否跟进：1未跟进；2已跟进
         */
        isFollowUp?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  报送人id
         */
        submittedId?: number;
      }
      /**
       * @name 宽带报送表列表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<BroadbandSub>>>(
          "/report-v100001/broadbandSub/page",
          { params }
        );
      }
      export interface ResubmitParams {
        /**
         * @description  再次报送备注
         */
        resubmitRemark?: string;
      }
      /**
       * @name 再次报送接口
       * @param id 再次报送接口
       * @param data
       */
      export function resubmit(id: string | number, data: ResubmitParams) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/broadbandSub/resubmit/${id}`,
          data
        );
      }
      export interface SaveParams {
        /**
         * @description  合同最后一页
         */
        contractEndImg?: string;
        /**
         * @description  合同第一页
         */
        contractStartImg?: string;
        /**
         * @description  门头照
         */
        doorHeaderImg?: string;
        /**
         * @description  房本
         */
        fangBen?: string;
        /**
         * @description  区域小区房号
         */
        houseAddress?: string;
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  业主电话
         */
        ownerPhone?: string;
        /**
         * @description  房间照
         */
        roomImg?: string;
        /**
         * @description  管家电话
         */
        stewardPhone?: string;
        /**
         * @description  报送人id
         */
        submittedId?: number;
        /**
         * @description  报送人名称
         */
        submittedName?: string;
        /**
         * @description  是否有效：1，有效，2无效
         */
        validOrNot?: number;
      }
      /**
       * @name 宽带报送添加
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/report-v100001/broadbandSub/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  合同最后一页
         */
        contractEndImg?: string;
        /**
         * @description  合同第一页
         */
        contractStartImg?: string;
        /**
         * @description  门头照
         */
        doorHeaderImg?: string;
        /**
         * @description  房本
         */
        fangBen?: string;
        /**
         * @description  区域小区房号
         */
        houseAddress?: string;
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  业主电话
         */
        ownerPhone?: string;
        /**
         * @description  房间照
         */
        roomImg?: string;
        /**
         * @description  管家电话
         */
        stewardPhone?: string;
        /**
         * @description  报送人id
         */
        submittedId?: number;
        /**
         * @description  报送人名称
         */
        submittedName?: string;
        /**
         * @description  是否有效：1，有效，2无效
         */
        validOrNot?: number;
      }
      /**
       * @name 修改宽带报送
       * @param id 修改宽带报送
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/broadbandSub/update/${id}`,
          data
        );
      }
      export interface UpdateRemarksParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  备注
         */
        remark?: string;
      }
      /**
       * @name 单独修改备注接口
       * @param data
       */
      export function updateRemarks(data: UpdateRemarksParams) {
        return request.post<ResponseResult<object>>(
          "/report-v100001/broadbandSub/updateRemarks",
          data
        );
      }
      /**
       * @name 作废接口
       * @param id 作废接口
       */
      export function zuoFei(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/broadbandSub/zuoFei/${id}`
        );
      }
    }
    export namespace ccb {
      export interface CcbSyncHouseParams {
        /**
         * @description  房源ids
         */
        ids?: string;
      }
      /**
       * @name 房源美好家园同步
       * @param params
       */
      export function ccbSyncHouse(params: CcbSyncHouseParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/ccb/ccbSyncHouse",
          { params }
        );
      }
      export interface CcbSyncTenantsParams {
        /**
         * @description  房间ids
         */
        ids?: string;
      }
      /**
       * @name 租客美好家园同步
       * @param params
       */
      export function ccbSyncTenants(params: CcbSyncTenantsParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/ccb/ccbSyncTenants",
          { params }
        );
      }
    }
    export namespace contractRecord {
      export interface BatchCopyDownloadUrlParams {
        /**
         * @description  ids
         */
        ids?: string;
      }
      /**
       * @name 电子合同批量下载
       * @param params
       */
      export function batchCopyDownloadUrl(params: BatchCopyDownloadUrlParams) {
        return request.get(
          "/report-v100001/contractRecord/batchCopyDownloadUrl",
          { params }
        );
      }
      export interface ReportContractRecordParams {
        /**
         * @description  合同性质id
         */
        contractNatureId?: number;
        /**
         * @description  合同编号
         */
        contractNo?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  邮件发送状态 0-未发送 1-已发送
         */
        emailState?: number;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联类型ID 12房东  13租客
         */
        relationTypeId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态 1-正常 2-删除
         */
        state?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
        /**
         * @description  合同类型 1-电子 2-纸质
         */
        type?: number;
      }
      /**
       * @name 合同记录列表
       * @param params
       */
      export function reportContractRecord(params: ReportContractRecordParams) {
        return request.get<ResponseResult<PageList<ContractRecord>>>(
          "/report-v100001/contractRecord/reportContractRecord",
          { params }
        );
      }
      /**
       * @name 后端使用：同步房源业绩表数据
       */
      export function syncFinishTime() {
        return request.get<ResponseResult<object>>(
          "/report-v100001/contractRecord/syncFinishTime"
        );
      }
    }
    export namespace houseSync {
      export interface AddDebtParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步欠款
       * @param data
       */
      export function addDebt(data: AddDebtParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addDebt",
          data
        );
      }
      export interface AddRemarkParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步备忘
       * @param data
       */
      export function addRemark(data: AddRemarkParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addRemark",
          data
        );
      }
      export interface AddDeliveryOrderParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步电子交割单
       * @param data
       */
      export function addDeliveryOrder(data: AddDeliveryOrderParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addDeliveryOrder",
          data
        );
      }
      export interface AddFinanceParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步财务
       * @param data
       */
      export function addFinance(data: AddFinanceParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addFinance",
          data
        );
      }
      export interface AddHouseRentParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步帐单
       * @param data
       */
      export function addHouseRent(data: AddHouseRentParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addHouseRent",
          data
        );
      }
      export interface UpdateHouseRentParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 修改账单
       * @param data
       */
      export function updateHouseRent(data: UpdateHouseRentParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/updateHouseRent",
          data
        );
      }
      export interface UpdateTenantsRentParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 修改租客账单
       * @param data
       */
      export function updateTenantsRent(data: UpdateTenantsRentParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/updateTenantsRent",
          data
        );
      }
      export interface AddHouseParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
        /**
         * @description  page
         */
        page?: number;
        /**
         * @description  houseType
         */
        houseType?: string;
      }
      /**
       * @name 同步房源（整租）
       * @param data
       */
      export function addHouse(data: AddHouseParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addHouse",
          data
        );
      }
      export interface AddUserParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步用户
       * @param data
       */
      export function addUser(data: AddUserParams) {
        return request.post<ResponseResult<PageList<object>>>(
          "/report-v100001/houseSync/addUser",
          data
        );
      }
      export interface UpdateHouseParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
        /**
         * @description  houseType
         */
        houseType?: string;
      }
      /**
       * @name 同步房源（整租）
       * @param data
       */
      export function updateHouse(data: UpdateHouseParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/updateHouse",
          data
        );
      }
      export interface UpdateTenantsOtherParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 修改租客其它）
       * @param data
       */
      export function updateTenantsOther(data: UpdateTenantsOtherParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/updateTenantsOther",
          data
        );
      }
      export interface AddMaintenanceParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步维修
       * @param data
       */
      export function addMaintenance(data: AddMaintenanceParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addMaintenance",
          data
        );
      }
      export interface AddReserveCustomersParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步储备客源
       * @param data
       */
      export function addReserveCustomers(data: AddReserveCustomersParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addReserveCustomers",
          data
        );
      }
      export interface AddReserveParams {
        /**
         * @description  全房通用户token
         */
        qftToken?: string;
      }
      /**
       * @name 同步储备房源
       * @param data
       */
      export function addReserve(data: AddReserveParams) {
        return request.post<ResponseResult<ResponseResult<object>>>(
          "/report-v100001/houseSync/addReserve",
          data
        );
      }
    }
    export namespace decorate {
      export interface ReportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id（集中传房间id）
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  工人姓名
         */
        workerName?: string;
      }
      /**
       * @name 房源装修报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<Decorate>>>(
          "/report-v100001/decorate/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id（集中传房间id）
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  工人姓名
         */
        workerName?: string;
      }
      /**
       * @name 房源装修报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/decorate/reportExport", { params });
      }
    }
    export namespace delay {
      export interface ReportParams {
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        endTimeStart?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  姓名
         */
        name?: number;
        /**
         * @description  租客或者房东id
         */
        otherId?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费日期 1表示提前，2表示延后 3固定日期
         */
        payType?: number;
        /**
         * @description  缴费日期 提前或者延后多少天
         */
        payTypeDay?: number;
        /**
         * @description  付款方式id
         */
        payTypeId?: number;
        /**
         * @description  电话
         */
        phone?: number;
        /**
         * @description  12 房东，13表示租客
         */
        relationTypeId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 延期记录列表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<Delay>>>(
          "/report-v100001/delay/report",
          { params }
        );
      }
    }
    export namespace emptyHouse {
      /**
       * @name houseEmptyTime
       */
      export function houseEmptyTime() {
        return request.post<ResponseResult<object>>(
          "/report-v100001/emptyHouse/houseEmptyTime"
        );
      }
      export interface PageParams {
        /**
         * @description  区域id多选
         */
        areaIdList?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  管家id多选
         */
        stewardIdList?: string;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 年空置天数统计表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<REmptyHouse>>>(
          "/report-v100001/emptyHouse/page",
          { params }
        );
      }
    }
    export namespace emptyTime {
      export interface CompanyReportParams {
        /**
         * @description  每天空置时间
         */
        houseDate?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 公司每日空置率统计报表 不要门店搜索条件，不展示门店名称
       * @param params
       */
      export function companyReport(params: CompanyReportParams) {
        return request.get<ResponseResult<PageList<EmptyTimeVo>>>(
          "/report-v100001/emptyTime/companyReport",
          { params }
        );
      }
      export interface CompanyReportExportParams {
        /**
         * @description  每天空置时间
         */
        houseDate?: string;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 公司每日空置率导出
       * @param params
       */
      export function companyReportExport(params: CompanyReportExportParams) {
        return request.get("/report-v100001/emptyTime/companyReportExport", {
          params
        });
      }
      export interface MonthReportParams {
        /**
         * @description  每天空置时间
         */
        houseDate?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 月空置率统计报表 不要门店搜索条件，不展示门店名称，不要月搜索，默认不要所有条件
       * @param params
       */
      export function monthReport(params: MonthReportParams) {
        return request.get<ResponseResult<PageList<EmptyTimeVo>>>(
          "/report-v100001/emptyTime/monthReport",
          { params }
        );
      }
      export interface StoreReportParams {
        /**
         * @description  每天空置时间
         */
        houseDate?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 店面每日空置率统计报表
       * @param params
       */
      export function storeReport(params: StoreReportParams) {
        return request.get<ResponseResult<PageList<EmptyTimeVo>>>(
          "/report-v100001/emptyTime/storeReport",
          { params }
        );
      }
      export interface StoreReportExportParams {
        /**
         * @description  每天空置时间
         */
        houseDate?: string;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 店面每日空置率导出
       * @param params
       */
      export function storeReportExport(params: StoreReportExportParams) {
        return request.get("/report-v100001/emptyTime/storeReportExport", {
          params
        });
      }
      /**
       * @name 空置定时任务调用（后端测试用的）
       */
      export function sync() {
        return request.get<ResponseResult>("/report-v100001/emptyTime/sync");
      }
    }
    export namespace fileLog {
      /**
       * @name 大文件下载日志删除
       * @param id 大文件下载日志删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/report-v100001/fileLog/delete/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  实体名称
         */
        entityName?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 大文件下载日志列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<FileLog>>>(
          "/report-v100001/fileLog/list",
          { params }
        );
      }
    }
    export namespace finance {
      export namespace statistics {
        export interface HouseRentExportV1Params {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  业务人员ID
           */
          businessId?: number;
          /**
           * @description  联系人
           */
          contactName?: string;
          /**
           * @description  联系电话
           */
          contactPhone?: string;
          /**
           * @description  合同性质id
           */
          contractId?: number;
          /**
           * @description  统计月份 格式:2020-02
           */
          dateMonth?: string;
          /**
           * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
           */
          dateType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  房源ID(续约ID-租客ID) 选其一
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNo?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  费用结算单签名 1-未签名 2-已签名
           */
          houseSettlementIsSignature?: number;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  租后是否审核 1已审核  2未通过  3未审核
           */
          isAfterAudit?: number;
          /**
           * @description  只看自己的
           */
          isMy?: number;
          /**
           * @description  是否续约 0-否 1-是
           */
          isRenewal?: number;
          /**
           * @description  缴费日期(结束)
           */
          payDateEnd?: string;
          /**
           * @description  缴费日期(开始)
           */
          payDateStart?: string;
          /**
           * @description  0-押金 非0-表示房租
           */
          payNum?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  付款类型ID
           */
          payTypeId?: number;
          /**
           * @description  租金类型 房东=应支 租客=应收
           */
          relationTypeId?: number;
          /**
           * @description  续约ID(房源ID-租客ID) 选其一
           */
          renewalId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  状态：1正常 2待退房 3冻结
           */
          status?: number;
          /**
           * @description  管家确认无误：1,无误；2，未确认;3,有误
           */
          stewardConfirm?: number;
          /**
           * @description  管家ID
           */
          stewardId?: number;
          /**
           * @description  第二管家ID
           */
          stewardId2?: number;
          /**
           * @description  员工分组id
           */
          storeGroupId?: number;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID(续约ID-房源ID) 选其一
           */
          tenantsId?: number;
        }
        /**
         * @name 未审核，未发送，未通过的房租列表导出
         * @param params
         */
        export function houseRentExportV1(params: HouseRentExportV1Params) {
          return request.get(
            "/report-v100001/finance/statistics/houseRentExportV1",
            { params }
          );
        }
        export interface HouseRentListParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  业务人员ID
           */
          businessId?: number;
          /**
           * @description  联系人
           */
          contactName?: string;
          /**
           * @description  联系电话
           */
          contactPhone?: string;
          /**
           * @description  合同性质id
           */
          contractId?: number;
          /**
           * @description  统计月份 格式:2020-02
           */
          dateMonth?: string;
          /**
           * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
           */
          dateType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  房源ID(续约ID-租客ID) 选其一
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNo?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  费用结算单签名 1-未签名 2-已签名
           */
          houseSettlementIsSignature?: number;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  租后是否审核 1已审核  2未通过  3未审核
           */
          isAfterAudit?: number;
          /**
           * @description  只看自己的
           */
          isMy?: number;
          /**
           * @description  是否续约 0-否 1-是
           */
          isRenewal?: number;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  缴费日期(结束)
           */
          payDateEnd?: string;
          /**
           * @description  缴费日期(开始)
           */
          payDateStart?: string;
          /**
           * @description  0-押金 非0-表示房租
           */
          payNum?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  付款类型ID
           */
          payTypeId?: number;
          /**
           * @description  租金类型 房东=应支 租客=应收
           */
          relationTypeId?: number;
          /**
           * @description  续约ID(房源ID-租客ID) 选其一
           */
          renewalId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  状态：1正常 2待退房 3冻结
           */
          status?: number;
          /**
           * @description  管家确认无误：1,无误；2，未确认;3,有误
           */
          stewardConfirm?: number;
          /**
           * @description  管家ID
           */
          stewardId?: number;
          /**
           * @description  第二管家ID
           */
          stewardId2?: number;
          /**
           * @description  员工分组id
           */
          storeGroupId?: number;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID(续约ID-房源ID) 选其一
           */
          tenantsId?: number;
        }
        /**
         * @name 房租列表
         * @param params
         */
        export function houseRentList(params: HouseRentListParams) {
          return request.get<ResponseResult<PageList<HouseRent>>>(
            "/report-v100001/finance/statistics/houseRentList",
            { params }
          );
        }
        export interface HouseRentListV1Params {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  业务人员ID
           */
          businessId?: number;
          /**
           * @description  联系人
           */
          contactName?: string;
          /**
           * @description  联系电话
           */
          contactPhone?: string;
          /**
           * @description  合同性质id
           */
          contractId?: number;
          /**
           * @description  统计月份 格式:2020-02
           */
          dateMonth?: string;
          /**
           * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
           */
          dateType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  房源ID(续约ID-租客ID) 选其一
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNo?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  费用结算单签名 1-未签名 2-已签名
           */
          houseSettlementIsSignature?: number;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  租后是否审核 1已审核  2未通过  3未审核
           */
          isAfterAudit?: number;
          /**
           * @description  只看自己的
           */
          isMy?: number;
          /**
           * @description  是否续约 0-否 1-是
           */
          isRenewal?: number;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  缴费日期(结束)
           */
          payDateEnd?: string;
          /**
           * @description  缴费日期(开始)
           */
          payDateStart?: string;
          /**
           * @description  0-押金 非0-表示房租
           */
          payNum?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  付款类型ID
           */
          payTypeId?: number;
          /**
           * @description  租金类型 房东=应支 租客=应收
           */
          relationTypeId?: number;
          /**
           * @description  续约ID(房源ID-租客ID) 选其一
           */
          renewalId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  状态：1正常 2待退房 3冻结
           */
          status?: number;
          /**
           * @description  管家确认无误：1,无误；2，未确认;3,有误
           */
          stewardConfirm?: number;
          /**
           * @description  管家ID
           */
          stewardId?: number;
          /**
           * @description  第二管家ID
           */
          stewardId2?: number;
          /**
           * @description  员工分组id
           */
          storeGroupId?: number;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID(续约ID-房源ID) 选其一
           */
          tenantsId?: number;
        }
        /**
         * @name 未审核，未发送，未通过的房租列表
         * @param params
         */
        export function houseRentListV1(params: HouseRentListV1Params) {
          return request.get<ResponseResult<PageList<HouseRent>>>(
            "/report-v100001/finance/statistics/houseRentListV1",
            { params }
          );
        }
        export interface HouseRentRemindParams {
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  1短信 2微信
           */
          type?: number;
        }
        /**
         * @name 房租催收功能
         * @param data
         */
        export function houseRentRemind(data: HouseRentRemindParams) {
          return request.post<ResponseResult<object>>(
            "/report-v100001/finance/statistics/houseRentRemind",
            data
          );
        }
        export interface HouseRentTotalParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  业务人员ID
           */
          businessId?: number;
          /**
           * @description  联系人
           */
          contactName?: string;
          /**
           * @description  联系电话
           */
          contactPhone?: string;
          /**
           * @description  合同性质id
           */
          contractId?: number;
          /**
           * @description  统计月份 格式:2020-02
           */
          dateMonth?: string;
          /**
           * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
           */
          dateType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  房源ID(续约ID-租客ID) 选其一
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNo?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  费用结算单签名 1-未签名 2-已签名
           */
          houseSettlementIsSignature?: number;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  租后是否审核 1已审核  2未通过  3未审核
           */
          isAfterAudit?: number;
          /**
           * @description  只看自己的
           */
          isMy?: number;
          /**
           * @description  是否续约 0-否 1-是
           */
          isRenewal?: number;
          /**
           * @description  缴费日期(结束)
           */
          payDateEnd?: string;
          /**
           * @description  缴费日期(开始)
           */
          payDateStart?: string;
          /**
           * @description  0-押金 非0-表示房租
           */
          payNum?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  付款类型ID
           */
          payTypeId?: number;
          /**
           * @description  租金类型 房东=应支 租客=应收
           */
          relationTypeId?: number;
          /**
           * @description  续约ID(房源ID-租客ID) 选其一
           */
          renewalId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  状态：1正常 2待退房 3冻结
           */
          status?: number;
          /**
           * @description  管家确认无误：1,无误；2，未确认;3,有误
           */
          stewardConfirm?: number;
          /**
           * @description  管家ID
           */
          stewardId?: number;
          /**
           * @description  第二管家ID
           */
          stewardId2?: number;
          /**
           * @description  员工分组id
           */
          storeGroupId?: number;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID(续约ID-房源ID) 选其一
           */
          tenantsId?: number;
        }
        /**
         * @name 房租总计
         * @param params
         */
        export function houseRentTotal(params: HouseRentTotalParams) {
          return request.get<ResponseResult<HouseRentTotal>>(
            "/report-v100001/finance/statistics/houseRentTotal",
            { params }
          );
        }
        export interface IncomeExpenseListParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  计费方式(1-均费 2-自定义)
           */
          billType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  款项原因ID
           */
          feeReasonId?: number;
          /**
           * @description  款项类型ID
           */
          feeTypeId?: number;
          /**
           * @description  房源ID 与收支计划ID和租客ID互斥
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNum?: string;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  收支类型(1-收入 2-支出)
           */
          inoutType?: number;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  支付时间
           */
          payDate?: string;
          /**
           * @description  缴费日期筛选结束时间
           */
          payDateEnd?: string;
          /**
           * @description  支付时间统计月 格式:2020-01
           */
          payDateMonth?: string;
          /**
           * @description  缴费日期筛选开始时间
           */
          payDateStart?: string;
          /**
           * @description  付款方式ID
           */
          payMethodId?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  收支计划ID 与房源ID和租客ID互斥
           */
          planId?: number;
          /**
           * @description  计划类型(1-房屋 2-其他)
           */
          planType?: number;
          /**
           * @description  关联人身份证号码
           */
          relationIdcard?: string;
          /**
           * @description  关联人
           */
          relationName?: string;
          /**
           * @description  关联人电话
           */
          relationPhone?: string;
          /**
           * @description  关联类型ID
           */
          relationTypeId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID 与收支计划ID和房源ID互斥
           */
          tenantsId?: number;
        }
        /**
         * @name 收支明细列表
         * @param params
         */
        export function incomeExpenseList(params: IncomeExpenseListParams) {
          return request.get<ResponseResult<PageList<IncomeExpenseDetail>>>(
            "/report-v100001/finance/statistics/incomeExpenseList",
            { params }
          );
        }
        export interface UpdateIsAuditParams {
          /**
           * @description  支付月份
           */
          payDateMonth?: number;
        }
        /**
         * @name updateIsAudit
         * @param data
         */
        export function updateIsAudit(data: UpdateIsAuditParams) {
          return request.post<ResponseResult<object>>(
            "/report-v100001/finance/statistics/updateIsAudit",
            data
          );
        }
      }
    }
    export namespace followUp {
      export interface ReportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 跟进记录报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<FollowUp>>>(
          "/report-v100001/followUp/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 跟进记录报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/followUp/reportExport", { params });
      }
    }
    export namespace goodsIncrease {
      export interface ReportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id(整租与合租传房源id)
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房源物品增配报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<GoodsIncrease>>>(
          "/report-v100001/goodsIncrease/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id(整租与合租传房源id)
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房源物品增配导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/goodsIncrease/reportExport", {
          params
        });
      }
    }
    export namespace home {
      export namespace finance {
        /**
         * @name 首页财务统计
         */
        export function homeFinance() {
          return request.get<ResponseResult<HomeFinanceVo>>(
            "/report-v100001/home/finance/homeFinance"
          );
        }
        /**
         * @name 首页今日收房捷报,多少条，直接取数组条数
         */
        export function homeHouse() {
          return request.get<ResponseResult<Array<string>>>(
            "/report-v100001/home/finance/homeHouse"
          );
        }
        /**
         * @name 首页今日出房捷报
         */
        export function homeTenants() {
          return request.get<ResponseResult<Array<string>>>(
            "/report-v100001/home/finance/homeTenants"
          );
        }
      }
      export namespace operating {
        /**
         * @name 首页月空置率统计报表
         */
        export function getHomeEmptyTime() {
          return request.get<ResponseResult<EmptyHomeVo>>(
            "/report-v100001/home/operating/getHomeEmptyTime"
          );
        }
        /**
         * @name 首页月空置率统计报表
         */
        export function getHomeRate() {
          return request.get<ResponseResult<HomeRateVo>>(
            "/report-v100001/home/operating/getHomeRate"
          );
        }
        export interface GetHouseMapParams {
          /**
           * @description  区域id
           */
          areaId?: number;
          /**
           * @description  物业地区id
           */
          detailId?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  类型：1整租,2合租，3集中
           */
          houseType?: number;
          /**
           * @description  是否租完 1已租完 2未租完
           */
          isRentOut?: number;
          /**
           * @description  地图等级只做2级 1表示区域 2表示 物业地址
           */
          level?: number;
          /**
           * @description  几室
           */
          room?: number;
          /**
           * @description  管家id
           */
          stewardId?: number;
          /**
           * @description  门店id
           */
          storeId?: number;
        }
        /**
         * @name 首页地图展示房源
         * @param params
         */
        export function getHouseMap(params: GetHouseMapParams) {
          return request.get<ResponseResult<HouseMapHomeVo>>(
            "/report-v100001/home/operating/getHouseMap",
            { params }
          );
        }
      }
      export namespace center {
        export interface GetHouseRankingParams {
          /**
           * @description  1表示合同开始时间，2表示录入时间
           */
          type?: number;
        }
        /**
         * @name 首页本月收房排名(个人)
         * @param params
         */
        export function getHouseRanking(params: GetHouseRankingParams) {
          return request.get<ResponseResult<Array<HomeRankingVo>>>(
            "/report-v100001/home/center/getHouseRanking",
            { params }
          );
        }
        export interface GetHouseRankingV1Params {
          /**
           * @description  1表示合同开始时间，2表示录入时间
           */
          type?: number;
        }
        /**
         * @name 首页本月收房排名(店面)
         * @param params
         */
        export function getHouseRankingV1(params: GetHouseRankingV1Params) {
          return request.get<ResponseResult<Array<HomeRankingVoV1>>>(
            "/report-v100001/home/center/getHouseRankingV1",
            { params }
          );
        }
        export interface GetPerformanceParams {
          /**
           * @description  1表示合同开始时间，2表示录入时间
           */
          type?: number;
        }
        /**
         * @name 首页公司业绩
         * @param params
         */
        export function getPerformance(params: GetPerformanceParams) {
          return request.get<ResponseResult<HomePerformanceVo>>(
            "/report-v100001/home/center/getPerformance",
            { params }
          );
        }
        export interface GetRoomTenantsRankingParams {
          /**
           * @description  1表示合同开始时间，2表示录入时间
           */
          type?: number;
        }
        /**
         * @name 首页本月出房排名(个人)
         * @param params
         */
        export function getRoomTenantsRanking(
          params: GetRoomTenantsRankingParams
        ) {
          return request.get<ResponseResult<Array<HomeRankingVo>>>(
            "/report-v100001/home/center/getRoomTenantsRanking",
            { params }
          );
        }
        export interface GetRoomTenantsRankingV1Params {
          /**
           * @description  1表示合同开始时间，2表示录入时间
           */
          type?: number;
        }
        /**
         * @name 首页本月出房排名(店面)
         * @param params
         */
        export function getRoomTenantsRankingV1(
          params: GetRoomTenantsRankingV1Params
        ) {
          return request.get<ResponseResult<Array<HomeRankingVoV1>>>(
            "/report-v100001/home/center/getRoomTenantsRankingV1",
            { params }
          );
        }
      }
    }
    export namespace houseImpl {
      /**
       * @name 房源房证地址导入
       */
      export function houseCardUpdate() {
        return request.post<ResponseResult<object>>(
          "/report-v100001/houseImpl/houseCardUpdate"
        );
      }
    }
    export namespace houseContract {
      export interface BatchContractDownloadParams {
        /**
         * @description  业务员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  合同开始时间报表查询时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 续约合同批量下载
       * @param params
       */
      export function batchContractDownload(
        params: BatchContractDownloadParams
      ) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/houseContract/batchContractDownload",
          { params }
        );
      }
      export interface ExportContractBackReportParams {
        /**
         * @description  业务员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  合同开始时间报表查询时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 业主续约数据备份
       * @param params
       */
      export function exportContractBackReport(
        params: ExportContractBackReportParams
      ) {
        return request.get(
          "/report-v100001/houseContract/exportContractBackReport",
          { params }
        );
      }
      export interface ReportParams {
        /**
         * @description  业务员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  合同开始时间报表查询时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 房东续约报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<HouseContract>>>(
          "/report-v100001/houseContract/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  业务员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  合同开始时间报表查询时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 房东续约报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/houseContract/reportExport", {
          params
        });
      }
    }
    export namespace houseExit {
      export interface HouseExitAmountTotalParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        endTimeStart?: string;
        /**
         * @description  1 正常退房 2没有签成 3房东违约 4公司违约
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房东已退房报表总金额
       * @param params
       */
      export function houseExitAmountTotal(params: HouseExitAmountTotalParams) {
        return request.get<ResponseResult<HouseExitSumVo>>(
          "/report-v100001/houseExit/houseExitAmountTotal",
          { params }
        );
      }
      export interface ReportParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        endTimeStart?: string;
        /**
         * @description  1 正常退房 2没有签成 3房东违约 4公司违约
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房东已退房报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<HouseExit>>>(
          "/report-v100001/houseExit/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        endTimeStart?: string;
        /**
         * @description  1 正常退房 2没有签成 3房东违约 4公司违约
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房东已退房报表
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/houseExit/reportExport", {
          params
        });
      }
    }
    export namespace houseHis {
      export interface ExportHouseHisBackReportParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  业务人员id
         */
        businessId?: number;
        /**
         * @description  协助人员2
         */
        businessId2?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 收房业绩线下数据备份，包含租金
       * @param params
       */
      export function exportHouseHisBackReport(
        params: ExportHouseHisBackReportParams
      ) {
        return request.get(
          "/report-v100001/houseHis/exportHouseHisBackReport",
          { params }
        );
      }
      export interface ExportHouseReportParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  业务人员id
         */
        businessId?: number;
        /**
         * @description  协助人员2
         */
        businessId2?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 收房业绩报表导出
       * @param params
       */
      export function exportHouseReport(params: ExportHouseReportParams) {
        return request.get("/report-v100001/houseHis/exportHouseReport", {
          params
        });
      }
      /**
       * @name 实时当月收房数据导出
       */
      export function exportHouseReportV1() {
        return request.get("/report-v100001/houseHis/exportHouseReportV1");
      }
      export interface HouseReportParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  业务人员id
         */
        businessId?: number;
        /**
         * @description  协助人员2
         */
        businessId2?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 收房业绩报表
       * @param params
       */
      export function houseReport(params: HouseReportParams) {
        return request.get<ResponseResult<PageList<HouseHis>>>(
          "/report-v100001/houseHis/houseReport",
          { params }
        );
      }
      export interface HouseReportSumParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  业务人员id
         */
        businessId?: number;
        /**
         * @description  协助人员2
         */
        businessId2?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 收房业绩统计
       * @param params
       */
      export function houseReportSum(params: HouseReportSumParams) {
        return request.get<ResponseResult<HouseReportSumVo>>(
          "/report-v100001/houseHis/houseReportSum",
          { params }
        );
      }
      export interface HouseReportSumV1Params {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  业务人员id
         */
        businessId?: number;
        /**
         * @description  协助人员2
         */
        businessId2?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 收房业绩统计（到家了合作业绩专用）
       * @param params
       */
      export function houseReportSumV1(params: HouseReportSumV1Params) {
        return request.get<ResponseResult<HouseReportSumVo>>(
          "/report-v100001/houseHis/houseReportSumV1",
          { params }
        );
      }
      export interface HouseReportV1Params {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  业务人员id
         */
        businessId?: number;
        /**
         * @description  协助人员2
         */
        businessId2?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 收房业绩统计（到家了合作业绩专用）
       * @param params
       */
      export function houseReportV1(params: HouseReportV1Params) {
        return request.get<ResponseResult<PageList<HouseHis>>>(
          "/report-v100001/houseHis/houseReportV1",
          { params }
        );
      }
      /**
       * @name 房源历史表详情
       * @param id 房源历史表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseHis>>(
          `/report-v100001/houseHis/info/${id}`
        );
      }
      export namespace update {
        export interface PayStatusParams {
          /**
           * @description  支付状态
           */
          commissionPayStatus?: number;
          /**
           * @description  佣金支付备注
           */
          commissionRemark?: string;
        }
        /**
         * @name 收房业绩修改佣金支付状态
         * @param id 收房业绩修改佣金支付状态
         * @param data
         */
        export function payStatus(id: string | number, data: PayStatusParams) {
          return request.post<ResponseResult<object>>(
            `/report-v100001/houseHis/update/payStatus/${id}`,
            data
          );
        }
      }
    }
    export namespace houseInsuranceClaims {
      export interface ReportExportParams {
        /**
         * @description  报案报表查询时间
         */
        caseReportTime?: string;
        /**
         * @description  报案时间结束
         */
        caseTimeEnd?: string;
        /**
         * @description  报案时间开始
         */
        caseTimeStart?: string;
        /**
         * @description  理赔报表查询时间
         */
        claimsReportTime?: string;
        /**
         * @description  理赔时间结束
         */
        claimsTimeEnd?: string;
        /**
         * @description  理赔时间开始
         */
        claimsTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  家财险表主键
         */
        insuranceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 家财险理赔导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get(
          "/report-v100001/houseInsuranceClaims/reportExport",
          { params }
        );
      }
      export interface ReportListParams {
        /**
         * @description  报案报表查询时间
         */
        caseReportTime?: string;
        /**
         * @description  报案时间结束
         */
        caseTimeEnd?: string;
        /**
         * @description  报案时间开始
         */
        caseTimeStart?: string;
        /**
         * @description  理赔报表查询时间
         */
        claimsReportTime?: string;
        /**
         * @description  理赔时间结束
         */
        claimsTimeEnd?: string;
        /**
         * @description  理赔时间开始
         */
        claimsTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  家财险表主键
         */
        insuranceId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 家财险理赔表列表
       * @param params
       */
      export function reportList(params: ReportListParams) {
        return request.get<ResponseResult<PageList<HouseInsuranceClaims>>>(
          "/report-v100001/houseInsuranceClaims/reportList",
          { params }
        );
      }
    }
    export namespace houseInsurance {
      export interface ReportExportParams {
        /**
         * @description  购买日期报表查询时间
         */
        buyReportTime?: string;
        /**
         * @description  购买时间结束
         */
        buyTimeEnd?: string;
        /**
         * @description  购买时间开始
         */
        buyTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  到时日期报表查询时间 (默认当前这个时间为时间查询条件)
         */
        insuranceEndReportTime?: string;
        /**
         * @description  终保日期结束
         */
        insuranceEndTimeEnd?: string;
        /**
         * @description  终保日期开始
         */
        insuranceEndTimeStart?: string;
        /**
         * @description  起保日期报表查询时间
         */
        insuranceStartReportTime?: string;
        /**
         * @description  起保日期结束
         */
        insuranceStartTimeEnd?: string;
        /**
         * @description  起保日期开始
         */
        insuranceStartTimeStart?: string;
        /**
         * @description  保险类型：1新签购买、2脱保购买、3续保购买
         */
        insuranceType?: number;
        /**
         * @description  保单号
         */
        orderNo?: string;
        /**
         * @description  托管开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 家财险报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/houseInsurance/reportExport", {
          params
        });
      }
      export interface ReportListParams {
        /**
         * @description  购买日期报表查询时间
         */
        buyReportTime?: string;
        /**
         * @description  购买时间结束
         */
        buyTimeEnd?: string;
        /**
         * @description  购买时间开始
         */
        buyTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  到时日期报表查询时间 (默认当前这个时间为时间查询条件)
         */
        insuranceEndReportTime?: string;
        /**
         * @description  终保日期结束
         */
        insuranceEndTimeEnd?: string;
        /**
         * @description  终保日期开始
         */
        insuranceEndTimeStart?: string;
        /**
         * @description  起保日期报表查询时间
         */
        insuranceStartReportTime?: string;
        /**
         * @description  起保日期结束
         */
        insuranceStartTimeEnd?: string;
        /**
         * @description  起保日期开始
         */
        insuranceStartTimeStart?: string;
        /**
         * @description  保险类型：1新签购买、2脱保购买、3续保购买
         */
        insuranceType?: number;
        /**
         * @description  保单号
         */
        orderNo?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 家财险列表
       * @param params
       */
      export function reportList(params: ReportListParams) {
        return request.get<ResponseResult<PageList<HouseInsurance>>>(
          "/report-v100001/houseInsurance/reportList",
          { params }
        );
      }
    }
    export namespace house {
      export interface HouseEndReportParams {
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(整租，合租)
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  导出报表结束时间（导出管家半年内巡房数据使用）
         */
        exportTimeEnd?: string;
        /**
         * @description  导出报表开始时间（导出管家半年内巡房数据使用）
         */
        exportTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东创建时间结束
         */
        houseCreateTimeEnd?: string;
        /**
         * @description  房东创建时间开始
         */
        houseCreateTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  宽带是否安装:1未安装 2已安装 3已到期
         */
        isBroadband?: number;
        /**
         * @description  是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  家财险是否购买:1未购买 2已购买 3已到期
         */
        isInsurance?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费方式名称
         */
        payCostTypeId?: number;
        /**
         * @description  推送58的状态：1 已推送 ，2 未推送
         */
        pushStatus?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 房东合同到期报表
       * @param params
       */
      export function houseEndReport(params: HouseEndReportParams) {
        return request.get<ResponseResult<PageList<HouseExpire>>>(
          "/report-v100001/house/houseEndReport",
          { params }
        );
      }
      export interface HouseEndReportExportParams {
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(整租，合租)
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  导出报表结束时间（导出管家半年内巡房数据使用）
         */
        exportTimeEnd?: string;
        /**
         * @description  导出报表开始时间（导出管家半年内巡房数据使用）
         */
        exportTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东创建时间结束
         */
        houseCreateTimeEnd?: string;
        /**
         * @description  房东创建时间开始
         */
        houseCreateTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  宽带是否安装:1未安装 2已安装 3已到期
         */
        isBroadband?: number;
        /**
         * @description  是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  家财险是否购买:1未购买 2已购买 3已到期
         */
        isInsurance?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  缴费方式名称
         */
        payCostTypeId?: number;
        /**
         * @description  推送58的状态：1 已推送 ，2 未推送
         */
        pushStatus?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 房东合同到期报表导出
       * @param params
       */
      export function houseEndReportExport(params: HouseEndReportExportParams) {
        return request.get("/report-v100001/house/houseEndReportExport", {
          params
        });
      }
      export interface HouseWalkReportParams {
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(整租，合租)
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  导出报表结束时间（导出管家半年内巡房数据使用）
         */
        exportTimeEnd?: string;
        /**
         * @description  导出报表开始时间（导出管家半年内巡房数据使用）
         */
        exportTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东创建时间结束
         */
        houseCreateTimeEnd?: string;
        /**
         * @description  房东创建时间开始
         */
        houseCreateTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  宽带是否安装:1未安装 2已安装 3已到期
         */
        isBroadband?: number;
        /**
         * @description  是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  家财险是否购买:1未购买 2已购买 3已到期
         */
        isInsurance?: number;
        /**
         * @description  是否查询是我的：1，是。
         */
        isMy?: number;
        /**
         * @description  是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费方式名称
         */
        payCostTypeId?: number;
        /**
         * @description  推送58的状态：1 已推送 ，2 未推送
         */
        pushStatus?: string;
        /**
         * @description  查询是否有备注的：0，为有；1，为没有
         */
        remarkStatus?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  是否回访 1已回访  2未回访
         */
        visitStatus?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 管家半年巡房报表
       * @param params
       */
      export function houseWalkReport(params: HouseWalkReportParams) {
        return request.get<ResponseResult<PageList<HouseVisitVo>>>(
          "/report-v100001/house/houseWalkReport",
          { params }
        );
      }
      export interface HouseWalkReportExportParams {
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(整租，合租)
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  导出报表结束时间（导出管家半年内巡房数据使用）
         */
        exportTimeEnd?: string;
        /**
         * @description  导出报表开始时间（导出管家半年内巡房数据使用）
         */
        exportTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东创建时间结束
         */
        houseCreateTimeEnd?: string;
        /**
         * @description  房东创建时间开始
         */
        houseCreateTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  宽带是否安装:1未安装 2已安装 3已到期
         */
        isBroadband?: number;
        /**
         * @description  是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  家财险是否购买:1未购买 2已购买 3已到期
         */
        isInsurance?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  缴费方式名称
         */
        payCostTypeId?: number;
        /**
         * @description  推送58的状态：1 已推送 ，2 未推送
         */
        pushStatus?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫
         */
        who?: number;
        /**
         * @description  visitStatus
         */
        visitStatus?: number;
      }
      /**
       * @name 管家半年巡房报表导出
       * @param params
       */
      export function houseWalkReportExport(
        params: HouseWalkReportExportParams
      ) {
        return request.get("/report-v100001/house/houseWalkReportExport", {
          params
        });
      }
      export interface HouseWalkReportExportV1Params {
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(整租，合租)
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  导出报表结束时间（导出管家半年内巡房数据使用）
         */
        exportTimeEnd?: string;
        /**
         * @description  导出报表开始时间（导出管家半年内巡房数据使用）
         */
        exportTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东创建时间结束
         */
        houseCreateTimeEnd?: string;
        /**
         * @description  房东创建时间开始
         */
        houseCreateTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  宽带是否安装:1未安装 2已安装 3已到期
         */
        isBroadband?: number;
        /**
         * @description  是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  家财险是否购买:1未购买 2已购买 3已到期
         */
        isInsurance?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  缴费方式名称
         */
        payCostTypeId?: number;
        /**
         * @description  推送58的状态：1 已推送 ，2 未推送
         */
        pushStatus?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 管家半年巡房报表导出(含半年内所有的未回访记录)
       * @param params
       */
      export function houseWalkReportExportV1(
        params: HouseWalkReportExportV1Params
      ) {
        return request.get("/report-v100001/house/houseWalkReportExportV1", {
          params
        });
      }
    }
    export namespace houseVisit {
      /**
       * @name 回访记录表删除
       * @param id 回访记录表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/report-v100001/houseVisit/delete/${id}`
        );
      }
      /**
       * @name 测试未回访提醒
       */
      export function houseWalkTask() {
        return request.get<ResponseResult<object>>(
          "/report-v100001/houseVisit/houseWalkTask"
        );
      }
      /**
       * @name 回访记录表详情
       * @param id 回访记录表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseVisit>>(
          `/report-v100001/houseVisit/info/${id}`
        );
      }
      export interface SaveParams {
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  实际回访人主键,前端默认当前登陆人
         */
        realVisitId?: number;
        /**
         * @description  回访备注
         */
        remark: string;
        /**
         * @description  几月回访时间查询
         */
        reportTime: string;
        /**
         * @description  回访图片，已回访图片必传
         */
        visitImg?: string;
        /**
         * @description  是否回访 1已回访  2未回访
         */
        visitStatus: number;
      }
      /**
       * @name 回访记录表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/report-v100001/houseVisit/save",
          data
        );
      }
    }
    export namespace invoiceApplication {
      export interface AuditParams {
        /**
         * @description  审批状态
         */
        isAudit?: number;
      }
      /**
       * @name 审核接口
       * @param id 审核接口
       * @param data
       */
      export function audit(id: string | number, data: AuditParams) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/invoiceApplication/audit/${id}`,
          data
        );
      }
      /**
       * @name 删除
       * @param id 删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/report-v100001/invoiceApplication/delete/${id}`
        );
      }
      export interface GetByIdParams {
        /**
         * @description  科目类型：1，房租类型；2，服务费类型
         */
        accountType?: number;
      }
      /**
       * @name 根据房租id获取发票申请单
       * @param id 根据房租id获取发票申请单
       * @param params
       */
      export function getById(id: string | number, params: GetByIdParams) {
        return request.get<ResponseResult<InvoiceApplicationOutDto>>(
          `/report-v100001/invoiceApplication/getById/${id}`,
          { params }
        );
      }
      /**
       * @name 详情
       * @param id 详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<InvoiceApplication>>(
          `/report-v100001/invoiceApplication/info/${id}`
        );
      }
      export interface PageParams {
        /**
         * @description  科目类型：1，房租类型；2，服务费类型
         */
        accountType?: number;
        /**
         * @description  经办资产管理师id
         */
        assetManagerId?: number;
        /**
         * @description  部门（大区）id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  房间号
         */
        houseNum?: string;
        /**
         * @description  是否审核 1已审核  2未通过  3未审核
         */
        isAudit?: number;
        /**
         * @description  签字状态：0,未签字；1，已签字
         */
        isSign?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询月份
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  发票类型：1，电子专票。2，电子普票
         */
        typeId?: number;
      }
      /**
       * @name 列表页
       * @param data
       */
      export function page(data: PageParams) {
        return request.post<ResponseResult<PageList<InvoiceApplication>>>(
          "/report-v100001/invoiceApplication/page",
          data
        );
      }
      export interface PageV1Params {
        /**
         * @description  科目类型：1，房租类型；2，服务费类型
         */
        accountType?: number;
        /**
         * @description  经办资产管理师id
         */
        assetManagerId?: number;
        /**
         * @description  部门（大区）id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  房间号
         */
        houseNum?: string;
        /**
         * @description  是否审核 1已审核  2未通过  3未审核
         */
        isAudit?: number;
        /**
         * @description  签字状态：0,未签字；1，已签字
         */
        isSign?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询月份
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  发票类型：1，电子专票。2，电子普票
         */
        typeId?: number;
      }
      /**
       * @name 列表页(租客端)
       * @param data
       */
      export function pageV1(data: PageV1Params) {
        return request.post<ResponseResult<PageList<InvoiceApplication>>>(
          "/report-v100001/invoiceApplication/pageV1",
          data
        );
      }
      export interface SaveParams {
        /**
         * @description  科目类型：1，房租类型；2，服务费类型
         */
        accountType?: number;
        /**
         * @description  出资公司地址电话
         */
        addrPhone?: string;
        /**
         * @description  承租详细地址
         */
        addressDetail?: string;
        /**
         * @description  经办资产管理师id
         */
        assetManagerId?: number;
        /**
         * @description  经办资产管理师名称
         */
        assetManagerName?: string;
        /**
         * @description  签约合同编号
         */
        contractNo?: string;
        /**
         * @description  出资公司开户行和账号
         */
        contributingBank?: string;
        /**
         * @description  出资公司名称
         */
        contributingName?: string;
        /**
         * @description  出资公司社会信用统一代码
         */
        contributingUscc?: string;
        /**
         * @description  电子普票接收邮箱
         */
        emailAddress?: string;
        /**
         * @description  开票金额
         */
        invoicingAmount?: number;
        /**
         * @description  承租时间
         */
        leaseTime?: string;
        /**
         * @description  承租人
         */
        lessee?: string;
        /**
         * @description  产权号码
         */
        propertyNum?: string;
        /**
         * @description  房租id
         */
        rentId?: number;
        /**
         * @description  税点金额
         */
        taxPoints?: number;
        /**
         * @description  发票类型：1，电子专票。2，电子普票
         */
        typeId?: number;
        /**
         * @description  开票费用使用项
         */
        usageItems?: string;
      }
      /**
       * @name 添加
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/report-v100001/invoiceApplication/save",
          data
        );
      }
      export interface SignParams {
        /**
         * @description  签字图片路径
         */
        url?: string;
      }
      /**
       * @name 签字接口
       * @param id 签字接口
       * @param data
       */
      export function sign(id: string | number, data: SignParams) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/invoiceApplication/sign/${id}`,
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  科目类型：1，房租类型；2，服务费类型
         */
        accountType?: number;
        /**
         * @description  出资公司地址电话
         */
        addrPhone?: string;
        /**
         * @description  承租详细地址
         */
        addressDetail?: string;
        /**
         * @description  经办资产管理师id
         */
        assetManagerId?: number;
        /**
         * @description  经办资产管理师名称
         */
        assetManagerName?: string;
        /**
         * @description  签约合同编号
         */
        contractNo?: string;
        /**
         * @description  出资公司开户行和账号
         */
        contributingBank?: string;
        /**
         * @description  出资公司名称
         */
        contributingName?: string;
        /**
         * @description  出资公司社会信用统一代码
         */
        contributingUscc?: string;
        /**
         * @description  电子普票接收邮箱
         */
        emailAddress?: string;
        /**
         * @description  开票金额
         */
        invoicingAmount?: number;
        /**
         * @description  承租时间
         */
        leaseTime?: string;
        /**
         * @description  承租人
         */
        lessee?: string;
        /**
         * @description  产权号码
         */
        propertyNum?: string;
        /**
         * @description  房租id
         */
        rentId?: number;
        /**
         * @description  税点金额
         */
        taxPoints?: number;
        /**
         * @description  发票类型：1，电子专票。2，电子普票
         */
        typeId?: number;
        /**
         * @description  开票费用使用项
         */
        usageItems?: string;
      }
      /**
       * @name 修改接口
       * @param id 修改接口
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/invoiceApplication/update/${id}`,
          data
        );
      }
      export interface UpdateInvoiceNumberParams {
        /**
         * @description  发票号
         */
        invoiceNumber?: string;
      }
      /**
       * @name 修改发票号接口
       * @param id 修改发票号接口
       * @param data
       */
      export function updateInvoiceNumber(
        id: string | number,
        data: UpdateInvoiceNumberParams
      ) {
        return request.post<ResponseResult<object>>(
          `/report-v100001/invoiceApplication/updateInvoiceNumber/${id}`,
          data
        );
      }
    }
    export namespace maintenanceClean {
      export interface ReportParams {
        /**
         * @description  保洁人员是否处理完成订单：1.为是，2为不是
         */
        cleanType?: number;
        /**
         * @description  保洁人员完成保洁时间
         */
        cleanerDealTimeEnd?: string;
        /**
         * @description  保洁人员完成保洁时间
         */
        cleanerDealTimeStart?: string;
        /**
         * @description  创建开始结束时间
         */
        createTimeEnd?: string;
        /**
         * @description  创建开始时间
         */
        createTimeStart?: string;
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  处理时间开始时间
         */
        dealTimeEnd?: string;
        /**
         * @description  处理时间开始时间
         */
        dealTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  期望时间，前传今天，明天 这种要自己处理今天0点 结束为23点，或者自己选
         */
        expectTimeEnd?: string;
        /**
         * @description  期望时间，前传今天，明天，或者自己选
         */
        expectTimeStart?: string;
        /**
         * @description  房源id(列表使用)
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1表示通过，2未通过   3未审核（微信报修才有）
         */
        isAudit?: number;
        /**
         * @description  是否查询自己的 1表示我的，2取消）
         */
        isMy?: number;
        /**
         * @description  1表示微信报修，2表示公司派单
         */
        isWeixin?: number;
        /**
         * @description  来源(1：租客业主端，2：管理端)
         */
        orderSource?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  支付状态:1为未支付，2为已支付
         */
        payStatus?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id(列表使用)
         */
        roomId?: number;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        /**
         * @description  星评
         */
        score?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1未处理，2已处理 3处理中
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  1表示维修 2保洁  3清洗
         */
        typeId?: number;
        /**
         * @description  核实状态：1，未核实；2，已核实
         */
        verifyStatus?: number;
        /**
         * @description  回访结果：pid:689
         */
        visitDealStatusId?: number;
        /**
         * @description  是否私下收费：1是，2否
         */
        visitIsCharge?: number;
        /**
         * @description  是否按时间完：1是，2否
         */
        visitOnTime?: number;
        /**
         * @description  服务星评：1特别差,2差，3一般，4好，5非常好
         */
        visitServiceId?: number;
        /**
         * @description  回访状态：1未回访 2已回访
         */
        visitStatus?: number;
        /**
         * @description  回访时间结束
         */
        visitTimeEndTime?: string;
        /**
         * @description  回访时间开始
         */
        visitTimeStartTime?: string;
        /**
         * @description  回访人
         */
        visitUserId?: number;
      }
      /**
       * @name 维修保洁报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<MaintenanceClean>>>(
          "/report-v100001/maintenanceClean/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  保洁人员是否处理完成订单：1.为是，2为不是
         */
        cleanType?: number;
        /**
         * @description  保洁人员完成保洁时间
         */
        cleanerDealTimeEnd?: string;
        /**
         * @description  保洁人员完成保洁时间
         */
        cleanerDealTimeStart?: string;
        /**
         * @description  创建开始结束时间
         */
        createTimeEnd?: string;
        /**
         * @description  创建开始时间
         */
        createTimeStart?: string;
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  处理时间开始时间
         */
        dealTimeEnd?: string;
        /**
         * @description  处理时间开始时间
         */
        dealTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  期望时间，前传今天，明天 这种要自己处理今天0点 结束为23点，或者自己选
         */
        expectTimeEnd?: string;
        /**
         * @description  期望时间，前传今天，明天，或者自己选
         */
        expectTimeStart?: string;
        /**
         * @description  房源id(列表使用)
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1表示通过，2未通过   3未审核（微信报修才有）
         */
        isAudit?: number;
        /**
         * @description  是否查询自己的 1表示我的，2取消）
         */
        isMy?: number;
        /**
         * @description  1表示微信报修，2表示公司派单
         */
        isWeixin?: number;
        /**
         * @description  来源(1：租客业主端，2：管理端)
         */
        orderSource?: number;
        /**
         * @description  支付状态:1为未支付，2为已支付
         */
        payStatus?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id(列表使用)
         */
        roomId?: number;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        /**
         * @description  星评
         */
        score?: number;
        /**
         * @description  1未处理，2已处理 3处理中
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  第二管家id
         */
        stewardId2?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  1表示维修 2保洁  3清洗
         */
        typeId?: number;
        /**
         * @description  核实状态：1，未核实；2，已核实
         */
        verifyStatus?: number;
        /**
         * @description  回访结果：pid:689
         */
        visitDealStatusId?: number;
        /**
         * @description  是否私下收费：1是，2否
         */
        visitIsCharge?: number;
        /**
         * @description  是否按时间完：1是，2否
         */
        visitOnTime?: number;
        /**
         * @description  服务星评：1特别差,2差，3一般，4好，5非常好
         */
        visitServiceId?: number;
        /**
         * @description  回访状态：1未回访 2已回访
         */
        visitStatus?: number;
        /**
         * @description  回访时间结束
         */
        visitTimeEndTime?: string;
        /**
         * @description  回访时间开始
         */
        visitTimeStartTime?: string;
        /**
         * @description  回访人
         */
        visitUserId?: number;
      }
      /**
       * @name 维修保洁报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/maintenanceClean/reportExport", {
          params
        });
      }
    }
    export namespace otherChangeRoom {
      export interface ExportOtherChangeBackParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  新物业地区id
         */
        newDetailId?: string;
        /**
         * @description  新门牌号、（集中几层或者几栋）
         */
        newHouseNum?: string;
        /**
         * @description  新房间id
         */
        newRoomId?: number;
        /**
         * @description  原物业地区id
         */
        oldDetailId?: string;
        /**
         * @description  原门牌号、（集中几层或者几栋）
         */
        oldHouseNum?: string;
        /**
         * @description  原门店id
         */
        oldStoreId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束)
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始)
         */
        reportTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  新门店id
         */
        storeId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 换房业绩数据备份(线下数据)
       * @param params
       */
      export function exportOtherChangeBack(
        params: ExportOtherChangeBackParams
      ) {
        return request.get(
          "/report-v100001/otherChangeRoom/exportOtherChangeBack",
          { params }
        );
      }
      export interface TenantsChangeRoomBackUpParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  新物业地区id
         */
        newDetailId?: string;
        /**
         * @description  新门牌号、（集中几层或者几栋）
         */
        newHouseNum?: string;
        /**
         * @description  新房间id
         */
        newRoomId?: number;
        /**
         * @description  原物业地区id
         */
        oldDetailId?: string;
        /**
         * @description  原门牌号、（集中几层或者几栋）
         */
        oldHouseNum?: string;
        /**
         * @description  原门店id
         */
        oldStoreId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束)
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始)
         */
        reportTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  新门店id
         */
        storeId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 换房业绩资料备份
       * @param params
       */
      export function tenantsChangeRoomBackUp(
        params: TenantsChangeRoomBackUpParams
      ) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/otherChangeRoom/tenantsChangeRoomBackUp",
          { params }
        );
      }
      export interface TenantsChangeRoomReportParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  新物业地区id
         */
        newDetailId?: string;
        /**
         * @description  新门牌号、（集中几层或者几栋）
         */
        newHouseNum?: string;
        /**
         * @description  新房间id
         */
        newRoomId?: number;
        /**
         * @description  原物业地区id
         */
        oldDetailId?: string;
        /**
         * @description  原门牌号、（集中几层或者几栋）
         */
        oldHouseNum?: string;
        /**
         * @description  原门店id
         */
        oldStoreId?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束)
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始)
         */
        reportTimeStart?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  新门店id
         */
        storeId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 租客换房
       * @param params
       */
      export function tenantsChangeRoomReport(
        params: TenantsChangeRoomReportParams
      ) {
        return request.get<ResponseResult<PageList<OtherChangeRoom>>>(
          "/report-v100001/otherChangeRoom/tenantsChangeRoomReport",
          { params }
        );
      }
      export interface TenantsChangeRoomReportExportParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  新物业地区id
         */
        newDetailId?: string;
        /**
         * @description  新门牌号、（集中几层或者几栋）
         */
        newHouseNum?: string;
        /**
         * @description  新房间id
         */
        newRoomId?: number;
        /**
         * @description  原物业地区id
         */
        oldDetailId?: string;
        /**
         * @description  原门牌号、（集中几层或者几栋）
         */
        oldHouseNum?: string;
        /**
         * @description  原门店id
         */
        oldStoreId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束)
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始)
         */
        reportTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  新门店id
         */
        storeId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 租客换房导出
       * @param params
       */
      export function tenantsChangeRoomReportExport(
        params: TenantsChangeRoomReportExportParams
      ) {
        return request.get(
          "/report-v100001/otherChangeRoom/tenantsChangeRoomReportExport",
          { params }
        );
      }
    }
    export namespace pricing {
      export interface ReportParams {
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房源定价报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<Pricing>>>(
          "/report-v100001/pricing/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房源定价报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/pricing/reportExport", { params });
      }
    }
    export namespace remark {
      export interface ReportParams {
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源表id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  其它业务表id
         */
        otherId?: number;
        /**
         * @description  其它业务表类型 101表示应收
         */
        otherType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 备注报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<Remark>>>(
          "/report-v100001/remark/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源表id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  其它业务表id
         */
        otherId?: number;
        /**
         * @description  其它业务表类型 101表示应收
         */
        otherType?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 备注报表
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/remark/reportExport", { params });
      }
    }
    export namespace rentCollection {
      export interface ExportParams {
        /**
         * @description  区ids(多选)
         */
        areaIds?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  部门ids(多选)
         */
        deptIds?: string;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  管家ids(多选)
         */
        stewardIds?: string;
        /**
         * @description  门店ids(多选)
         */
        storeIds?: string;
      }
      /**
       * @name 收租统计报表导出
       * @param data
       */
      export function exports(data: ExportParams) {
        return request.post("/report-v100001/rentCollection/export", data);
      }
      export interface PageParams {
        /**
         * @description  区ids(多选)
         */
        areaIds?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  部门ids(多选)
         */
        deptIds?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  管家ids(多选)
         */
        stewardIds?: string;
        /**
         * @description  门店ids(多选)
         */
        storeIds?: string;
      }
      /**
       * @name 收租统计报表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<RentCollectionVo>>>(
          "/report-v100001/rentCollection/page",
          { params }
        );
      }
      /**
       * @name save
       */
      export function save() {
        return request.post<ResponseResult<object>>(
          "/report-v100001/rentCollection/save"
        );
      }
    }
    export namespace rentDifference {
      export interface PageParams {
        /**
         * @description  房屋类型：1整租,2合租，3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  查看类型：1,按公司；2，按大区；3按门店
         */
        type?: number;
      }
      /**
       * @name 月平均实时租差统计表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<RRentDifference>>>(
          "/report-v100001/rentDifference/page",
          { params }
        );
      }
      /**
       * @name save
       */
      export function save() {
        return request.post<ResponseResult<object>>(
          "/report-v100001/rentDifference/save"
        );
      }
    }
    export namespace rentPoor {
      export interface RentPoorReportExportParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  合租租差单独有的条件：是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  合同开始时间
         */
        reportTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  类型：录入时间，2表示合同开始时间
         */
        type?: number;
      }
      /**
       * @name 整租(合租)租差统计报表导出
       * @param params
       */
      export function rentPoorReportExport(params: RentPoorReportExportParams) {
        return request.get("/report-v100001/rentPoor/rentPoorReportExport", {
          params
        });
      }
      export interface RentPoorReportListParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  合租租差单独有的条件：是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  合同开始时间
         */
        reportTimeStart?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  类型：录入时间，2表示合同开始时间
         */
        type?: number;
      }
      /**
       * @name 租差统计报表
       * @param params
       */
      export function rentPoorReportList(params: RentPoorReportListParams) {
        return request.get<ResponseResult<PageList<RentPoorVo>>>(
          "/report-v100001/rentPoor/rentPoorReportList",
          { params }
        );
      }
      export interface RentPoorStatisticalParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  合租租差单独有的条件：是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  合同开始时间
         */
        reportTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  类型：录入时间，2表示合同开始时间
         */
        type?: number;
      }
      /**
       * @name 整租(合租)租差统计报表总计
       * @param params
       */
      export function rentPoorStatistical(params: RentPoorStatisticalParams) {
        return request.get<ResponseResult<RentPoorStatisticalVo>>(
          "/report-v100001/rentPoor/rentPoorStatistical",
          { params }
        );
      }
    }
    export namespace reportContract {
      export interface CompanyReportParams {
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示房东  2租客
         */
        type?: number;
      }
      /**
       * @name 公司率统计表列表
       * @param params
       */
      export function companyReport(params: CompanyReportParams) {
        return request.get<ResponseResult<PageList<ReportContractVo>>>(
          "/report-v100001/reportContract/companyReport",
          { params }
        );
      }
      export interface StoreReportParams {
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示房东  2租客
         */
        type?: number;
      }
      /**
       * @name 续约率统计表列表(按店面统计)
       * @param params
       */
      export function storeReport(params: StoreReportParams) {
        return request.get<ResponseResult<PageList<ReportContractVo>>>(
          "/report-v100001/reportContract/storeReport",
          { params }
        );
      }
      export interface SyncParams {
        /**
         * @description  endTime
         */
        endTime?: number;
      }
      /**
       * @name 续约率定时任务调用（后端测试用的）
       * @param params
       */
      export function sync(params: SyncParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/reportContract/sync",
          { params }
        );
      }
    }
    export namespace reserveCustomers {
      export interface ExportStoreReportParams {
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 按店面导出潜在租客数据
       * @param params
       */
      export function exportStoreReport(params: ExportStoreReportParams) {
        return request.get(
          "/report-v100001/reserveCustomers/exportStoreReport",
          { params }
        );
      }
      export interface ExportUserReportParams {
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
        /**
         * @description  createId
         */
        createId?: number;
      }
      /**
       * @name 按员工导出潜在租客数据
       * @param params
       */
      export function exportUserReport(params: ExportUserReportParams) {
        return request.get(
          "/report-v100001/reserveCustomers/exportUserReport",
          { params }
        );
      }
      export interface ReserveCustomerReportSumParams {
        /**
         * @description  员工id
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 潜在租客数据统计
       * @param params
       */
      export function reserveCustomerReportSum(
        params: ReserveCustomerReportSumParams
      ) {
        return request.get<ResponseResult<any>>(
          "/report-v100001/reserveCustomers/reserveCustomerReportSum",
          { params }
        );
      }
      export interface StoreReportParams {
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 按店面查询潜在租客数据
       * @param params
       */
      export function storeReport(params: StoreReportParams) {
        return request.get<ResponseResult<PageList<NameCountVo>>>(
          "/report-v100001/reserveCustomers/storeReport",
          { params }
        );
      }
      export interface UserReportParams {
        /**
         * @description  员工id
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 按员工查询潜在租客数据
       * @param params
       */
      export function userReport(params: UserReportParams) {
        return request.get<ResponseResult<PageList<NameCountVo>>>(
          "/report-v100001/reserveCustomers/userReport",
          { params }
        );
      }
    }
    export namespace reserveHouse {
      export interface ExportStoreReportParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 按店面导出潜在业主数据
       * @param params
       */
      export function exportStoreReport(params: ExportStoreReportParams) {
        return request.get("/report-v100001/reserveHouse/exportStoreReport", {
          params
        });
      }
      export interface ExportUserReportParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 按员工导出潜在业主数据
       * @param params
       */
      export function exportUserReport(params: ExportUserReportParams) {
        return request.get("/report-v100001/reserveHouse/exportUserReport", {
          params
        });
      }
      export interface ReserveReportSumParams {
        /**
         * @description  员工id
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 潜在业主数据统计
       * @param params
       */
      export function reserveReportSum(params: ReserveReportSumParams) {
        return request.get<ResponseResult<any>>(
          "/report-v100001/reserveHouse/reserveReportSum",
          { params }
        );
      }
      export interface StoreReportParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 按店面查询潜在业主数据
       * @param params
       */
      export function storeReport(params: StoreReportParams) {
        return request.get<ResponseResult<PageList<NameCountVo>>>(
          "/report-v100001/reserveHouse/storeReport",
          { params }
        );
      }
      export interface UserReportParams {
        /**
         * @description  员工id
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        /**
         * @description  月报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 按员工查询潜在业主数据
       * @param params
       */
      export function userReport(params: UserReportParams) {
        return request.get<ResponseResult<PageList<NameCountVo>>>(
          "/report-v100001/reserveHouse/userReport",
          { params }
        );
      }
    }
    export namespace roomIsEffective {
      export interface PageParams {
        /**
         * @description  部门区域id多选
         */
        deptIdList?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 分页查询
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<RoomIsEffectiveVo>>>(
          "/report-v100001/roomIsEffective/page",
          { params }
        );
      }
    }
    export namespace scheduled {
      /**
       * @name 政府租赁平台同步（后端测试用的）
       */
      export function ccbSyncScheduled() {
        return request.get<ResponseResult>(
          "/report-v100001/scheduled/ccbSyncScheduled"
        );
      }
      /**
       * @name 删除文件（后端测试用的）
       */
      export function deleteFileZip() {
        return request.get<ResponseResult>(
          "/report-v100001/scheduled/deleteFileZip"
        );
      }
      /**
       * @name 房租提醒报表（后端测试用的）
       */
      export function syncHouseRentRemind() {
        return request.get<ResponseResult>(
          "/report-v100001/scheduled/syncHouseRentRemind"
        );
      }
    }
    export namespace sublet {
      export interface ExportSubletBackParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  新租客姓名
         */
        newName?: string;
        /**
         * @description  新租客电话
         */
        newPhone?: string;
        /**
         * @description  原租客姓名
         */
        oldName?: string;
        /**
         * @description  原租客联系电话
         */
        oldPhone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  转租性质 id Pid:293
         */
        subletId?: number;
      }
      /**
       * @name 转租业绩数据备份(线下数据)
       * @param params
       */
      export function exportSubletBack(params: ExportSubletBackParams) {
        return request.get("/report-v100001/sublet/exportSubletBack", {
          params
        });
      }
      export interface ReportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  新租客姓名
         */
        newName?: string;
        /**
         * @description  新租客电话
         */
        newPhone?: string;
        /**
         * @description  原租客姓名
         */
        oldName?: string;
        /**
         * @description  原租客联系电话
         */
        oldPhone?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  转租性质 id Pid:293
         */
        subletId?: number;
      }
      /**
       * @name 转租记录报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<Sublet>>>(
          "/report-v100001/sublet/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  新租客姓名
         */
        newName?: string;
        /**
         * @description  新租客电话
         */
        newPhone?: string;
        /**
         * @description  原租客姓名
         */
        oldName?: string;
        /**
         * @description  原租客联系电话
         */
        oldPhone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  转租性质 id Pid:293
         */
        subletId?: number;
      }
      /**
       * @name 转租记录报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/sublet/reportExport", { params });
      }
      export interface TenantsSubletBackUpParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  新租客姓名
         */
        newName?: string;
        /**
         * @description  新租客电话
         */
        newPhone?: string;
        /**
         * @description  原租客姓名
         */
        oldName?: string;
        /**
         * @description  原租客联系电话
         */
        oldPhone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  转租性质 id Pid:293
         */
        subletId?: number;
      }
      /**
       * @name 转租业绩资料备份
       * @param params
       */
      export function tenantsSubletBackUp(params: TenantsSubletBackUpParams) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/sublet/tenantsSubletBackUp",
          { params }
        );
      }
    }
    export namespace takeLook {
      export interface ReportParams {
        /**
         * @description  创建日期
         */
        createDate?: string;
        /**
         * @description  创建时间结束
         */
        createDateEnd?: string;
        /**
         * @description  创建时间开始
         */
        createDateStart?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  1正常 2异常
         */
        isException?: number;
        /**
         * @description  带看次数：1次 2次  到  10次
         */
        lookCount?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 带看房源记录表报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<TakeLook>>>(
          "/report-v100001/takeLook/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  创建日期
         */
        createDate?: string;
        /**
         * @description  创建时间结束
         */
        createDateEnd?: string;
        /**
         * @description  创建时间开始
         */
        createDateStart?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  1正常 2异常
         */
        isException?: number;
        /**
         * @description  带看次数：1次 2次  到  10次
         */
        lookCount?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 带看房源报表
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/takeLook/reportExport", { params });
      }
    }
    export namespace takeLookReport {
      export interface ReportPageParams {
        /**
         * @description  创建日期
         */
        createDate?: string;
        /**
         * @description  创建时间结束
         */
        createDateEnd?: string;
        /**
         * @description  创建时间开始
         */
        createDateStart?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  1正常 2异常
         */
        isException?: number;
        /**
         * @description  带看次数：1次 2次  到  10次
         */
        lookCount?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 带看统计列表
       * @param params
       */
      export function reportPage(params: ReportPageParams) {
        return request.get<ResponseResult<PageList<TakeLook>>>(
          "/report-v100001/takeLookReport/reportPage",
          { params }
        );
      }
      export interface ReportPageV1Params {
        /**
         * @description  创建日期
         */
        createDate?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ids（多选）
         */
        storeIds?: string;
        /**
         * @description  员工ids（多选）
         */
        userIds?: string;
      }
      /**
       * @name 带看统计列表-按个人查看
       * @param params
       */
      export function reportPageV1(params: ReportPageV1Params) {
        return request.get<ResponseResult<PageList<LookReportVo>>>(
          "/report-v100001/takeLookReport/reportPageV1",
          { params }
        );
      }
      export interface ReportPageV2Params {
        /**
         * @description  创建日期
         */
        createDate?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ids（多选）
         */
        storeIds?: string;
        /**
         * @description  员工ids（多选）
         */
        userIds?: string;
      }
      /**
       * @name 带看统计列表-按店面查看
       * @param params
       */
      export function reportPageV2(params: ReportPageV2Params) {
        return request.get<ResponseResult<PageList<LookReportVoV1>>>(
          "/report-v100001/takeLookReport/reportPageV2",
          { params }
        );
      }
    }
    export namespace tenantsContract {
      export interface BatchContractDownloadParams {
        /**
         * @description  续约业务人员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeEnd?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeStart?: string;
        /**
         * @description  续约前租赁结束时间结束
         */
        oldLeaseStartTimeEnd?: string;
        /**
         * @description  续约前租赁开始时间开始
         */
        oldLeaseStartTimeStart?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表续约合同开始时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  房间id
         */
        roomTenantsId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家2id
         */
        stewardId2?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 续约合同批量下载
       * @param params
       */
      export function batchContractDownload(
        params: BatchContractDownloadParams
      ) {
        return request.get<ResponseResult<object>>(
          "/report-v100001/tenantsContract/batchContractDownload",
          { params }
        );
      }
      export interface ExportTenantsContractBackParams {
        /**
         * @description  续约业务人员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeEnd?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeStart?: string;
        /**
         * @description  续约前租赁结束时间结束
         */
        oldLeaseStartTimeEnd?: string;
        /**
         * @description  续约前租赁开始时间开始
         */
        oldLeaseStartTimeStart?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表续约合同开始时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  房间id
         */
        roomTenantsId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家2id
         */
        stewardId2?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 租客续约数据备份(线下数据)
       * @param params
       */
      export function exportTenantsContractBack(
        params: ExportTenantsContractBackParams
      ) {
        return request.get(
          "/report-v100001/tenantsContract/exportTenantsContractBack",
          { params }
        );
      }
      export interface ReportParams {
        /**
         * @description  续约业务人员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeEnd?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeStart?: string;
        /**
         * @description  续约前租赁结束时间结束
         */
        oldLeaseStartTimeEnd?: string;
        /**
         * @description  续约前租赁开始时间开始
         */
        oldLeaseStartTimeStart?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表续约合同开始时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  房间id
         */
        roomTenantsId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家2id
         */
        stewardId2?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 租客续约报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<TenantsContract>>>(
          "/report-v100001/tenantsContract/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  续约业务人员id
         */
        businessId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeEnd?: string;
        /**
         * @description  续约前租赁结束时间开始
         */
        oldLeaseEndTimeStart?: string;
        /**
         * @description  续约前租赁结束时间结束
         */
        oldLeaseStartTimeEnd?: string;
        /**
         * @description  续约前租赁开始时间开始
         */
        oldLeaseStartTimeStart?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表续约合同开始时间
         */
        reportTimeStart?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  房间id
         */
        roomTenantsId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家2id
         */
        stewardId2?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
      }
      /**
       * @name 租客续约报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/tenantsContract/reportExport", {
          params
        });
      }
    }
    export namespace tenantsEnergy {
      export interface ReportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  付款状态 1-未付   3-已还完
         */
        payStatus?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 能源记录报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<TenantsEnergy>>>(
          "/report-v100001/tenantsEnergy/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  付款状态 1-未付   3-已还完
         */
        payStatus?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 能源记录报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/tenantsEnergy/reportExport", {
          params
        });
      }
    }
    export namespace tenantsExit {
      export interface ReportParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  退房人
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  退房性质id
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
         */
        isMark?: number;
        /**
         * @description  超期退租标记月份
         */
        isMark1Month?: string;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 租客退房报表
       * @param params
       */
      export function report(params: ReportParams) {
        return request.get<ResponseResult<PageList<TenantsExit>>>(
          "/report-v100001/tenantsExit/report",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  退房人
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  退房性质id
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
         */
        isMark?: number;
        /**
         * @description  超期退租标记月份
         */
        isMark1Month?: string;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 租客退房报表导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/report-v100001/tenantsExit/reportExport", {
          params
        });
      }
      export interface TenantsExitAmountTotalParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  退房人
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  退房性质id
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
         */
        isMark?: number;
        /**
         * @description  超期退租标记月份
         */
        isMark1Month?: string;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 租客已退房报表总金额
       * @param params
       */
      export function tenantsExitAmountTotal(
        params: TenantsExitAmountTotalParams
      ) {
        return request.get<ResponseResult<HouseExitSumVo>>(
          "/report-v100001/tenantsExit/tenantsExitAmountTotal",
          { params }
        );
      }
      /**
       * @name 更新退房房租数据
       */
      export function tenantsExitUpdateHouseRent() {
        return request.get<ResponseResult>(
          "/report-v100001/tenantsExit/tenantsExitUpdateHouseRent"
        );
      }
    }
    export namespace tenantsHis {
      export interface ExportTenantsHisBackParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  协助人员2
         */
        businessName2?: string;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  户型/房型id
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  门牌号（集中楼层）
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 换新租房业绩数据备份(线下数据)
       * @param params
       */
      export function exportTenantsHisBack(params: ExportTenantsHisBackParams) {
        return request.get("/report-v100001/tenantsHis/exportTenantsHisBack", {
          params
        });
      }
      /**
       * @name 实时当月收房数据导出
       */
      export function exportTenantsReportV1() {
        return request.get("/report-v100001/tenantsHis/exportTenantsReportV1");
      }
      /**
       * @name 租房历史表详情
       * @param id 租房历史表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<TenantsHis>>(
          `/report-v100001/tenantsHis/info/${id}`
        );
      }
      export interface TenantsReportParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  协助人员2
         */
        businessName2?: string;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  户型/房型id
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  门牌号（集中楼层）
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租房业绩报表
       * @param params
       */
      export function tenantsReport(params: TenantsReportParams) {
        return request.get<ResponseResult<PageList<TenantsHis>>>(
          "/report-v100001/tenantsHis/tenantsReport",
          { params }
        );
      }
      export interface TenantsReportExportParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  协助人员2
         */
        businessName2?: string;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  户型/房型id
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  门牌号（集中楼层）
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租房业绩报表导出
       * @param params
       */
      export function tenantsReportExport(params: TenantsReportExportParams) {
        return request.get("/report-v100001/tenantsHis/tenantsReportExport", {
          params
        });
      }
      export interface TenantsReportSumParams {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  协助人员2
         */
        businessName2?: string;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  户型/房型id
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  门牌号（集中楼层）
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租房业绩统计
       * @param params
       */
      export function tenantsReportSum(params: TenantsReportSumParams) {
        return request.get<ResponseResult<TenantsReportSumVo>>(
          "/report-v100001/tenantsHis/tenantsReportSum",
          { params }
        );
      }
      export interface TenantsReportSumV1Params {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  协助人员2
         */
        businessName2?: string;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  户型/房型id
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  门牌号（集中楼层）
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租房业绩统计（到家了合作业绩专用）
       * @param params
       */
      export function tenantsReportSumV1(params: TenantsReportSumV1Params) {
        return request.get<ResponseResult<TenantsReportSumVo>>(
          "/report-v100001/tenantsHis/tenantsReportSumV1",
          { params }
        );
      }
      export interface TenantsReportV1Params {
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  协助人员2
         */
        businessName2?: string;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签字 1-未签字 2-已签字
         */
        contractRecordSignStatus?: number;
        /**
         * @description  签字时间结束
         */
        contractRecordSignTimeEnd?: string;
        /**
         * @description  签字时间开始
         */
        contractRecordSignTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  交割单签 1已签字 2废弃 3待签字
         */
        deliveryOrderSignStatus?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  户型/房型id
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  门牌号（集中楼层）
         */
        houseNum?: string;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  是否已上传证件图片:2未上传
         */
        idCardImgStatus?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  结算单签字状态：1已签字，2未签字
         */
        settlementSignStatus?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1新签 2续约 3退房
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1表示合同开始时间，2表示录入时间
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租房业绩报表（到家了合作业绩专用）
       * @param params
       */
      export function tenantsReportV1(params: TenantsReportV1Params) {
        return request.get<ResponseResult<PageList<TenantsHis>>>(
          "/report-v100001/tenantsHis/tenantsReportV1",
          { params }
        );
      }
      export interface UpdateRentOutEndTimeParams {
        /**
         * @description  空置天数
         */
        day?: number;
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 修改空置时间
       * @param data
       */
      export function updateRentOutEndTime(data: UpdateRentOutEndTimeParams) {
        return request.put<ResponseResult<object>>(
          "/report-v100001/tenantsHis/updateRentOutEndTime",
          data
        );
      }
      export namespace update {
        export interface PayStatusParams {
          /**
           * @description  支付状态
           */
          commissionPayStatus?: number;
          /**
           * @description  佣金支付备注
           */
          commissionRemark?: string;
        }
        /**
         * @name 租房业绩报表
         * @param id 租房业绩报表
         * @param data
         */
        export function payStatus(id: string | number, data: PayStatusParams) {
          return request.post<ResponseResult<object>>(
            `/report-v100001/tenantsHis/update/payStatus/${id}`,
            data
          );
        }
      }
    }
    export namespace roomTenants {
      export interface TenantsEndReportParams {
        /**
         * @description  区id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  1 有空调，0无空调
         */
        conditioner?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  装修状态（集中）
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  户型/房型id  主，次卧（合租才有）
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  第一次房租支付状态 1-已付 2-未付
         */
        houseRentOne?: number;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租客是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  查看身份证是否上传
         */
        isTenantImg?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  在线找房上传状态：1，已上传；2未上传
         */
        outShowStatus?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  联系电话2
         */
        phone2?: string;
        /**
         * @description  空置时间搜索条件
         */
        rentOutEndTimeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1正常   3冻结
         */
        status?: number;
        /**
         * @description  出租状态（系统字典）
         */
        statusId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id2
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租客到期报表
       * @param params
       */
      export function tenantsEndReport(params: TenantsEndReportParams) {
        return request.get<ResponseResult<PageList<RoomTenants>>>(
          "/report-v100001/roomTenants/tenantsEndReport",
          { params }
        );
      }
      export interface TenantsEndReportExportParams {
        /**
         * @description  区id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  1 有空调，0无空调
         */
        conditioner?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  装修状态（集中）
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  户型/房型id  主，次卧（合租才有）
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  第一次房租支付状态 1-已付 2-未付
         */
        houseRentOne?: number;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租客是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  查看身份证是否上传
         */
        isTenantImg?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  在线找房上传状态：1，已上传；2未上传
         */
        outShowStatus?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  联系电话2
         */
        phone2?: string;
        /**
         * @description  空置时间搜索条件
         */
        rentOutEndTimeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        /**
         * @description  状态：1正常   3冻结
         */
        status?: number;
        /**
         * @description  出租状态（系统字典）
         */
        statusId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id2
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租客到期报表导出
       * @param params
       */
      export function tenantsEndReportExport(
        params: TenantsEndReportExportParams
      ) {
        return request.get(
          "/report-v100001/roomTenants/tenantsEndReportExport",
          { params }
        );
      }
    }
  }
}
