import { request } from "../utils/request";
export namespace API_OTHER {
  export namespace v100001 {
    export namespace addr {
      /**
       * @name 到家了片区列表
       */
      export function djlDistrict() {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/other-v100001/addr/DjlDistrict"
        );
      }
      export interface ListParams {
        /**
         * @description  pid
         */
        pid?: number;
      }
      /**
       * @name 省市区地址下拉选项
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<Array<AddrEntity>>>(
          "/other-v100001/addr/list",
          { params }
        );
      }
      /**
       * @name 在线找房重庆九大主城区下拉选项
       */
      export function listV1() {
        return request.get<ResponseResult<Array<AddrEntity>>>(
          "/other-v100001/addr/listV1"
        );
      }
      export interface SuggestionParams {
        /**
         * @description  关键字
         */
        keyword?: string;
        /**
         * @description  城市中文(切换城市功能)
         */
        region?: string;
      }
      /**
       * @name 物业地址下拉选项
       * @param params
       */
      export function suggestion(params: SuggestionParams) {
        return request.get<ResponseResult<Array<SuggestionEntity>>>(
          "/other-v100001/addr/suggestion",
          { params }
        );
      }
      export interface UpdateCityParams {
        /**
         * @description  城市id
         */
        cityId?: number;
      }
      /**
       * @name 切换城市
       * @param params
       */
      export function updateCity(params: UpdateCityParams) {
        return request.get<ResponseResult<LoginVo>>(
          "/other-v100001/addr/updateCity",
          { params }
        );
      }
    }
    export namespace houseConfig {
      export interface HouseAfterAuditConfigParams {
        /**
         * @description  合同图片是否上传 1验证 2不验证
         */
        contractImg?: number;
        /**
         * @description  业主合同是否签字 1验证，2不验证
         */
        contractSignature?: number;
        /**
         * @description  业主交割单是否签字  1验证，2不验证
         */
        deliveryOrderSignature?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  业主身份证是否上传 1验证 2不验证
         */
        idCardImg?: number;
        /**
         * @description  其它字段验证，传id过来，多个以逗号隔开，当前字典Pid:135
         */
        otherDicIds?: string;
        /**
         * @description  其他图片是否上传 1验证 2不验证
         */
        otherImg?: number;
        /**
         * @description  费用结算单业主是否签字 1验证 2不验证
         */
        settlementSignature?: number;
      }
      /**
       * @name 业主租后扫描设置
       * @param data
       */
      export function houseAfterAuditConfig(data: HouseAfterAuditConfigParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseConfig/houseAfterAuditConfig",
          data
        );
      }
      export interface HouseBeforeAuditConfigParams {
        /**
         * @description  合同开始时间小于录入时间为异常 1验证 2不验证
         */
        contractTimeLessCreateTime?: number;
        /**
         * @description  合同期限低多少年为异常
         */
        contractYear?: number;
        /**
         * @description  集中与整租押金高于月租金为异常 1验证 2不验证
         */
        depositAboveRent?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  累积免租期小于每年多少天为异常
         */
        periodDay?: number;
        /**
         * @description  房租有递增为异常 1验证 2不验证
         */
        rentIncreasing?: number;
      }
      /**
       * @name 业主租前扫描设置
       * @param data
       */
      export function houseBeforeAuditConfig(
        data: HouseBeforeAuditConfigParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseConfig/houseBeforeAuditConfig",
          data
        );
      }
      export interface HouseExitAuditConfigParams {
        /**
         * @description  违约退租：检查违约金有没有值 1验证，2不验证
         */
        breachExitHouseAmountVal?: number;
        /**
         * @description  正常退租：超期房租（超过房东合同结束时间要查询超期房租没有值，没有值提示用户异常） 1验证，2不验证
         */
        houseExitOverdueRentAmountVal?: number;
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 业主退租扫描设置
       * @param data
       */
      export function houseExitAuditConfig(data: HouseExitAuditConfigParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseConfig/houseExitAuditConfig",
          data
        );
      }
      export interface TenantsAfterAuditConfigParams {
        /**
         * @description  合同图片是否上传 1验证 2不验证
         */
        contractImg?: number;
        /**
         * @description  租客合同是否签字 1验证，2不验证
         */
        contractSignature?: number;
        /**
         * @description  租客交割单是否签字  1验证，2不验证
         */
        deliveryOrderSignature?: number;
        /**
         * @description  第一次房租是否支付 1验证 2不验证
         */
        houseRentOne?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  租客身份证是否上传 1验证 2不验证
         */
        idCardImg?: number;
        /**
         * @description  其它字段验证，传id过来，多个以逗号隔开，当前字典Pid:131
         */
        otherDicIds?: string;
        /**
         * @description  其他图片是否上传 1验证 2不验证
         */
        otherImg?: number;
        /**
         * @description  费用结算单业主是否签字 1验证 2不验证
         */
        settlementSignature?: number;
      }
      /**
       * @name 租客租后扫描设置
       * @param data
       */
      export function tenantsAfterAuditConfig(
        data: TenantsAfterAuditConfigParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseConfig/tenantsAfterAuditConfig",
          data
        );
      }
      export interface TenantsBeforeAuditConfigParams {
        /**
         * @description  合同起算时间延后多少天视为异常  【有的员工随意给租客延长合同起算时间，例如2月1号签约，给租客从2月10号起算。】
         */
        contractStartDay?: number;
        /**
         * @description  合同期限小于多少年为异常
         */
        contractYear?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  新签合同什么费用是不是填写,多个费用请以逗号隔开填写 字段pid:92
         */
        payOtherAmountDicIds?: string;
        /**
         * @description  租客年龄大于岁视为异常
         */
        tenantsAge?: number;
        /**
         * @description  租金低于定价多少元为异常
         */
        tenantsAmountBelowBargainAmount?: number;
      }
      /**
       * @name 租客租前扫描设置
       * @param data
       */
      export function tenantsBeforeAuditConfig(
        data: TenantsBeforeAuditConfigParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseConfig/tenantsBeforeAuditConfig",
          data
        );
      }
      export interface TenantsExitAuditConfigParams {
        /**
         * @description  违约退房:退还租客押金大于多少元视为异常
         */
        breachTenantExitDeposit?: number;
        /**
         * @description  正常退房:退还租客租金大于多少元为异常
         */
        exitTenantsAmount?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  没有签成:有应退金额视为异常 1验证，2不验证
         */
        noContractExitAmount?: number;
        /**
         * @description  正常退房:未结算水电气、物业费（水电气金额为0）为异常 1验证 2不验证
         */
        noSettlementWaterElGas?: number;
        /**
         * @description  没有填写水电气止数据为异常 1验证 2不验证
         */
        noWaterElGasNum?: number;
        /**
         * @description  正常退房:有超期房租未计算或者少算视为异常(退房时间大于租赁结束时间) 1验证 2不验证
         */
        tenantsExitOverdueRentAmountVal?: number;
      }
      /**
       * @name 租客退租扫描设置
       * @param data
       */
      export function tenantsExitAuditConfig(
        data: TenantsExitAuditConfigParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseConfig/tenantsExitAuditConfig",
          data
        );
      }
    }
    export namespace auditRecodeHouse {
      export interface AuditHouseParams {
        /**
         * @description  审核项目json
         */
        auditJson?: string;
        /**
         * @description  是否审核 1已通过  2未通过
         */
        isAudit: number;
        /**
         * @description  其它业务表id
         */
        otherId: number;
        /**
         * @description  1租前  2租后
         */
        type?: number;
      }
      /**
       * @name 房东租前，租后审核
       * @param data
       */
      export function auditHouse(data: AuditHouseParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeHouse/auditHouse",
          data
        );
      }
      export interface AuditHouseAfterScanParams {
        /**
         * @description  房源id
         */
        houseId?: number;
      }
      /**
       * @name 房东租后扫描
       * @param data
       */
      export function auditHouseAfterScan(data: AuditHouseAfterScanParams) {
        return request.post<ResponseResult<Array<ScanAuditVo>>>(
          "/other-v100001/auditRecodeHouse/auditHouseAfterScan",
          data
        );
      }
      export interface AuditHouseBeforeScanParams {
        /**
         * @description  房源id
         */
        houseId?: number;
      }
      /**
       * @name 房东租前扫描
       * @param data
       */
      export function auditHouseBeforeScan(data: AuditHouseBeforeScanParams) {
        return request.post<ResponseResult<Array<ScanAuditVo>>>(
          "/other-v100001/auditRecodeHouse/auditHouseBeforeScan",
          data
        );
      }
      export interface AuditHouseListAfterParams {
        /**
         * @description  houseIds
         */
        houseIds?: string;
      }
      /**
       * @name 批量房东租后审核
       * @param data
       */
      export function auditHouseListAfter(data: AuditHouseListAfterParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeHouse/auditHouseListAfter",
          data
        );
      }
      export interface ExportParams {
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(整租，合租)
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  导出报表结束时间（导出管家半年内巡房数据使用）
         */
        exportTimeEnd?: string;
        /**
         * @description  导出报表开始时间（导出管家半年内巡房数据使用）
         */
        exportTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东创建时间结束
         */
        houseCreateTimeEnd?: string;
        /**
         * @description  房东创建时间开始
         */
        houseCreateTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  宽带是否安装:1未安装 2已安装 3已到期
         */
        isBroadband?: number;
        /**
         * @description  是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  家财险是否购买:1未购买 2已购买 3已到期
         */
        isInsurance?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        /**
         * @description  缴费方式名称
         */
        payCostTypeId?: number;
        /**
         * @description  推送58的状态：1 已推送 ，2 未推送
         */
        pushStatus?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 房东审核列表导出(租前，租后，都是这个接口，审核字段取不同的就行)
       * @param params
       */
      export function exports(params: ExportParams) {
        return request.get("/other-v100001/auditRecodeHouse/export", {
          params
        });
      }
      export interface HouseListParams {
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(整租，合租)
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeEnd?: string;
        /**
         * @description  托管结束开始时间
         */
        endTimeStart?: string;
        /**
         * @description  导出报表结束时间（导出管家半年内巡房数据使用）
         */
        exportTimeEnd?: string;
        /**
         * @description  导出报表开始时间（导出管家半年内巡房数据使用）
         */
        exportTimeStart?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  房东创建时间结束
         */
        houseCreateTimeEnd?: string;
        /**
         * @description  房东创建时间开始
         */
        houseCreateTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  宽带是否安装:1未安装 2已安装 3已到期
         */
        isBroadband?: number;
        /**
         * @description  是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  家财险是否购买:1未购买 2已购买 3已到期
         */
        isInsurance?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否租完 1已租完 2未租完
         */
        isRentOut?: number;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费方式名称
         */
        payCostTypeId?: number;
        /**
         * @description  推送58的状态：1 已推送 ，2 未推送
         */
        pushStatus?: string;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(托管结束) 房东合同到期报表
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(托管开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室
         */
        room?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始结束时间
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间
         */
        startTimeStart?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 房东审核列表(租前，租后，都是这个接口，审核字段取不同的就行)
       * @param params
       */
      export function houseList(params: HouseListParams) {
        return request.get<ResponseResult<PageList<HouseAuditVo>>>(
          "/other-v100001/auditRecodeHouse/houseList",
          { params }
        );
      }
      /**
       * @name upadateOtherStatus
       */
      export function upadateOtherStatus() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeHouse/upadateOtherStatus"
        );
      }
      export interface UpdateRemarkParams {
        /**
         * @description  审批备注
         */
        auditRemark?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  1租前  2租后
         */
        type?: number;
      }
      /**
       * @name 房东租前租后修改备注
       * @param data
       */
      export function updateRemark(data: UpdateRemarkParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeHouse/updateRemark",
          data
        );
      }
    }
    export namespace auditRecodeExit {
      export interface AuditHouseExitParams {
        /**
         * @description  审核项目json
         */
        auditJson?: string;
        /**
         * @description  是否审核 1已通过  2未通过
         */
        isAudit: number;
        /**
         * @description  其它业务表id
         */
        otherId: number;
      }
      /**
       * @name 审核房东退房
       * @param data
       */
      export function auditHouseExit(data: AuditHouseExitParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeExit/auditHouseExit",
          data
        );
      }
      export interface AuditHouseExitScanParams {
        /**
         * @description  房源id
         */
        houseId?: number;
      }
      /**
       * @name 房东退租扫描
       * @param data
       */
      export function auditHouseExitScan(data: AuditHouseExitScanParams) {
        return request.post<ResponseResult<Array<ScanAuditVo>>>(
          "/other-v100001/auditRecodeExit/auditHouseExitScan",
          data
        );
      }
      export interface HouseExitListParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        endTimeStart?: string;
        /**
         * @description  1 正常退房 2没有签成 3房东违约 4公司违约
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  租赁开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 房东退房记录审核列表
       * @param params
       */
      export function houseExitList(params: HouseExitListParams) {
        return request.get<ResponseResult<PageList<HouseExit>>>(
          "/other-v100001/auditRecodeExit/houseExitList",
          { params }
        );
      }
      /**
       * @name 审核记录表详情
       * @param id 审核记录表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<AuditRecode>>(
          `/other-v100001/auditRecodeExit/info/${id}`
        );
      }
      export interface UpdateHouseRemarksParams {
        /**
         * @description  退房表记录id主键
         */
        id?: number;
        /**
         * @description  备注
         */
        remarks?: string;
      }
      /**
       * @name 单独修改备注
       * @param data
       */
      export function updateHouseRemarks(data: UpdateHouseRemarksParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeExit/updateHouseRemarks",
          data
        );
      }
      export interface AuditTenantsExitParams {
        /**
         * @description  审核项目json
         */
        auditJson?: string;
        /**
         * @description  是否审核 1已通过  2未通过
         */
        isAudit: number;
        /**
         * @description  其它业务表id
         */
        otherId: number;
      }
      /**
       * @name 审核租客退房
       * @param data
       */
      export function auditTenantsExit(data: AuditTenantsExitParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeExit/auditTenantsExit",
          data
        );
      }
      export interface AuditTenantsExitScanParams {
        /**
         * @description  tenantsId
         */
        tenantsId?: number;
      }
      /**
       * @name 租客退租扫描
       * @param data
       */
      export function auditTenantsExitScan(data: AuditTenantsExitScanParams) {
        return request.post<ResponseResult<Array<ScanAuditVo>>>(
          "/other-v100001/auditRecodeExit/auditTenantsExitScan",
          data
        );
      }
      export interface SaveNewHouseDebtParams {
        /**
         * @description  生成新房欠款金额
         */
        debtAmount?: number;
        /**
         * @description  退房表记录id主键
         */
        id?: number;
      }
      /**
       * @name 租客退租换房生成新房欠款
       * @param data
       */
      export function saveNewHouseDebt(data: SaveNewHouseDebtParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeExit/saveNewHouseDebt",
          data
        );
      }
      export interface TenantsExitListParams {
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        crateTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        crateTimeStart?: string;
        /**
         * @description  退房人
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  退房性质id
         */
        exitId?: number;
        /**
         * @description  退房时间结束
         */
        exitTimeEnd?: string;
        /**
         * @description  退房时间开始
         */
        exitTimeStart?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否审核 1已审核  2驳回  3未审核
         */
        isAudit?: number;
        /**
         * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
         */
        isMark?: number;
        /**
         * @description  超期退租标记月份
         */
        isMark1Month?: string;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  支付状态 1未退  2已退部分  3已退完
         */
        payStatus?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 租客退房记录审核列表
       * @param params
       */
      export function tenantsExitList(params: TenantsExitListParams) {
        return request.get<ResponseResult<PageList<TenantsExit>>>(
          "/other-v100001/auditRecodeExit/tenantsExitList",
          { params }
        );
      }
      export interface UpdateRemarksParams {
        /**
         * @description  退房表记录id主键
         */
        id?: number;
        /**
         * @description  备注
         */
        remarks?: string;
      }
      /**
       * @name 单独修改备注
       * @param data
       */
      export function updateRemarks(data: UpdateRemarksParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeExit/updateRemarks",
          data
        );
      }
    }
    export namespace auditRecodeTenants {
      export interface AuditTenantsParams {
        /**
         * @description  审核项目json
         */
        auditJson?: string;
        /**
         * @description  是否审核 1已通过  2未通过
         */
        isAudit: number;
        /**
         * @description  其它业务表id
         */
        otherId: number;
        /**
         * @description  1租前  2租后
         */
        type?: number;
      }
      /**
       * @name 租客租前，租后审核
       * @param data
       */
      export function auditTenants(data: AuditTenantsParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeTenants/auditTenants",
          data
        );
      }
      export interface AuditTenantsAfterScanParams {
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 租客租后扫描
       * @param data
       */
      export function auditTenantsAfterScan(data: AuditTenantsAfterScanParams) {
        return request.post<ResponseResult<Array<ScanAuditVo>>>(
          "/other-v100001/auditRecodeTenants/auditTenantsAfterScan",
          data
        );
      }
      export interface AuditTenantsBeforeScanParams {
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 租客租前扫描
       * @param data
       */
      export function auditTenantsBeforeScan(
        data: AuditTenantsBeforeScanParams
      ) {
        return request.post<ResponseResult<Array<ScanAuditVo>>>(
          "/other-v100001/auditRecodeTenants/auditTenantsBeforeScan",
          data
        );
      }
      export interface AuditTenantsListAfterParams {
        /**
         * @description  tenantsIds
         */
        tenantsIds?: string;
      }
      /**
       * @name 租客租后审核批量操作
       * @param data
       */
      export function auditTenantsListAfter(data: AuditTenantsListAfterParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeTenants/auditTenantsListAfter",
          data
        );
      }
      export interface ExportParams {
        /**
         * @description  区id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  1 有空调，0无空调
         */
        conditioner?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  装修状态（集中）
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  户型/房型id  主，次卧（合租才有）
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  第一次房租支付状态 1-已付 2-未付
         */
        houseRentOne?: number;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租客是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  查看身份证是否上传
         */
        isTenantImg?: number;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  在线找房上传状态：1，已上传；2未上传
         */
        outShowStatus?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  联系电话2
         */
        phone2?: string;
        /**
         * @description  空置时间搜索条件
         */
        rentOutEndTimeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1正常   3冻结
         */
        status?: number;
        /**
         * @description  出租状态（系统字典）
         */
        statusId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id2
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租客审核列表导出(租前，租后，都是这个接口，审核字段取不同的就行)
       * @param params
       */
      export function exports(params: ExportParams) {
        return request.get("/other-v100001/auditRecodeTenants/export", {
          params
        });
      }
      export interface TenantsListParams {
        /**
         * @description  区id
         */
        areaId?: number;
        /**
         * @description  审核时间结束
         */
        auditTimeEnd?: string;
        /**
         * @description  审核时间开始
         */
        auditTimeStart?: string;
        /**
         * @description  业务人员
         */
        businessId?: number;
        /**
         * @description  客源人
         */
        businessId2?: number;
        /**
         * @description  美好家园：1同步成功 2未同步  3同步失败
         */
        ccbStatus?: number;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  1 有空调，0无空调
         */
        conditioner?: number;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  合同签名 1-未签名 2-已签名
         */
        contractRecordIsSignature?: number;
        /**
         * @description  创建时间结束
         */
        createTenantsTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTenantsTimeStart?: string;
        /**
         * @description  装修状态（集中）
         */
        decorateId?: number;
        /**
         * @description  交割单签名 1-未签名 2-已签名
         */
        deliveryOrderIsSignature?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  户型/房型id  主，次卧（合租才有）
         */
        doorModelId?: number;
        /**
         * @description  几厅（集中才有）
         */
        hall?: number;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  第一次房租支付状态 1-已付 2-未付
         */
        houseRentOne?: number;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  租客是否到期 1未到期  2已到期
         */
        isEndTime?: number;
        /**
         * @description  是否提交了退房申请(1,已提交；2未提交)
         */
        isExit?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  查看身份证是否上传
         */
        isTenantImg?: number;
        /**
         * @description  与我相关 1自己  2审核
         */
        isUserRelated?: number;
        /**
         * @description  租赁结束时间结束
         */
        leaseEndTimeEnd?: string;
        /**
         * @description  租赁结束时间开始
         */
        leaseEndTimeStart?: string;
        /**
         * @description  租赁开始时间结束
         */
        leaseStartTimeEnd?: string;
        /**
         * @description  租赁开始时间开始
         */
        leaseStartTimeStart?: string;
        /**
         * @description  租客姓名
         */
        name?: string;
        /**
         * @description  在线找房上传状态：1，已上传；2未上传
         */
        outShowStatus?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  租客付款方式id
         */
        payTypeId?: number;
        /**
         * @description  联系电话
         */
        phone?: string;
        /**
         * @description  联系电话2
         */
        phone2?: string;
        /**
         * @description  空置时间搜索条件
         */
        rentOutEndTimeId?: number;
        /**
         * @description  录入报表查询时间
         */
        reportTime?: string;
        /**
         * @description  报表查询时间(租赁结束) 出房业绩使用
         */
        reportTimeEnd?: string;
        /**
         * @description  报表查询时间(租赁开始) 收房业绩使用
         */
        reportTimeStart?: string;
        /**
         * @description  几室（集中才有）
         */
        room?: number;
        /**
         * @description  房间号/门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1正常   3冻结
         */
        status?: number;
        /**
         * @description  出租状态（系统字典）
         */
        statusId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  管家id2
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租前  2租后
         */
        type?: number;
        /**
         * @description  几卫（集中才有）（合租0表示无，1表示有）
         */
        who?: number;
      }
      /**
       * @name 租客审核列表(租前，租后，都是这个接口，审核字段取不同的就行)
       * @param params
       */
      export function tenantsList(params: TenantsListParams) {
        return request.get<ResponseResult<PageList<TenantsAuditVo>>>(
          "/other-v100001/auditRecodeTenants/tenantsList",
          { params }
        );
      }
      /**
       * @name upadateOtherStatus
       */
      export function upadateOtherStatus() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeTenants/upadateOtherStatus"
        );
      }
      export interface UpdateRemarkParams {
        /**
         * @description  审批备注
         */
        auditRemark?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  1租前  2租后
         */
        type?: number;
      }
      /**
       * @name 租客租前租后修改备注
       * @param data
       */
      export function updateRemark(data: UpdateRemarkParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/auditRecodeTenants/updateRemark",
          data
        );
      }
    }
    export namespace noLogin {
      export namespace bargain {
        export interface GetBargainContractContentParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
          /**
           * @description  主键
           */
          id?: number;
        }
        /**
         * @name 获取定金合同内容
         * @param params
         */
        export function getBargainContractContent(
          params: GetBargainContractContentParams
        ) {
          return request.get<ResponseResult<Bargain>>(
            "/other-v100001/noLogin/bargain/getBargainContractContent",
            { params }
          );
        }
        export interface GetBargainContractUrlParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
          /**
           * @description  主键
           */
          id?: number;
        }
        /**
         * @name 获取定金合同下载路径
         * @param params
         */
        export function getBargainContractUrl(
          params: GetBargainContractUrlParams
        ) {
          return request.get<ResponseResult<object>>(
            "/other-v100001/noLogin/bargain/getBargainContractUrl",
            { params }
          );
        }
        export interface GetPhoneBargainParams {
          /**
           * @description  phone
           */
          phone?: string;
        }
        /**
         * @name 通过租客手机号查询定金记录
         * @param params
         */
        export function getPhoneBargain(params: GetPhoneBargainParams) {
          return request.get<ResponseResult<Array<Bargain>>>(
            "/other-v100001/noLogin/bargain/getPhoneBargain",
            { params }
          );
        }
        export interface InfoParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
        }
        /**
         * @name 定金记录表详情
         * @param id 定金记录表详情
         * @param params
         */
        export function info(id: string | number, params: InfoParams) {
          return request.get<ResponseResult<object>>(
            `/other-v100001/noLogin/bargain/info/${id}`,
            { params }
          );
        }
        export interface PayBargainParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
          /**
           * @description  小程序code
           */
          code?: string;
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  前端计算出来的支付金额
           */
          payMoneyShow?: number;
        }
        /**
         * @name 租客交定金
         * @param data
         */
        export function payBargain(data: PayBargainParams) {
          return request.post<ResponseResult<JHPayResult>>(
            "/other-v100001/noLogin/bargain/payBargain",
            data
          );
        }
        export interface SignatureParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
          /**
           * @description  主键
           */
          id?: number;
        }
        /**
         * @name 定金合同签字
         * @param data
         */
        export function signature(data: SignatureParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/noLogin/bargain/signature",
            data
          );
        }
      }
      export namespace contractRecord {
        /**
         * @name 上上签异步通知
         */
        export function callback() {
          return request.post<ResponseResult<object>>(
            "/other-v100001/noLogin/contractRecord/callback"
          );
        }
        export interface PreviewParams {
          /**
           * @description  城市标志
           */
          cityCode?: string;
        }
        /**
         * @name 预览合同
         * @param id 预览合同
         * @param params
         */
        export function preview(id: string | number, params: PreviewParams) {
          return request.get<ResponseResult<object>>(
            `/other-v100001/noLogin/contractRecord/preview/${id}`,
            { params }
          );
        }
        /**
         * @name text
         */
        export function text() {
          return request.post<ResponseResult<object>>(
            "/other-v100001/noLogin/contractRecord/text"
          );
        }
      }
      export namespace maintenanceClean {
        export interface InfoParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
        }
        /**
         * @name 维修保洁详情
         * @param id 维修保洁详情
         * @param params
         */
        export function info(id: string | number, params: InfoParams) {
          return request.get<ResponseResult<object>>(
            `/other-v100001/noLogin/maintenanceClean/info/${id}`,
            { params }
          );
        }
      }
      export namespace oss {
        /**
         * @name oss回调
         */
        export function callBack() {
          return request.post<ResponseResult<object>>(
            "/other-v100001/noLogin/oss/callBack"
          );
        }
      }
      export namespace preLookHouse {
        export interface AreaListParams {
          /**
           * @description  companyId
           */
          companyId?: number;
        }
        /**
         * @name 区域下拉框
         * @param params
         */
        export function areaList(params: AreaListParams) {
          return request.get<ResponseResult<Array<AddrEntity>>>(
            "/other-v100001/noLogin/preLookHouse/areaList",
            { params }
          );
        }
        export interface DownloadFileParams {
          /**
           * @description  url
           */
          url?: string;
        }
        /**
         * @name 下载文件不支持批量下载
         * @param params
         */
        export function downloadFile(params: DownloadFileParams) {
          return request.get(
            "/other-v100001/noLogin/preLookHouse/downloadFile",
            { params }
          );
        }
        export interface GetCompanyNameParams {
          /**
           * @description  companyId
           */
          companyId?: number;
        }
        /**
         * @name 公寓名称
         * @param params
         */
        export function getCompanyName(params: GetCompanyNameParams) {
          return request.get<ResponseResult<string>>(
            "/other-v100001/noLogin/preLookHouse/getCompanyName",
            { params }
          );
        }
        export interface GetDetailNameSelectParams {
          /**
           * @description  detailLike
           */
          detailLike?: string;
          /**
           * @description  companyId
           */
          companyId?: number;
        }
        /**
         * @name 房源物业下拉框 本地检索
         * @param params
         */
        export function getDetailNameSelect(params: GetDetailNameSelectParams) {
          return request.get<ResponseResult<Array<SelectStrVo>>>(
            "/other-v100001/noLogin/preLookHouse/getDetailNameSelect",
            { params }
          );
        }
        /**
         * @name 在线找房详情
         * @param id 在线找房详情
         */
        export function info(id: string | number) {
          return request.get<ResponseResult<OnlineHouse>>(
            `/other-v100001/noLogin/preLookHouse/info/${id}`
          );
        }
        export interface ListParams {
          /**
           * @description  区id
           */
          areaId?: number;
          /**
           * @description  客户id
           */
          companyId?: number;
          /**
           * @description  物业地区id
           */
          detailId?: string;
          /**
           * @description  物业地区名称
           */
          detailName?: string;
          /**
           * @description  几厅
           */
          hall?: number;
          /**
           * @description  1整租 2合租 3集中
           */
          houseType?: number;
          /**
           * @description  是否可以短租 1可以 2不行
           */
          isShortRent?: number;
          /**
           * @description  是否特价房 1否 2是
           */
          isSpecialOffer?: number;
          /**
           * @description  当前人所在经度
           */
          lat1?: string;
          /**
           * @description  当前人所在纬度
           */
          lng1?: string;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  出租金额最小值  前端定义 0-1000   1000-2000   2000 - 3000 3000-5000 5000-10000
           */
          pricingMinAmountRange?: string;
          /**
           * @description  几室
           */
          room?: number;
          sortField?: string;
          sortWay?: string;
        }
        /**
         * @name 在线找房列表
         * @param params
         */
        export function list(params: ListParams) {
          return request.get<ResponseResult<PageList<OnlineHouseVo>>>(
            "/other-v100001/noLogin/preLookHouse/list",
            { params }
          );
        }
        export interface SaveParams {
          /**
           * @description  客户名称
           */
          name?: string;
          /**
           * @description  客户电话
           */
          phone?: string;
          /**
           * @description  预约时间
           */
          preTime?: string;
          /**
           * @description  预约说明
           */
          remark?: string;
          /**
           * @description  房间id
           */
          roomId: number;
        }
        /**
         * @name 微信预约看房新增
         * @param data
         */
        export function save(data: SaveParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/noLogin/preLookHouse/save",
            data
          );
        }
        export interface SuggestionParams {
          /**
           * @description  关键字
           */
          keyword?: string;
          /**
           * @description  companyId
           */
          companyId?: number;
        }
        /**
         * @name 物业地址下拉选项（不用了）
         * @param params
         */
        export function suggestion(params: SuggestionParams) {
          return request.get<ResponseResult<Array<SuggestionEntity>>>(
            "/other-v100001/noLogin/preLookHouse/suggestion",
            { params }
          );
        }
      }
      export namespace signUser {
        export interface DeliveryOrderInfoParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
        }
        /**
         * @name 交割单详情
         * @param id 交割单详情
         * @param params
         */
        export function deliveryOrderInfo(
          id: string | number,
          params: DeliveryOrderInfoParams
        ) {
          return request.get<ResponseResult<object>>(
            `/other-v100001/noLogin/signUser/deliveryOrderInfo/${id}`,
            { params }
          );
        }
        export interface DeliveryOrderSignatureUserParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
          /**
           * @description  主键
           */
          id?: number;
        }
        /**
         * @name 交割单签字,或重签 ，租客与房东端 需要 判断，如果已签字，那不能在签了
         * @param data
         */
        export function deliveryOrderSignatureUser(
          data: DeliveryOrderSignatureUserParams
        ) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/noLogin/signUser/deliveryOrderSignatureUser",
            data
          );
        }
        export interface HouseSettlementInfoParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
        }
        /**
         * @name 费用结算单表详情
         * @param id 费用结算单表详情
         * @param params
         */
        export function houseSettlementInfo(
          id: string | number,
          params: HouseSettlementInfoParams
        ) {
          return request.get<ResponseResult<object>>(
            `/other-v100001/noLogin/signUser/houseSettlementInfo/${id}`,
            { params }
          );
        }
        export interface HouseSettlementSignatureUserParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
          /**
           * @description  主键
           */
          id?: number;
        }
        /**
         * @name 结算单签字，租客与房东端 需要 判断，如果已签字，那不能在签了
         * @param data
         */
        export function houseSettlementSignatureUser(
          data: HouseSettlementSignatureUserParams
        ) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/noLogin/signUser/houseSettlementSignatureUser",
            data
          );
        }
      }
    }
    export namespace broadband {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 宽带表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/other-v100001/broadband/deleteById",
          { params }
        );
      }
      /**
       * @name 宽带表详情
       * @param id 宽带表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Broadband>>(
          `/other-v100001/broadband/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  宽带账号
         */
        account?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  到期查询时间
         */
        endTime?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  安装日期
         */
        installationTime?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  服务商id(系统字典)
         */
        serviceProvidersId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1，正常；2，停网；3，注销
         */
        status?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 宽带表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Broadband>>>(
          "/other-v100001/broadband/list",
          { params }
        );
      }
      /**
       * @name 注销宽带
       * @param id 注销宽带
       */
      export function logOff(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/broadband/logOff/${id}`
        );
      }
      export interface LogOffV1Params {
        /**
         * @description  到期时间
         */
        endTime?: string;
      }
      /**
       * @name 注销宽带V1
       * @param data
       */
      export function logOffV1(data: LogOffV1Params) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/broadband/logOffV1",
          data
        );
      }
      export interface RenewBatchParams {
        /**
         * @description  主键集合
         */
        ids?: string;
        /**
         * @description  宽带月份
         */
        monthBroadband?: number;
        /**
         * @description  宽带年限
         */
        yearBroadband?: number;
      }
      /**
       * @name 批量续费宽带
       * @param data
       */
      export function renewBatch(data: RenewBatchParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/broadband/renewBatch",
          data
        );
      }
      export interface SaveParams {
        /**
         * @description  宽带账号
         */
        account?: string;
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  安装日期
         */
        installationTimeStr?: string;
        /**
         * @description  维修电话
         */
        maintainPhone?: string;
        /**
         * @description  兆数
         */
        megaNumber: number;
        /**
         * @description  宽带月份
         */
        monthBroadband: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  费用
         */
        payAmount?: number;
        /**
         * @description  密码
         */
        pwd?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  路由器账号
         */
        routeAccount?: string;
        /**
         * @description  路由器密码
         */
        routePwd?: string;
        /**
         * @description  服务商id(系统字典)
         */
        serviceProvidersId: number;
        /**
         * @description  wifi名称
         */
        wifiName?: string;
        /**
         * @description  wifi密码
         */
        wifiPwd?: string;
        /**
         * @description  宽带年限
         */
        yearBroadband: number;
      }
      /**
       * @name 宽带表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/broadband/save",
          data
        );
      }
      export interface StopNetworkBatchParams {
        /**
         * @description  主键集合
         */
        ids?: string;
      }
      /**
       * @name 批量停网
       * @param data
       */
      export function stopNetworkBatch(data: StopNetworkBatchParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/broadband/stopNetworkBatch",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  宽带账号
         */
        account?: string;
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  安装日期
         */
        installationTimeStr?: string;
        /**
         * @description  维修电话
         */
        maintainPhone?: string;
        /**
         * @description  兆数
         */
        megaNumber: number;
        /**
         * @description  宽带月份
         */
        monthBroadband: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  费用
         */
        payAmount?: number;
        /**
         * @description  密码
         */
        pwd?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  路由器账号
         */
        routeAccount?: string;
        /**
         * @description  路由器密码
         */
        routePwd?: string;
        /**
         * @description  服务商id(系统字典)
         */
        serviceProvidersId: number;
        /**
         * @description  wifi名称
         */
        wifiName?: string;
        /**
         * @description  wifi密码
         */
        wifiPwd?: string;
        /**
         * @description  宽带年限
         */
        yearBroadband: number;
      }
      /**
       * @name 宽带表修改
       * @param id 宽带表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/broadband/update/${id}`,
          data
        );
      }
      export interface UpdateRemarkParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  备注
         */
        remark?: string;
      }
      /**
       * @name 单独修改备注
       * @param data
       */
      export function updateRemark(data: UpdateRemarkParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/broadband/updateRemark",
          data
        );
      }
      /**
       * @name 宽带导入模版下载
       */
      export function createExcelTemplate() {
        return request.get<ResponseEntity>(
          "/other-v100001/broadband/createExcelTemplate"
        );
      }
      /**
       * @name 宽带数据导入
       */
      export function insertImplBroadband(data: any) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/broadband/insertImplBroadband",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
    }
    export namespace card {
      export interface GetBankMsgParams {
        /**
         * @description  图片地址
         */
        cardImg?: string;
      }
      /**
       * @name 银行卡信息获取
       * @param params
       */
      export function getBankMsg(params: GetBankMsgParams) {
        return request.get<ResponseResult<BankVo>>(
          "/other-v100001/card/getBankMsg",
          { params }
        );
      }
      export interface GetIdCardMsgParams {
        /**
         * @description  图片地址
         */
        cardImg?: string;
      }
      /**
       * @name 身份证信息获取
       * @param params
       */
      export function getIdCardMsg(params: GetIdCardMsgParams) {
        return request.get<ResponseResult<IdCardVo>>(
          "/other-v100001/card/getIdCardMsg",
          { params }
        );
      }
    }
    export namespace companyDicdata {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 字典表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/other-v100001/companyDicdata/deleteById",
          { params }
        );
      }
      /**
       * @name 字典添加修改获取是否必填数组判断
       */
      export function getCheckRequired() {
        return request.get<ResponseResult<Array<any>>>(
          "/other-v100001/companyDicdata/getCheckRequired"
        );
      }
      /**
       * @name 字典表详情
       * @param id 字典表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<CompanyDicdata>>(
          `/other-v100001/companyDicdata/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  名称
         */
        name?: string;
        /**
         * @description  父级主键
         */
        pid?: number;
      }
      /**
       * @name 字典列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<Array<CompanyDicdata>>>(
          "/other-v100001/companyDicdata/list",
          { params }
        );
      }
      export interface SelectParams {
        /**
         * @description  pid
         */
        pid?: number;
      }
      /**
       * @name 字典下拉
       * @param params
       */
      export function select(params: SelectParams) {
        return request.get<ResponseResult<Array<CompanyDicdata>>>(
          "/other-v100001/companyDicdata/select",
          { params }
        );
      }
      export interface SelectPidListParams {
        /**
         * @description  pidList
         */
        pidList?: string;
      }
      /**
       * @name 一次查询多个字典
       * @param params
       */
      export function selectPidList(params: SelectPidListParams) {
        return request.get<ResponseResult<Array<DicdataPidsVo>>>(
          "/other-v100001/companyDicdata/selectPidList",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  客户系统是否可见 0-否 1-是
         */
        clientShow?: number;
        /**
         * @description  客户端是否可添加 0-否 1-是
         */
        isAdd?: number;
        /**
         * @description  是否分类 0-否 1-是
         */
        isCat?: number;
        /**
         * @description  名称
         */
        name?: string;
        /**
         * @description  父级主键 0表示顶级
         */
        pid?: number;
        /**
         * @description  字典描述
         */
        remarks?: string;
        /**
         * @description  排序
         */
        sorting?: number;
      }
      /**
       * @name 字典表修改
       * @param id 字典表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/companyDicdata/update/${id}`,
          data
        );
      }
      export namespace company {
        export interface SaveParams {
          /**
           * @description  客户系统是否可见 0-否 1-是
           */
          clientShow?: number;
          /**
           * @description  客户端是否可添加 0-否 1-是
           */
          isAdd?: number;
          /**
           * @description  是否分类 0-否 1-是
           */
          isCat?: number;
          /**
           * @description  名称
           */
          name?: string;
          /**
           * @description  父级主键 0表示顶级
           */
          pid?: number;
          /**
           * @description  字典描述
           */
          remarks?: string;
          /**
           * @description  排序
           */
          sorting?: number;
        }
        /**
         * @name 客户字典表新增
         * @param data
         */
        export function save(data: SaveParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/companyDicdata/company/save",
            data
          );
        }
      }
    }
    export namespace complaint {
      export interface AuditParams {
        /**
         * @description  回复说明
         */
        auditDesc?: string;
        /**
         * @description  主键
         */
        id: number;
        /**
         * @description  1通过 2不通过
         */
        status: number;
      }
      /**
       * @name 审核投诉申请
       * @param data
       */
      export function audit(data: AuditParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/complaint/audit",
          data
        );
      }
      export interface CancelParams {
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 租客或者房东：微信投诉撤回
       * @param data
       */
      export function cancel(data: CancelParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/complaint/cancel",
          data
        );
      }
      /**
       * @name 微信投诉列表
       */
      export function complaintList() {
        return request.get<ResponseResult<Array<Complaint>>>(
          "/other-v100001/complaint/complaintList"
        );
      }
      /**
       * @name 微信投诉列表(首页)
       */
      export function complaintListV1() {
        return request.get<ResponseResult<Array<string>>>(
          "/other-v100001/complaint/complaintListV1"
        );
      }
      /**
       * @name 微信投诉删除
       * @param id 微信投诉删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/complaint/delete/${id}`
        );
      }
      export interface ExportExcelParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  处理人2id
         */
        dealId2?: number;
        /**
         * @description  处理人电话
         */
        dealPhone?: string;
        /**
         * @description  处理人2电话
         */
        dealPhone2?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  客户名称
         */
        name?: string;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        /**
         * @description  1受理 2不受理  3申请中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租客 2房东
         */
        typeId?: number;
      }
      /**
       * @name 微信投诉列表 导出
       * @param params
       */
      export function exportExcel(params: ExportExcelParams) {
        return request.get("/other-v100001/complaint/exportExcel", { params });
      }
      /**
       * @name 微信投诉详情
       * @param id 微信投诉详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Complaint>>(
          `/other-v100001/complaint/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  处理人2id
         */
        dealId2?: number;
        /**
         * @description  处理人电话
         */
        dealPhone?: string;
        /**
         * @description  处理人2电话
         */
        dealPhone2?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  客户名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1受理 2不受理  3申请中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1租客 2房东
         */
        typeId?: number;
      }
      /**
       * @name 微信投诉列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Complaint>>>(
          "/other-v100001/complaint/list",
          { params }
        );
      }
      export interface MyListParams {
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 租客或者房东：我的投诉申请列表
       * @param params
       */
      export function myList(params: MyListParams) {
        return request.get<ResponseResult<PageList<Complaint>>>(
          "/other-v100001/complaint/myList",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  投诉类型原因(字典pid 174)
         */
        complaintId: string;
        /**
         * @description  投诉图片多张以逗号隔开
         */
        complaintImg?: string;
        /**
         * @description  投诉类型原因(字典pid 174)
         */
        complaintName: string;
        /**
         * @description  投诉说明
         */
        remark?: string;
        /**
         * @description  1租客 2房东
         */
        typeId: number;
      }
      /**
       * @name 租客或者房东：微信投诉新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/complaint/save",
          data
        );
      }
      /**
       * @name setDeal2
       */
      export function setDeal2() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/complaint/setDeal2"
        );
      }
      /**
       * @name setDealName2
       */
      export function setDealName2() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/complaint/setDealName2"
        );
      }
    }
    export namespace deleteLog {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 删除记录表（回收站）删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/other-v100001/deleteLog/deleteById",
          { params }
        );
      }
      /**
       * @name 删除记录表（回收站）详情
       * @param id 删除记录表（回收站）详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<DeleteLog>>(
          `/other-v100001/deleteLog/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  实体名称
         */
        entityName?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 删除记录表（回收站）列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<DeleteLog>>>(
          "/other-v100001/deleteLog/list",
          { params }
        );
      }
    }
    export namespace excelErrorMsg {
      export interface DeleteByIdParams {
        /**
         * @description  主键id
         */
        ids?: number;
      }
      /**
       * @name 导入错误信息删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/other-v100001/excelErrorMsg/delete",
          { params }
        );
      }
      export interface ErrorMsgExportParams {
        /**
         * @description  导入记录主键
         */
        recordId?: number;
        /**
         * @description  编号
         */
        recordNo?: string;
      }
      /**
       * @name 导入错误信息导出
       * @param params
       */
      export function errorMsgExport(params: ErrorMsgExportParams) {
        return request.get("/other-v100001/excelErrorMsg/errorMsgExport", {
          params
        });
      }
      /**
       * @name 导入错误信息详情
       * @param id 导入错误信息详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<ExcelErrorMsg>>(
          `/other-v100001/excelErrorMsg/info/${id}`
        );
      }
      export interface ListParams {
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  导入记录主键
         */
        recordId?: number;
        /**
         * @description  编号
         */
        recordNo?: string;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 导入错误信息列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<ExcelErrorMsg>>>(
          "/other-v100001/excelErrorMsg/list",
          { params }
        );
      }
    }
    export namespace excelRecord {
      export interface DeleteByIdParams {
        /**
         * @description  主键id
         */
        ids?: number;
      }
      /**
       * @name 导入记录表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/other-v100001/excelRecord/delete",
          { params }
        );
      }
      /**
       * @name 导入记录表详情
       * @param id 导入记录表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<ExcelRecord>>(
          `/other-v100001/excelRecord/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  实体名称
         */
        entityName?: string;
        /**
         * @description  导入文件名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  表示成功，表示失败，表示导入中  字典
         */
        status?: number;
      }
      /**
       * @name 导入记录表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<ExcelRecord>>>(
          "/other-v100001/excelRecord/list",
          { params }
        );
      }
    }
    export namespace financeRecycle {
      /**
       * @name 财务删除数据表删除
       * @param id 财务删除数据表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/financeRecycle/delete/${id}`
        );
      }
      /**
       * @name 财务删除数据表详情
       * @param id 财务删除数据表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<string>>(
          `/other-v100001/financeRecycle/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  创建人ID
         */
        createId?: number;
        /**
         * @description  创建时间(止) 示例：2020-02-01
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间统计月 示例：2020-02
         */
        createTimeMonth?: string;
        /**
         * @description  创建时间(起) 示例：2020-02-01
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  收支类型 1-收入 2-支出
         */
        inoutType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  房间ID
         */
        roomId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  类型 1-租金 2-收支 3-收支明细 4-财务 5-欠款
         */
        type: number;
      }
      /**
       * @name 财务删除数据表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<FinanceRecycle>>>(
          "/other-v100001/financeRecycle/list",
          { params }
        );
      }
      /**
       * @name 财务删除数据表还原
       * @param id 财务删除数据表还原
       */
      export function rollback(id: string | number) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/financeRecycle/rollback/${id}`
        );
      }
    }
    export namespace roomPush {
      export interface GetHRoomPushParams {
        /**
         * @description  房源或房间id
         */
        id?: string;
        /**
         * @description  推送类型:1,为整租房源类型；2为合租房源类型；3，为合租房间类型
         */
        pushType?: string;
      }
      /**
       * @name 获取历史推送选取数据
       * @param params
       */
      export function getHRoomPush(params: GetHRoomPushParams) {
        return request.get<ResponseResult<HRoomPush>>(
          "/other-v100001/roomPush/getHRoomPush",
          { params }
        );
      }
      export interface UpdateStateParams {
        /**
         * @description  房源或房间id
         */
        id?: number;
        /**
         * @description  推送类型:1,为整租房源类型；2为合租房源类型；3，为合租房间类型
         */
        pushType?: string;
        /**
         * @description  状态 枚举 1：待租，2：已定，3：已租，4：不可推广
         */
        status?: string;
      }
      /**
       * @name 手动修改推送房源状态接口
       * @param data
       */
      export function updateState(data: UpdateStateParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/roomPush/updateState",
          data
        );
      }
      /**
       * @name 房源推送58-手动更新token
       */
      export function updateToken() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/roomPush/updateToken"
        );
      }
      export namespace entire {
        export interface SaveParams {
          /**
           * @description  对应系统里面的houseId
           */
          bianHao: string;
          /**
           * @description  朝向 枚举 1：东，2：南，3：西，4：北，5：东南，6：东北，7：西南，8：西北，9：南北，10：东西
           */
          chaoXiang?: string;
          /**
           * @description  厨 值 0～15
           */
          chu?: string;
          /**
           * @description  出租时间 yyyy-mm-dd
           */
          chuZuShiJian?: string;
          /**
           * @description  已出租户数 值 0～15
           */
          chuZuUShu?: string;
          /**
           * @description  出租要求 枚举 1：只限女生，2：中介勿扰，3：一家人，4：禁止养宠物，5：半年起租，6：一年起租，7：租户稳定，8：作息正常，9：禁烟，10：不可带小孩，12：只限男生。可多选，枚举值之间用,连接,如：1,2,3
           */
          chuZuYaoQiu?: string;
          /**
           * @description  描述 长度 20～5000 不能连续8位数字及以上
           */
          content?: string;
          /**
           * @description  封面图
           */
          coverImg?: string;
          /**
           * @description  单元 长度 1～30
           */
          danYuan?: string;
          /**
           * @description  枚举 1：栋，2：幢，3：号，4：号楼，5：单元
           */
          danyuanDanwei?: string;
          /**
           * @description  电梯 枚举 1：是，0：否
           */
          dianTi?: string;
          /**
           * @description  	栋 长度 1～30
           */
          dong?: string;
          /**
           * @description  栋单位 枚举 1：栋，2：弄，3：座，4：号，5：号楼，6：胡同
           */
          dongDanwei?: string;
          /**
           * @description  短租 枚举 1：是，0：否
           */
          duanZu?: string;
          /**
           * @description  短租租金 值 101～400000
           */
          duanZuJin?: number;
          /**
           * @description  短租支付方式 枚举 1：押一付一，2：押二付一，21：月付免押金
           */
          duanZuZhiFuFangShi?: string;
          /**
           * @description  最短租期 枚举 1：1个月，2：2个月
           */
          duanZuZuQi?: string;
          /**
           * @description  房屋情况 枚举 1：普通住宅，2：别墅，3：商住楼，4：公寓，5：平房，6：其他，7：新里洋房，8：老公房，9：四合院，10：排屋
           */
          fangWuQingKuang?: string;
          /**
           * @description  房源亮点 枚举 683014：首次出租，683020：南北通透。可多选，枚举值之间用,连接,如：683014,683020
           */
          fangYuanLiangDian?: string;
          /**
           * @description  包含费用 枚举 1：水费，2：电费，3：燃气费，4：宽带费，5：物业费，6：取暖费，7：有线电视费，8：停车费。可多选，枚举值之间用,连接，如：1,2,3
           */
          feiYongMingXi?: string;
          /**
           * @description  服务费 枚举 1：收取，0：不收取
           */
          fuWuFei?: string;
          /**
           * @description  服务费比例收费 值 0.1～100 保留小数点后一位
           */
          fuWuFeiBiLi?: number;
          /**
           * @description  服务费固定收费 值 1～40000
           */
          fuWuFeiGuDingShouFei?: number;
          /**
           * @description  服务费方式 枚举 1：固定金额，2：月租金比例
           */
          fuWuFeiJin?: string;
          /**
           * @description  服务费 枚举 1：一次性收取，2：按月收取
           */
          fuWuFeiShouQuFangShi?: string;
          /**
           * @description  供暖 枚举 1：集中供暖，2：自供暖，3：不供暖
           */
          gongNuan?: string;
          /**
           * @description  公用配套 枚举 1：冰箱，2：电视，3：洗衣机，4：热水器，5：空调，6：宽带，7：沙发，8：床（独），9：暖气，10：衣柜，11：可做饭，12：独立卫生间，13：独立阳台，15：微波炉，16：燃气灶，17：智能门锁，18：油烟机，19：电磁炉，20：餐桌，21：WIFI，22：橱柜，23：书桌。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          gongYongPeiTao?: string;
          /**
           * @description  合租户数 值 1～15
           */
          heZuHuShu?: string;
          /**
           * @description  房间状态：1，整租 2，合租
           */
          houseType?: string;
          /**
           * @description  户型图
           */
          houseTypeImg?: string;
          /**
           * @description  看房时间 枚举 1：仅周末，2：仅工作日，3：随时看房，4：工作日晚上和周末
           */
          kanFangShiJian?: string;
          /**
           * @description  门牌号
           */
          menPaiHao?: string;
          /**
           * @description  面积 值 5.00～99999.00 保留小数点后两位
           */
          mianJi?: number;
          /**
           * @description  室外图
           */
          outdoorImg?: string;
          /**
           * @description  配套服务 枚举 1：房屋维修，2：代收快递，3：专属客服，4：房屋清洁。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          peiTaoFuWu?: string;
          /**
           * @description  定价最小金额
           */
          pricingMinAmount?: number;
          /**
           * @description  室 值 1～15
           */
          shi?: string;
          /**
           * @description  	总楼层 值 -9～99
           */
          suoZaiLouCeng?: string;
          /**
           * @description  厅 值 0～15
           */
          ting?: string;
          /**
           * @description  标题 长度 10～30
           */
          title?: string;
          /**
           * @description  	卫 值 0～15
           */
          wei?: string;
          /**
           * @description  物业地址
           */
          wuYeDiZhi?: string;
          /**
           * @description  小区名称 长度 1～30
           */
          xiaoQu?: string;
          /**
           * @description  支付方式文本 如：1:1000,2:2000,支付方式为1租金为1000，支付方式为2租金为2000，租金范围0～400000，支付方式枚举 1：付1押1，2：付1押2，3：付2押1，4：付2押2，5：付3押1，6：付3押2，10：半年付押1，11：半年付不押，12：年付不押，13：年付押1，14：付1不押，15：付2不押，16：付3不押，17：半年付押2，18：年付押2
           */
          zhiFuFangShiWenBen?: string;
          /**
           * @description  中介费收取 枚举 1：收取，0：不收取
           */
          zhongJieFei?: string;
          /**
           * @description  固定收费的金额 值 1～40000 保留小数点后两位
           */
          zhongJieFeiGuDingShouFei?: number;
          /**
           * @description  中介费金额收取方式 枚举 1：固定金额，2：半月租金，3：一月租金
           */
          zhongJieFeiJin?: string;
          /**
           * @description  房源状态 枚举 1：待租，2：已定，3：已租，4：不可推广
           */
          zhuangTai?: string;
          /**
           * @description  装修情况 枚举 1：毛坯，2：简单装修，4：精装修，6：豪华装修
           */
          zhuangXiuQingKuang?: string;
          /**
           * @description  总楼层 值 1～99
           */
          zongLouCeng?: string;
          /**
           * @description  最长租期 枚举 1：1年，2：2年，3：3年，4：不限（未传默认1年）
           */
          zuiChangZuQi?: string;
          indoorImg: Array<{
            category: number;
            description: number;
            imageUrl: string;
          }>;
        }
        /**
         * @name 房源推送58信息表新增-整租
         * @param data
         */
        export function save(data: SaveParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/entire/save",
            data
          );
        }
        export interface UpdateParams {
          /**
           * @description  对应系统里面的houseId
           */
          bianHao: string;
          /**
           * @description  朝向 枚举 1：东，2：南，3：西，4：北，5：东南，6：东北，7：西南，8：西北，9：南北，10：东西
           */
          chaoXiang?: string;
          /**
           * @description  厨 值 0～15
           */
          chu?: string;
          /**
           * @description  出租时间 yyyy-mm-dd
           */
          chuZuShiJian?: string;
          /**
           * @description  已出租户数 值 0～15
           */
          chuZuUShu?: string;
          /**
           * @description  出租要求 枚举 1：只限女生，2：中介勿扰，3：一家人，4：禁止养宠物，5：半年起租，6：一年起租，7：租户稳定，8：作息正常，9：禁烟，10：不可带小孩，12：只限男生。可多选，枚举值之间用,连接,如：1,2,3
           */
          chuZuYaoQiu?: string;
          /**
           * @description  描述 长度 20～5000 不能连续8位数字及以上
           */
          content?: string;
          /**
           * @description  封面图
           */
          coverImg?: string;
          /**
           * @description  单元 长度 1～30
           */
          danYuan?: string;
          /**
           * @description  枚举 1：栋，2：幢，3：号，4：号楼，5：单元
           */
          danyuanDanwei?: string;
          /**
           * @description  电梯 枚举 1：是，0：否
           */
          dianTi?: string;
          /**
           * @description  	栋 长度 1～30
           */
          dong?: string;
          /**
           * @description  栋单位 枚举 1：栋，2：弄，3：座，4：号，5：号楼，6：胡同
           */
          dongDanwei?: string;
          /**
           * @description  短租 枚举 1：是，0：否
           */
          duanZu?: string;
          /**
           * @description  短租租金 值 101～400000
           */
          duanZuJin?: number;
          /**
           * @description  短租支付方式 枚举 1：押一付一，2：押二付一，21：月付免押金
           */
          duanZuZhiFuFangShi?: string;
          /**
           * @description  最短租期 枚举 1：1个月，2：2个月
           */
          duanZuZuQi?: string;
          /**
           * @description  房屋情况 枚举 1：普通住宅，2：别墅，3：商住楼，4：公寓，5：平房，6：其他，7：新里洋房，8：老公房，9：四合院，10：排屋
           */
          fangWuQingKuang?: string;
          /**
           * @description  房源亮点 枚举 683014：首次出租，683020：南北通透。可多选，枚举值之间用,连接,如：683014,683020
           */
          fangYuanLiangDian?: string;
          /**
           * @description  包含费用 枚举 1：水费，2：电费，3：燃气费，4：宽带费，5：物业费，6：取暖费，7：有线电视费，8：停车费。可多选，枚举值之间用,连接，如：1,2,3
           */
          feiYongMingXi?: string;
          /**
           * @description  服务费 枚举 1：收取，0：不收取
           */
          fuWuFei?: string;
          /**
           * @description  服务费比例收费 值 0.1～100 保留小数点后一位
           */
          fuWuFeiBiLi?: number;
          /**
           * @description  服务费固定收费 值 1～40000
           */
          fuWuFeiGuDingShouFei?: number;
          /**
           * @description  服务费方式 枚举 1：固定金额，2：月租金比例
           */
          fuWuFeiJin?: string;
          /**
           * @description  服务费 枚举 1：一次性收取，2：按月收取
           */
          fuWuFeiShouQuFangShi?: string;
          /**
           * @description  供暖 枚举 1：集中供暖，2：自供暖，3：不供暖
           */
          gongNuan?: string;
          /**
           * @description  公用配套 枚举 1：冰箱，2：电视，3：洗衣机，4：热水器，5：空调，6：宽带，7：沙发，8：床（独），9：暖气，10：衣柜，11：可做饭，12：独立卫生间，13：独立阳台，15：微波炉，16：燃气灶，17：智能门锁，18：油烟机，19：电磁炉，20：餐桌，21：WIFI，22：橱柜，23：书桌。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          gongYongPeiTao?: string;
          /**
           * @description  合租户数 值 1～15
           */
          heZuHuShu?: string;
          /**
           * @description  房间状态：1，整租 2，合租
           */
          houseType?: string;
          /**
           * @description  户型图
           */
          houseTypeImg?: string;
          /**
           * @description  看房时间 枚举 1：仅周末，2：仅工作日，3：随时看房，4：工作日晚上和周末
           */
          kanFangShiJian?: string;
          /**
           * @description  门牌号
           */
          menPaiHao?: string;
          /**
           * @description  面积 值 5.00～99999.00 保留小数点后两位
           */
          mianJi?: number;
          /**
           * @description  室内图
           */
          outdoorImg?: string;
          /**
           * @description  配套服务 枚举 1：房屋维修，2：代收快递，3：专属客服，4：房屋清洁。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          peiTaoFuWu?: string;
          /**
           * @description  定价最小金额
           */
          pricingMinAmount?: number;
          /**
           * @description  室 值 1～15
           */
          shi?: string;
          /**
           * @description  	总楼层 值 -9～99
           */
          suoZaiLouCeng?: string;
          /**
           * @description  厅 值 0～15
           */
          ting?: string;
          /**
           * @description  标题 长度 10～30
           */
          title?: string;
          /**
           * @description  	卫 值 0～15
           */
          wei?: string;
          /**
           * @description  物业地址
           */
          wuYeDiZhi?: string;
          /**
           * @description  小区名称 长度 1～30
           */
          xiaoQu?: string;
          /**
           * @description  支付方式文本 如：1:1000,2:2000,支付方式为1租金为1000，支付方式为2租金为2000，租金范围0～400000，支付方式枚举 1：付1押1，2：付1押2，3：付2押1，4：付2押2，5：付3押1，6：付3押2，10：半年付押1，11：半年付不押，12：年付不押，13：年付押1，14：付1不押，15：付2不押，16：付3不押，17：半年付押2，18：年付押2
           */
          zhiFuFangShiWenBen?: string;
          /**
           * @description  中介费收取 枚举 1：收取，0：不收取
           */
          zhongJieFei?: string;
          /**
           * @description  固定收费的金额 值 1～40000 保留小数点后两位
           */
          zhongJieFeiGuDingShouFei?: number;
          /**
           * @description  中介费金额收取方式 枚举 1：固定金额，2：半月租金，3：一月租金
           */
          zhongJieFeiJin?: string;
          /**
           * @description  装修情况 枚举 1：毛坯，2：简单装修，4：精装修，6：豪华装修
           */
          zhuangXiuQingKuang?: string;
          /**
           * @description  总楼层 值 1～99
           */
          zongLouCeng?: string;
          /**
           * @description  最长租期 枚举 1：1年，2：2年，3：3年，4：不限（未传默认1年）
           */
          zuiChangZuQi?: string;
          indoorImg: Array<{
            category: number;
            description: number;
            imageUrl: string;
          }>;
        }
        /**
         * @name 房源推送58信息表更新-整租
         * @param data
         */
        export function update(data: UpdateParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/entire/update",
            data
          );
        }
      }
      export namespace push {
        export interface DeleteByIdParams {
          /**
           * @description  房源或房间id
           */
          id?: any;
          /**
           * @description  推送类型:1,为整租房源类型；2为合租房源类型；3，为合租房间类型
           */
          pushType?: string;
        }
        /**
         * @name 删除推送平台的房源或者是房间
         * @param data
         */
        export function deleteById(data: DeleteByIdParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/push/delete",
            data
          );
        }
        export interface DeleteTestParams {
          /**
           * @description  房源或房间id
           */
          id?: string;
          /**
           * @description  推送类型:1,为整租房源类型；2为合租房源类型；3，为合租房间类型
           */
          pushType?: string;
        }
        /**
         * @name 删除推送平台的房源或者是房间
         * @param data
         */
        export function deleteTest(data: DeleteTestParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/push/deleteTest",
            data
          );
        }
      }
      export namespace shared {
        export interface HouseParams {
          /**
           * @description  对应系统里面的houseId
           */
          bianHao: string;
          /**
           * @description  朝向 枚举 1：东，2：南，3：西，4：北，5：东南，6：东北，7：西南，8：西北，9：南北，10：东西
           */
          chaoXiang?: string;
          /**
           * @description  厨 值 0～15
           */
          chu?: number;
          chuZuHuShu?: number;
          /**
           * @description  封面图
           */
          coverImg?: string;
          /**
           * @description  单元 长度 1～30
           */
          danYuan?: string;
          /**
           * @description  枚举 1：栋，2：幢，3：号，4：号楼，5：单元
           */
          danyuanDanwei?: string;
          /**
           * @description  电梯 枚举 1：是，0：否
           */
          dianTi?: string;
          /**
           * @description  栋 长度 1～30
           */
          dong?: string;
          /**
           * @description  栋单位 枚举 1：栋，2：弄，3：座，4：号，5：号楼，6：胡同
           */
          dongDanwei?: string;
          /**
           * @description  房屋情况 枚举 1：普通住宅，2：别墅，3：商住楼，4：公寓，5：平房，6：其他，7：新里洋房，8：老公房，9：四合院，10：排屋
           */
          fangWuQingKuang?: string;
          /**
           * @description  包含费用 枚举 1：水费，2：电费，3：燃气费，4：宽带费，5：物业费，6：取暖费，7：有线电视费，8：停车费。可多选，枚举值之间用,连接，如：1,2,3
           */
          feiYongMingXi?: string;
          /**
           * @description  供暖 枚举 1：集中供暖，2：自供暖，3：不供暖
           */
          gongNuan?: string;
          /**
           * @description  公用配套 枚举 1：冰箱，2：电视，3：洗衣机，4：热水器，5：空调，6：宽带，7：沙发，8：床（独），9：暖气，10：衣柜，11：可做饭，12：独立卫生间，13：独立阳台，15：微波炉，16：燃气灶，17：智能门锁，18：油烟机，19：电磁炉，20：餐桌，21：WIFI，22：橱柜，23：书桌。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          gongYongPeiTao?: string;
          /**
           * @description  合租户数 值 1～15
           */
          heZuHuShu?: number;
          /**
           * @description  户型图
           */
          houseTypeImg?: string;
          /**
           * @description  门牌号 长度 1～30
           */
          menPaiHao?: string;
          /**
           * @description  面积 值 5.00～99999.00 保留2位小数
           */
          mianJi?: number;
          /**
           * @description  室外图
           */
          outdoorImg?: string;
          /**
           * @description  配套服务 枚举 1：房屋维修，2：代收快递，3：专属客服，4：房屋清洁。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          peiTaoFuWu?: string;
          /**
           * @description  定价最小金额
           */
          pricingMinAmount?: number;
          /**
           * @description  室 值 1～15
           */
          shi?: number;
          /**
           * @description  所在楼层 值 -9～99
           */
          suoZaiLouCeng?: string;
          /**
           * @description  厅 值 0～15
           */
          ting?: number;
          /**
           * @description  卫 值 0～15
           */
          wei?: number;
          /**
           * @description  物业地址
           */
          wuYeDiZhi?: string;
          /**
           * @description  小区名称 长度 1～30
           */
          xiaoQu?: string;
          /**
           * @description  装修情况 枚举 1：毛坯，2：简单装修，4：精装修，6：豪华装修
           */
          zhuangXiuQingKuang?: string;
          /**
           * @description  总楼层 值 1～99
           */
          zongLouCeng?: string;
          indoorImg: Array<{
            category: number;
            description: number;
            imageUrl: string;
          }>;
        }
        /**
         * @name 房源推送58信息表新增-合租房源
         * @param data
         */
        export function house(data: HouseParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/shared/house",
            data
          );
        }
        export interface RoomParams {
          /**
           * @description  对应系统里面的roomId
           */
          bianHao: string;
          /**
           * @description  朝向 枚举 1：东，2：南，3：西，4：北，5：东南，6：东北，7：西南，8：西北，9：南北，10：东西
           */
          chaoXiang?: string;
          /**
           * @description  房间配套 枚举 1：冰箱，2：电视，3：洗衣机，4：热水器，5：空调，7：沙发，8：床（独），9：暖气，10：衣柜，11：可做饭，12：独立卫生间，13：独立阳台，15：微波炉，16：燃气灶，17：智能门锁，18：油烟机，19：电磁炉，20：餐桌，21：WIFI，22：橱柜，23：书桌。可多选，枚举值之间用连接，如：1,2,3
           */
          chuZuJianDuLiPeiTao?: string;
          /**
           * @description  出租时间 yyyy-mm-dd
           */
          chuZuShiJian?: string;
          /**
           * @description  出租要求 枚举 1：只限女生，2：中介勿扰，3：一家人，4：禁止养宠物，5：半年起租，6：一年起租，7：租户稳定，8：作息正常，9：禁烟，10：不可带小孩，12：只限男生。可多选，枚举值之间用,连接,如：1,2,3
           */
          chuZuYaoQiu?: string;
          /**
           * @description  公区Id，取上传房源返回的id
           */
          commonInfoId?: string;
          /**
           * @description  描述 长度 20～5000 不能连续8位数字及以上，不能特殊字符,能填写的符号有：,.，。！；;~&#|？%@、-\()（ ）
           */
          content?: string;
          /**
           * @description  封面图
           */
          coverImg?: string;
          /**
           * @description  短租 枚举 1：是，0：否
           */
          duanZu?: string;
          /**
           * @description  短租必填;短租租金 值 101～400000
           */
          duanZuJin?: number;
          /**
           * @description  短租必填;短租支付方式 枚举 1：押一付一，2：押二付一，21：月付免押金
           */
          duanZuZhiFuFangShi?: string;
          /**
           * @description  短租必填;最短租期 枚举 1：1个月，2：2个月
           */
          duanZuZuQi?: string;
          /**
           * @description  房间编号 值 1～30
           */
          fangJianBianHao?: string;
          /**
           * @description  房间亮点 枚举 3：独卫，4：独立阳台，12：首次出租。可多选，枚举值之间用","连接，如："3,4"
           */
          fangJianLiangDian?: string;
          /**
           * @description  房间情况 枚举 1：次卧，2：主卧
           */
          fangJianQingKuang?: string;
          /**
           * @description  服务费 枚举 1：收取，0：不收取
           */
          fuWuFei?: string;
          /**
           * @description  比例收取必填;服务费比例收费 值 0.1～100 保留小数点后一位
           */
          fuWuFeiBiLi?: number;
          /**
           * @description  固定收取时必填;服务费固定收费 值 1～40000 保留小数点后两位
           */
          fuWuFeiGuDingShouFei?: number;
          /**
           * @description  选择服务费时必填;服务费方式 枚举 1：固定金额，2：月租金比例
           */
          fuWuFeiJin?: string;
          /**
           * @description  选择服务费时必填;服务费 枚举 1：一次性收取，2：按月收取
           */
          fuWuFeiShouQuFangShi?: string;
          /**
           * @description  户型图
           */
          houseTypeImg?: string;
          /**
           * @description  室内图
           */
          indoorImg?: string;
          /**
           * @description  看房时间 枚举 1：仅周末，2：仅工作日，3：随时看房，4：工作日晚上和周末
           */
          kanFangShiJian?: string;
          /**
           * @description  面积 值 5.00～99999.00 保留2位小数
           */
          mianJi?: number;
          /**
           * @description  室内图
           */
          outdoorImg?: string;
          /**
           * @description  定价最小金额
           */
          pricingMinAmount?: number;
          /**
           * @description  标题 长度 10～30
           */
          title?: string;
          /**
           * @description  	支付方式文本 如："1:1000,2:2000",支付方式为1租金为1000，支付方式为2租金为2000，租金范围0～400000 支付方式 枚举 1：付1押1，2：付1押2，3：付2押1，4：付2押2，5：付3押1，6：付3押2，10：半年付押1，11：半年付不押，12：年付不押，13：年付押1，14：付1不押，15：付2不押，16：付3不押，17：半年付押2，18：年付押2
           */
          zhiFuFangShiWenBen?: string;
          /**
           * @description  中介费收取 枚举 1：收取，0：不收取
           */
          zhongJieFei?: string;
          /**
           * @description  固定收费时必填;固定收费的金额 值 1～40000 保留小数点后两位
           */
          zhongJieFeiGuDingShouFei?: number;
          /**
           * @description  中介费时必填;中介费金额收取方式 枚举 1：固定金额，2：半月租金，3：一月租金
           */
          zhongJieFeiJin?: string;
          /**
           * @description  房源状态 枚举 1：待租，2：已定，3：已租，4：不可推广
           */
          zhuangTai?: string;
          /**
           * @description  最长租期 枚举 1：1年，2：2年，3：3年，4：不限（未传默认1年）
           */
          zuiChangZuQi?: string;
        }
        /**
         * @name 房源推送58信息表新增-合租房间
         * @param data
         */
        export function room(data: RoomParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/shared/room",
            data
          );
        }
        export interface UpdateHouseParams {
          /**
           * @description  对应系统里面的houseId
           */
          bianHao: string;
          /**
           * @description  朝向 枚举 1：东，2：南，3：西，4：北，5：东南，6：东北，7：西南，8：西北，9：南北，10：东西
           */
          chaoXiang?: string;
          /**
           * @description  厨 值 0～15
           */
          chu?: number;
          chuZuHuShu?: number;
          /**
           * @description  封面图
           */
          coverImg?: string;
          /**
           * @description  单元 长度 1～30
           */
          danYuan?: string;
          /**
           * @description  枚举 1：栋，2：幢，3：号，4：号楼，5：单元
           */
          danyuanDanwei?: string;
          /**
           * @description  电梯 枚举 1：是，0：否
           */
          dianTi?: string;
          /**
           * @description  栋 长度 1～30
           */
          dong?: string;
          /**
           * @description  栋单位 枚举 1：栋，2：弄，3：座，4：号，5：号楼，6：胡同
           */
          dongDanwei?: string;
          /**
           * @description  房屋情况 枚举 1：普通住宅，2：别墅，3：商住楼，4：公寓，5：平房，6：其他，7：新里洋房，8：老公房，9：四合院，10：排屋
           */
          fangWuQingKuang?: string;
          /**
           * @description  包含费用 枚举 1：水费，2：电费，3：燃气费，4：宽带费，5：物业费，6：取暖费，7：有线电视费，8：停车费。可多选，枚举值之间用,连接，如：1,2,3
           */
          feiYongMingXi?: string;
          /**
           * @description  供暖 枚举 1：集中供暖，2：自供暖，3：不供暖
           */
          gongNuan?: string;
          /**
           * @description  公用配套 枚举 1：冰箱，2：电视，3：洗衣机，4：热水器，5：空调，6：宽带，7：沙发，8：床（独），9：暖气，10：衣柜，11：可做饭，12：独立卫生间，13：独立阳台，15：微波炉，16：燃气灶，17：智能门锁，18：油烟机，19：电磁炉，20：餐桌，21：WIFI，22：橱柜，23：书桌。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          gongYongPeiTao?: string;
          /**
           * @description  合租户数 值 1～15
           */
          heZuHuShu?: number;
          /**
           * @description  户型图
           */
          houseTypeImg?: string;
          /**
           * @description  门牌号 长度 1～30
           */
          menPaiHao?: string;
          /**
           * @description  面积 值 5.00～99999.00 保留2位小数
           */
          mianJi?: number;
          /**
           * @description  室外图
           */
          outdoorImg?: string;
          /**
           * @description  配套服务 枚举 1：房屋维修，2：代收快递，3：专属客服，4：房屋清洁。可多选，枚举值之间用,连接，如：'1,2,3'
           */
          peiTaoFuWu?: string;
          /**
           * @description  定价最小金额
           */
          pricingMinAmount?: number;
          /**
           * @description  室 值 1～15
           */
          shi?: number;
          /**
           * @description  所在楼层 值 -9～99
           */
          suoZaiLouCeng?: string;
          /**
           * @description  厅 值 0～15
           */
          ting?: number;
          /**
           * @description  卫 值 0～15
           */
          wei?: number;
          /**
           * @description  物业地址
           */
          wuYeDiZhi?: string;
          /**
           * @description  小区名称 长度 1～30
           */
          xiaoQu?: string;
          /**
           * @description  装修情况 枚举 1：毛坯，2：简单装修，4：精装修，6：豪华装修
           */
          zhuangXiuQingKuang?: string;
          /**
           * @description  总楼层 值 1～99
           */
          zongLouCeng?: string;
          indoorImg: Array<{
            category: number;
            description: number;
            imageUrl: string;
          }>;
        }
        /**
         * @name 房源推送58信息表更新-合租房源
         * @param data
         */
        export function updateHouse(data: UpdateHouseParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/shared/updateHouse",
            data
          );
        }
        export interface UpdateRoomParams {
          /**
           * @description  对应系统里面的roomId
           */
          bianHao: string;
          /**
           * @description  朝向 枚举 1：东，2：南，3：西，4：北，5：东南，6：东北，7：西南，8：西北，9：南北，10：东西
           */
          chaoXiang?: string;
          /**
           * @description  房间配套 枚举 1：冰箱，2：电视，3：洗衣机，4：热水器，5：空调，7：沙发，8：床（独），9：暖气，10：衣柜，11：可做饭，12：独立卫生间，13：独立阳台，15：微波炉，16：燃气灶，17：智能门锁，18：油烟机，19：电磁炉，20：餐桌，21：WIFI，22：橱柜，23：书桌。可多选，枚举值之间用连接，如：1,2,3
           */
          chuZuJianDuLiPeiTao?: string;
          /**
           * @description  出租时间 yyyy-mm-dd
           */
          chuZuShiJian?: string;
          /**
           * @description  出租要求 枚举 1：只限女生，2：中介勿扰，3：一家人，4：禁止养宠物，5：半年起租，6：一年起租，7：租户稳定，8：作息正常，9：禁烟，10：不可带小孩，12：只限男生。可多选，枚举值之间用,连接,如：1,2,3
           */
          chuZuYaoQiu?: string;
          /**
           * @description  公区Id，取上传房源返回的id
           */
          commonInfoId?: string;
          /**
           * @description  描述 长度 20～5000 不能连续8位数字及以上，不能特殊字符,能填写的符号有：,.，。！；;~&#|？%@、-\()（ ）
           */
          content?: string;
          /**
           * @description  封面图
           */
          coverImg?: string;
          /**
           * @description  短租 枚举 1：是，0：否
           */
          duanZu?: string;
          /**
           * @description  短租必填;短租租金 值 101～400000
           */
          duanZuJin?: number;
          /**
           * @description  短租必填;短租支付方式 枚举 1：押一付一，2：押二付一，21：月付免押金
           */
          duanZuZhiFuFangShi?: string;
          /**
           * @description  短租必填;最短租期 枚举 1：1个月，2：2个月
           */
          duanZuZuQi?: string;
          /**
           * @description  房间编号 值 1～30
           */
          fangJianBianHao?: string;
          /**
           * @description  房间亮点 枚举 3：独卫，4：独立阳台，12：首次出租。可多选，枚举值之间用","连接，如："3,4"
           */
          fangJianLiangDian?: string;
          /**
           * @description  房间情况 枚举 1：次卧，2：主卧
           */
          fangJianQingKuang?: string;
          /**
           * @description  服务费 枚举 1：收取，0：不收取
           */
          fuWuFei?: string;
          /**
           * @description  比例收取必填;服务费比例收费 值 0.1～100 保留小数点后一位
           */
          fuWuFeiBiLi?: number;
          /**
           * @description  固定收取时必填;服务费固定收费 值 1～40000 保留小数点后两位
           */
          fuWuFeiGuDingShouFei?: number;
          /**
           * @description  选择服务费时必填;服务费方式 枚举 1：固定金额，2：月租金比例
           */
          fuWuFeiJin?: string;
          /**
           * @description  选择服务费时必填;服务费 枚举 1：一次性收取，2：按月收取
           */
          fuWuFeiShouQuFangShi?: string;
          /**
           * @description  户型图
           */
          houseTypeImg?: string;
          /**
           * @description  室内图
           */
          indoorImg?: string;
          /**
           * @description  看房时间 枚举 1：仅周末，2：仅工作日，3：随时看房，4：工作日晚上和周末
           */
          kanFangShiJian?: string;
          /**
           * @description  面积 值 5.00～99999.00 保留2位小数
           */
          mianJi?: number;
          /**
           * @description  室内图
           */
          outdoorImg?: string;
          /**
           * @description  定价最小金额
           */
          pricingMinAmount?: number;
          /**
           * @description  标题 长度 10～30
           */
          title?: string;
          /**
           * @description  	支付方式文本 如："1:1000,2:2000",支付方式为1租金为1000，支付方式为2租金为2000，租金范围0～400000 支付方式 枚举 1：付1押1，2：付1押2，3：付2押1，4：付2押2，5：付3押1，6：付3押2，10：半年付押1，11：半年付不押，12：年付不押，13：年付押1，14：付1不押，15：付2不押，16：付3不押，17：半年付押2，18：年付押2
           */
          zhiFuFangShiWenBen?: string;
          /**
           * @description  中介费收取 枚举 1：收取，0：不收取
           */
          zhongJieFei?: string;
          /**
           * @description  固定收费时必填;固定收费的金额 值 1～40000 保留小数点后两位
           */
          zhongJieFeiGuDingShouFei?: number;
          /**
           * @description  中介费时必填;中介费金额收取方式 枚举 1：固定金额，2：半月租金，3：一月租金
           */
          zhongJieFeiJin?: string;
          /**
           * @description  最长租期 枚举 1：1年，2：2年，3：3年，4：不限（未传默认1年）
           */
          zuiChangZuQi?: string;
        }
        /**
         * @name 房源推送58信息表更新-合租房间
         * @param data
         */
        export function updateRoom(data: UpdateRoomParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/roomPush/shared/updateRoom",
            data
          );
        }
      }
    }
    export namespace houseLog {
      /**
       * @name 房源操作日志表详情
       * @param id 房源操作日志表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseLog>>(
          `/other-v100001/houseLog/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  房源id
         */
        houseId?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 房源操作日志表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<HouseLog>>>(
          "/other-v100001/houseLog/list",
          { params }
        );
      }
    }
    export namespace houseTenants {
      export interface CopyDownloadUrlParams {
        /**
         * @description  3租客 4房东
         */
        type?: number;
      }
      /**
       * @name 房东，租客下载电子合同
       * @param params
       */
      export function copyDownloadUrl(params: CopyDownloadUrlParams) {
        return request.get<ResponseResult<object>>(
          "/other-v100001/houseTenants/copyDownloadUrl",
          { params }
        );
      }
      export interface DebtListParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  欠款时间结束
         */
        dateEnd?: string;
        /**
         * @description  日期筛选月 格式:2020-01 与时间段互斥
         */
        dateMonth?: string;
        /**
         * @description  欠款时间开始
         */
        dateStart?: string;
        /**
         * @description  日期筛选类型 1-欠款时间 2-还款期限
         */
        dateType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源ID 如不为空则查询房东欠款
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-他人欠公司 2-公司欠他人
         */
        inoutType?: number;
        /**
         * @description  是否挂载：1已挂载 2未挂载
         */
        isMount?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  还款期限结束
         */
        repayPeriodEnd?: string;
        /**
         * @description  还款期限开始
         */
        repayPeriodStart?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID 如不为空则查询租客欠款
         */
        tenantsId?: number;
      }
      /**
       * @name 房东与租客欠款列表
       * @param params
       */
      export function debtList(params: DebtListParams) {
        return request.get<ResponseResult<PageList<Debt>>>(
          "/other-v100001/houseTenants/debtList",
          { params }
        );
      }
      /**
       * @name 租客看宽带信息
       */
      export function getBroadBand() {
        return request.get<ResponseResult<Broadband>>(
          "/other-v100001/houseTenants/getBroadBand"
        );
      }
      export interface GetContractUrlParams {
        /**
         * @description  3租客 4房东
         */
        type?: number;
      }
      /**
       * @name 获取合同访问路径
       * @param data
       */
      export function getContractUrl(data: GetContractUrlParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseTenants/getContractUrl",
          data
        );
      }
      /**
       * @name 根据id 判断显示
       */
      export function getHouseOtherInfo() {
        return request.get<ResponseResult<HouseVo>>(
          "/other-v100001/houseTenants/getHouseOtherInfo"
        );
      }
      export interface GetIncomeExpenseDetailParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  计费方式(1-均费 2-自定义)
         */
        billType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  款项原因ID
         */
        feeReasonId?: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId?: number;
        /**
         * @description  房源ID 与收支计划ID和租客ID互斥
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收支类型(1-收入 2-支出)
         */
        inoutType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  支付时间
         */
        payDate?: string;
        /**
         * @description  缴费日期筛选结束时间
         */
        payDateEnd?: string;
        /**
         * @description  支付时间统计月 格式:2020-01
         */
        payDateMonth?: string;
        /**
         * @description  缴费日期筛选开始时间
         */
        payDateStart?: string;
        /**
         * @description  付款方式ID
         */
        payMethodId?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  收支计划ID 与房源ID和租客ID互斥
         */
        planId?: number;
        /**
         * @description  计划类型(1-房屋 2-其他)
         */
        planType?: number;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID 与收支计划ID和房源ID互斥
         */
        tenantsId?: number;
      }
      /**
       * @name 租客：其它缴费
       * @param params
       */
      export function getIncomeExpenseDetail(
        params: GetIncomeExpenseDetailParams
      ) {
        return request.get<ResponseResult<PageList<IncomeExpenseDetail>>>(
          "/other-v100001/houseTenants/getIncomeExpenseDetail",
          { params }
        );
      }
      /**
       * @name 房东：合同信息
       */
      export function getInfo() {
        return request.get<ResponseResult<HouseVo>>(
          "/other-v100001/houseTenants/getInfo"
        );
      }
      /**
       * @name 获取手续费比例
       */
      export function getServiceChargeRate() {
        return request.get<ResponseResult<object>>(
          "/other-v100001/houseTenants/getServiceChargeRate"
        );
      }
      /**
       * @name 租客：我的租约
       */
      export function getTenantsInfo() {
        return request.get<ResponseResult<TenantsVo>>(
          "/other-v100001/houseTenants/getTenantsInfo"
        );
      }
      /**
       * @name 我的管家
       */
      export function mySteward() {
        return request.get<ResponseResult<BookUserVo>>(
          "/other-v100001/houseTenants/mySteward"
        );
      }
      /**
       * @name putDong
       */
      export function putDong() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/houseTenants/putDong"
        );
      }
      export interface RentListParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  业务人员ID
         */
        businessId?: number;
        /**
         * @description  联系人
         */
        contactName?: string;
        /**
         * @description  联系电话
         */
        contactPhone?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  统计月份 格式:2020-02
         */
        dateMonth?: string;
        /**
         * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
         */
        dateType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源ID(续约ID-租客ID) 选其一
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否续约 0-否 1-是
         */
        isRenewal?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费日期(结束)
         */
        payDateEnd?: string;
        /**
         * @description  缴费日期(开始)
         */
        payDateStart?: string;
        /**
         * @description  0-押金 非0-表示房租
         */
        payNum?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  付款类型ID
         */
        payTypeId?: number;
        /**
         * @description  租金类型 房东=应支 租客=应收
         */
        relationTypeId?: number;
        /**
         * @description  续约ID(房源ID-租客ID) 选其一
         */
        renewalId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家ID
         */
        stewardId?: number;
        /**
         * @description  第二管家ID
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID(续约ID-房源ID) 选其一
         */
        tenantsId?: number;
      }
      /**
       * @name 我的房租
       * @param params
       */
      export function rentList(params: RentListParams) {
        return request.get<ResponseResult<PageList<HouseRent>>>(
          "/other-v100001/houseTenants/rentList",
          { params }
        );
      }
      /**
       * @name 房东端：查询租客列表
       */
      export function tenantsList() {
        return request.get<ResponseResult<Array<RoomTenants>>>(
          "/other-v100001/houseTenants/tenantsList"
        );
      }
    }
    export namespace materialType {
      /**
       * @name 材料类型总表删除
       * @param id 材料类型总表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/materialType/delete/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  分类id:pid:685
         */
        classifyId?: number;
        /**
         * @description  物品名称
         */
        name?: string;
      }
      /**
       * @name 材料类型总表列表页
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<Array<OtherMaterialType>>>(
          "/other-v100001/materialType/list",
          { params }
        );
      }
      export interface PageParams {
        /**
         * @description  分类id:pid:685
         */
        classifyId?: number;
        /**
         * @description  物品名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 材料类型总表列表页-分页查询
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<OtherMaterialType>>>(
          "/other-v100001/materialType/page",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  分类id:pid:685
         */
        classifyId: number;
        /**
         * @description  材料提成
         */
        commission: number;
        /**
         * @description  成本价
         */
        costAmount: number;
        /**
         * @description  型号
         */
        model: string;
        /**
         * @description  物品名称
         */
        name: string;
        /**
         * @description  销售价
         */
        price: number;
      }
      /**
       * @name 材料类型总表添加
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/materialType/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  分类id:pid:685
         */
        classifyId: number;
        /**
         * @description  材料提成
         */
        commission: number;
        /**
         * @description  成本价
         */
        costAmount: number;
        /**
         * @description  型号
         */
        model: string;
        /**
         * @description  物品名称
         */
        name: string;
        /**
         * @description  销售价
         */
        price: number;
      }
      /**
       * @name 材料类型总表修改
       * @param id 材料类型总表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/materialType/update/${id}`,
          data
        );
      }
    }
    export namespace notice {
      /**
       * @name 公告管理删除
       * @param id 公告管理删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/notice/delete/${id}`
        );
      }
      /**
       * @name 公告管理详情
       * @param id 公告管理详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Notice>>(
          `/other-v100001/notice/info/${id}`
        );
      }
      export interface ListParams {
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  标题
         */
        title?: string;
        /**
         * @description  1表示微信租客   2表示房东   3表示公司公告
         */
        type?: number;
      }
      /**
       * @name 管理后台：公告管理列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Notice>>>(
          "/other-v100001/notice/list",
          { params }
        );
      }
      export interface NewNoticeParams {
        /**
         * @description  1 租客 2房东  3公司
         */
        type?: number;
      }
      /**
       * @name 最新公告详情
       * @param params
       */
      export function newNotice(params: NewNoticeParams) {
        return request.get<ResponseResult<Array<Notice>>>(
          "/other-v100001/notice/newNotice",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  内容
         */
        content?: string;
        /**
         * @description  标题
         */
        title?: string;
        /**
         * @description  1表示微信租客   2表示房东   3表示公司公告
         */
        type: number;
      }
      /**
       * @name 公告管理新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/notice/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  内容
         */
        content?: string;
        /**
         * @description  标题
         */
        title?: string;
        /**
         * @description  1表示微信租客   2表示房东   3表示公司公告
         */
        type: number;
      }
      /**
       * @name 公告管理修改
       * @param id 公告管理修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/notice/update/${id}`,
          data
        );
      }
    }
    export namespace onlineHouse {
      /**
       * @name 在线找房删除
       * @param id 在线找房删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/onlineHouse/delete/${id}`
        );
      }
      /**
       * @name 在线找房详情
       * @param id 在线找房详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<OnlineHouse>>(
          `/other-v100001/onlineHouse/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区id
         */
        areaId?: number;
        /**
         * @description  客户id
         */
        companyId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  物业地区名称
         */
        detailName?: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  是否可以短租 1可以 2不行
         */
        isShortRent?: number;
        /**
         * @description  是否特价房 1否 2是
         */
        isSpecialOffer?: number;
        /**
         * @description  当前人所在经度
         */
        lat1?: string;
        /**
         * @description  当前人所在纬度
         */
        lng1?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  出租金额最小值  前端定义 0-1000   1000-2000   2000 - 3000 3000-5000 5000-10000
         */
        pricingMinAmountRange?: string;
        /**
         * @description  几室
         */
        room?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 在线找房列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<OnlineHouse>>>(
          "/other-v100001/onlineHouse/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  特色勾选 字典367（逗号隔开）
         */
        feature: string;
        /**
         * @description  房屋配置
         */
        houseConfig?: string;
        /**
         * @description  是否可以短租 1可以 2不行 ,默认不能短租
         */
        isShortRent: number;
        /**
         * @description  是否特价房 1否 2是
         */
        isSpecialOffer: number;
        /**
         * @description  出租金额
         */
        pricingMinAmount: number;
        /**
         * @description  联系人id
         */
        pubId: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId: number;
        /**
         * @description  特价房金额
         */
        specialOfferAmount?: number;
        /**
         * @description  状态 1上线 2下线
         */
        status: number;
      }
      /**
       * @name 在线找房新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/onlineHouse/save",
          data
        );
      }
    }
    export namespace opinion {
      export interface AuditParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  replyDesc
         */
        replyDesc?: string;
      }
      /**
       * @name 回复
       * @param data
       */
      export function audit(data: AuditParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/opinion/audit",
          data
        );
      }
      /**
       * @name 微信反馈删除
       * @param id 微信反馈删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/opinion/delete/${id}`
        );
      }
      export interface HouseOpinionParams {
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  客户名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1提示交租 2续约 3解约
         */
        type?: number;
      }
      /**
       * @name 传类型 显示不同的列表字段
       * @param params
       */
      export function houseOpinion(params: HouseOpinionParams) {
        return request.get<ResponseResult<PageList<Opinion>>>(
          "/other-v100001/opinion/houseOpinion",
          { params }
        );
      }
      /**
       * @name 微信反馈详情
       * @param id 微信反馈详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Opinion>>(
          `/other-v100001/opinion/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  客户名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  1提示交租 2续约 3解约
         */
        type?: number;
      }
      /**
       * @name 微信反馈列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Opinion>>>(
          "/other-v100001/opinion/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  解约时间
         */
        applyTime?: string;
        /**
         * @description  续约租金
         */
        contractRent?: number;
        /**
         * @description  续约年限
         */
        contractYear?: number;
        /**
         * @description  反馈说明
         */
        remark?: string;
        /**
         * @description  解约类型 1到期解约  2违约解约
         */
        terminationType?: number;
        /**
         * @description  1提示交租 2续约 3解约
         */
        type: number;
      }
      /**
       * @name 微信反馈新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/opinion/save",
          data
        );
      }
    }
    export namespace otherBuyCarApply {
      /**
       * @name 获取详情接口
       * @param id 获取详情接口
       */
      export function getInfo(id: string | number) {
        return request.get<ResponseResult<OtherBuyCarApplyVo>>(
          `/other-v100001/otherBuyCarApply/getInfo/${id}`
        );
      }
      export interface PageParams {
        /**
         * @description  申请名称
         */
        approvalName?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 获取列表页
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<OtherBuyCarApplyVo>>>(
          "/other-v100001/otherBuyCarApply/page",
          { params }
        );
      }
      /**
       * @name 删除
       * @param id 删除
       */
      export function remove(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/otherBuyCarApply/remove/${id}`
        );
      }
      export interface SaveParams {
        /**
         * @description  说明
         */
        approvalExplain?: string;
        /**
         * @description  申请名称
         */
        approvalName?: string;
        /**
         * @description  审批编号
         */
        approvalNum?: string;
        /**
         * @description  金额
         */
        carAmount?: number;
        /**
         * @description  购买的车辆照片
         */
        carImgs?: string;
        /**
         * @description  购买类型
         */
        carType?: number;
        /**
         * @description  公司承担金额
         */
        companyAmount?: number;
        /**
         * @description  公司id
         */
        companyId?: number;
        /**
         * @description  公司名称
         */
        companyName?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  车架号
         */
        frameNo?: string;
        /**
         * @description  手写签名
         */
        handwrittenSignature?: string;
        /**
         * @description  支付账号开户名
         */
        payAccountName?: string;
        /**
         * @description  支付账户账号
         */
        payAccountNumber?: string;
        /**
         * @description  支付账户行别及支行
         */
        payCategory?: string;
        /**
         * @description  支付方式
         */
        payType?: number;
        /**
         * @description  个人承担金额
         */
        personalAmount?: number;
        /**
         * @description  流程类型id
         */
        processTypeId?: number;
        /**
         * @description  创建人所属部门id
         */
        storeId?: number;
        /**
         * @description  创建人所属部门name
         */
        storeName?: string;
      }
      /**
       * @name 添加接口
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/otherBuyCarApply/save",
          data
        );
      }
    }
    export namespace otherPayment {
      /**
       * @name 获取详情
       * @param id 获取详情
       */
      export function getInfo(id: string | number) {
        return request.get<ResponseResult<OtherPaymentApplyVo>>(
          `/other-v100001/otherPayment/getInfo/${id}`
        );
      }
      export interface PageParams {
        /**
         * @description  申请名称
         */
        approvalName?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 查询列表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<OtherPaymentApplyVo>>>(
          "/other-v100001/otherPayment/page",
          { params }
        );
      }
      /**
       * @name 删除
       * @param id 删除
       */
      export function remove(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/otherPayment/remove/${id}`
        );
      }
      export interface SaveOtherParams {
        /**
         * @description  附件
         */
        accessory?: string;
        /**
         * @description  说明
         */
        approvalExplain?: string;
        /**
         * @description  申请名称
         */
        approvalName?: string;
        /**
         * @description  审批编号
         */
        approvalNum?: string;
        /**
         * @description  申请项目
         */
        approvalProject?: string;
        /**
         * @description  公司id
         */
        companyId?: number;
        /**
         * @description  公司名称
         */
        companyName?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  手写签名
         */
        handwrittenSignature?: string;
        /**
         * @description  支付账户名
         */
        payAccountName?: string;
        /**
         * @description  支付账号
         */
        payAccountNumber?: string;
        /**
         * @description  支付金额
         */
        payAmount?: number;
        /**
         * @description  支付账户行别及支行
         */
        payCategory?: string;
        /**
         * @description  支付日期
         */
        payTime?: string;
        /**
         * @description  费用承担方
         */
        payer?: string;
        /**
         * @description  支付费用明细
         */
        paymentDetail?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  创建人所属部门id
         */
        storeId?: number;
        /**
         * @description  创建人所属部门name
         */
        storeName?: string;
      }
      /**
       * @name 添加
       * @param data
       */
      export function saveOther(data: SaveOtherParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/otherPayment/saveOther",
          data
        );
      }
    }
    export namespace otherReimburse {
      /**
       * @name 获取详情
       * @param id 获取详情
       */
      export function getInfo(id: string | number) {
        return request.get<ResponseResult<OtherReimburseVo>>(
          `/other-v100001/otherReimburse/getInfo/${id}`
        );
      }
      export interface PageParams {
        /**
         * @description  申请名称
         */
        approvalName?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  创建时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        startTimeStart?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 查询列表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<OtherReimburseVo>>>(
          "/other-v100001/otherReimburse/page",
          { params }
        );
      }
      /**
       * @name 删除
       * @param id 删除
       */
      export function remove(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/otherReimburse/remove/${id}`
        );
      }
      export interface SaveParams {
        /**
         * @description  附件：费用明细
         */
        accessory?: string;
        /**
         * @description  总报销金额
         */
        aggregateAmount?: number;
        /**
         * @description  申请名称
         */
        approvalName?: string;
        /**
         * @description  审批编号
         */
        approvalNum?: string;
        /**
         * @description  公司承担金额
         */
        companyAmount?: number;
        /**
         * @description  公司id
         */
        companyId?: number;
        /**
         * @description  公司名称
         */
        companyName?: string;
        /**
         * @description  日期
         */
        createDay?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  手写签名
         */
        handwrittenSignature?: string;
        /**
         * @description  流程类型id
         */
        processTypeId?: number;
        /**
         * @description  业主承担金额
         */
        proprietorAmount?: number;
        /**
         * @description  发票/收据/付款凭证
         */
        receiptUrl?: string;
        /**
         * @description  报销摘要
         */
        reimburseAbstract?: string;
        /**
         * @description  报销金额
         */
        reimburseAmount?: number;
        /**
         * @description  报销所属门店id
         */
        reimburseDeptId?: number;
        /**
         * @description  说明
         */
        reimburseExplain?: string;
        /**
         * @description  报销项目
         */
        reimburseProject?: string;
        /**
         * @description  报销所属门店id
         */
        reimburseStoreId?: number;
        /**
         * @description  报销类别
         */
        reimburseType?: number;
        /**
         * @description  报销备注
         */
        remark?: string;
        /**
         * @description  报销的房间id
         */
        roomId?: number;
        /**
         * @description  创建人所属部门id
         */
        storeId?: number;
        /**
         * @description  创建人所属部门name
         */
        storeName?: string;
        /**
         * @description  租客承担金额
         */
        tenantAmount?: number;
        /**
         * @description  是否租客承担（0，是。1，不是）
         */
        tenantType?: number;
        /**
         * @description  凭证类型
         */
        voucherType?: number;
      }
      /**
       * @name 添加
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/otherReimburse/save",
          data
        );
      }
    }
    export namespace outDoor {
      export interface BackParams {
        /**
         * @description  返回描述
         */
        backDesc?: string;
        /**
         * @description  返回图片
         */
        backDetail?: string;
        /**
         * @description  主键
         */
        id: number;
      }
      /**
       * @name 手机客户使用：外出打卡返回
       * @param data
       */
      export function back(data: BackParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/outDoor/back",
          data
        );
      }
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        ids?: number;
      }
      /**
       * @name 外出打卡表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/other-v100001/outDoor/delete",
          { params }
        );
      }
      /**
       * @name 外出打卡表详情
       * @param id 外出打卡表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<OutDoor>>(
          `/other-v100001/outDoor/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  返回时间结束
         */
        backTimeEnd?: string;
        /**
         * @description  返回时间开始
         */
        backTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始(默认当前月1号 0点)
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地址id
         */
        detailId: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  是否查询自己的外出  1自己  2所有
         */
        isMy?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1出门中  2签到成功  3已返回
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  返回总共用时小时数 下拉选择（1-24）
         */
        sumTimeHour?: number;
        /**
         * @description  用户id
         */
        userId?: number;
      }
      /**
       * @name 外出打卡表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<OutDoor>>>(
          "/other-v100001/outDoor/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  物业地址id
         */
        detailId?: string;
        /**
         * @description  物业地址name
         */
        detailName?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  目的纬度
         */
        lat?: string;
        /**
         * @description  目的经度
         */
        lng?: string;
        /**
         * @description  备注
         */
        remarks?: string;
      }
      /**
       * @name 外出打卡表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/outDoor/save",
          data
        );
      }
      export interface SignInParams {
        /**
         * @description  主键
         */
        id: number;
        /**
         * @description  签到图片
         */
        signInDetail?: string;
        /**
         * @description  到达签到纬度
         */
        signInLat?: string;
        /**
         * @description  到达签到经度
         */
        signInLng?: string;
      }
      /**
       * @name 手机客户使用：外出打卡签到
       * @param data
       */
      export function signIn(data: SignInParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/outDoor/signIn",
          data
        );
      }
    }
    export namespace outPhoneBook {
      /**
       * @name 外部通讯录删除
       * @param id 外部通讯录删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/outPhoneBook/delete/${id}`
        );
      }
      /**
       * @name 外部通讯录详情
       * @param id 外部通讯录详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<OutPhoneBook>>(
          `/other-v100001/outPhoneBook/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  手机号
         */
        phone?: number;
        /**
         * @description  电话2
         */
        phone1?: string;
        /**
         * @description  备注
         */
        remark?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id(创建人)
         */
        storeId?: number;
        /**
         * @description  分类:pid:680
         */
        typeId?: number;
      }
      /**
       * @name 外部通讯录列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<OutPhoneBook>>>(
          "/other-v100001/outPhoneBook/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  地址
         */
        addr?: string;
        /**
         * @description  名称
         */
        name: string;
        /**
         * @description  手机号
         */
        phone: number;
        /**
         * @description  电话2
         */
        phone1?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  服务区域门店id(多选门店)
         */
        serviceStoreIds?: string;
        /**
         * @description  分类:pid:680
         */
        typeId: number;
      }
      /**
       * @name 外部通讯录新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/outPhoneBook/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  地址
         */
        addr?: string;
        /**
         * @description  名称
         */
        name: string;
        /**
         * @description  手机号
         */
        phone: number;
        /**
         * @description  电话2
         */
        phone1?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  服务区域门店id(多选门店)
         */
        serviceStoreIds?: string;
        /**
         * @description  分类:pid:680
         */
        typeId: number;
      }
      /**
       * @name 外部通讯录修改
       * @param id 外部通讯录修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/outPhoneBook/update/${id}`,
          data
        );
      }
    }
    export namespace payRent {
      export interface ExportExcelParams {
        /**
         * @description  支付时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  支付时间开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  商家订单号
         */
        orderNo?: string;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        /**
         * @description  1成功 2失败 3处理中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  (1, 交房租 ),(2,  交欠款 ),(3,  交房租与欠款 ),(4,  交房租与欠款,抵扣定金 ),(5,  交房租,抵扣定金 ),(6,  预存能源费 ),(7,  交其它费 ),(8,  租客交定金 ),(9,  交押金 ),
         */
        titleType?: number;
      }
      /**
       * @name 微信收款列表 导出
       * @param params
       */
      export function exportExcel(params: ExportExcelParams) {
        return request.get("/other-v100001/payRent/exportExcel", { params });
      }
      /**
       * @name 微信收款详情
       * @param id 微信收款详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<PayRent>>(
          `/other-v100001/payRent/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  支付时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  支付时间开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  商家订单号
         */
        orderNo?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1成功 2失败 3处理中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  (1, 交房租 ),(2,  交欠款 ),(3,  交房租与欠款 ),(4,  交房租与欠款,抵扣定金 ),(5,  交房租,抵扣定金 ),(6,  预存能源费 ),(7,  交其它费 ),(8,  租客交定金 ),(9,  交押金 ),
         */
        titleType?: number;
      }
      /**
       * @name 微信收款列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<PayRent>>>(
          "/other-v100001/payRent/list",
          { params }
        );
      }
      export interface PayRentTotalParams {
        /**
         * @description  支付时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  支付时间开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  商家订单号
         */
        orderNo?: string;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        /**
         * @description  1成功 2失败 3处理中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  (1, 交房租 ),(2,  交欠款 ),(3,  交房租与欠款 ),(4,  交房租与欠款,抵扣定金 ),(5,  交房租,抵扣定金 ),(6,  预存能源费 ),(7,  交其它费 ),(8,  租客交定金 ),(9,  交押金 ),
         */
        titleType?: number;
      }
      /**
       * @name 支付成功收入总金额
       * @param params
       */
      export function payRentTotal(params: PayRentTotalParams) {
        return request.get<ResponseResult<PayRentTotalDto>>(
          "/other-v100001/payRent/payRentTotal",
          { params }
        );
      }
      export interface TenantsListParams {
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 租客:交易流水列表
       * @param params
       */
      export function tenantsList(params: TenantsListParams) {
        return request.get<ResponseResult<PageList<PayRent>>>(
          "/other-v100001/payRent/tenantsList",
          { params }
        );
      }
      export interface TenantsPayDetailListParams {
        /**
         * @description  交流流水详情主键
         */
        payId?: number;
      }
      /**
       * @name 租客:交易流水详情子信息
       * @param params
       */
      export function tenantsPayDetailList(params: TenantsPayDetailListParams) {
        return request.get<ResponseResult<Array<PayDetail>>>(
          "/other-v100001/payRent/tenantsPayDetailList",
          { params }
        );
      }
      /**
       * @name 导入建行账单数据更新手续费
       */
      export function updatePayRentServiceChargeAmount(data: any) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/payRent/updatePayRentServiceChargeAmount",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
    }
    export namespace preLookHouse {
      /**
       * @name 微信预约看房删除
       * @param id 微信预约看房删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/preLookHouse/delete/${id}`
        );
      }
      export interface ExportExcelParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  客户名称
         */
        name?: string;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  预约说明
         */
        remark?: string;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 微信预约看房 导出
       * @param params
       */
      export function exportExcel(params: ExportExcelParams) {
        return request.get("/other-v100001/preLookHouse/exportExcel", {
          params
        });
      }
      /**
       * @name 微信预约看房详情
       * @param id 微信预约看房详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<PreLookHouse>>(
          `/other-v100001/preLookHouse/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  客户名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  预约说明
         */
        remark?: string;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 微信预约看房列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<PreLookHouse>>>(
          "/other-v100001/preLookHouse/list",
          { params }
        );
      }
    }
    export namespace remark {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 备注表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/other-v100001/remark/deleteById",
          { params }
        );
      }
      /**
       * @name 备注表详情
       * @param id 备注表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Remark>>(
          `/other-v100001/remark/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源表id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  其它业务表id
         */
        otherId?: number;
        /**
         * @description  其它业务表类型 101表示应收
         */
        otherType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  物品来源id
         */
        sourceId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 备注表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Remark>>>(
          "/other-v100001/remark/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  房源表id
         */
        houseId?: number;
        /**
         * @description  其它业务表id
         */
        otherId?: number;
        /**
         * @description  其它业务表类型 336表示应收  337表示应支
         */
        otherType: number;
        /**
         * @description  备注
         */
        remarks?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId?: number;
      }
      /**
       * @name 备注表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/remark/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  房源表id
         */
        houseId?: number;
        /**
         * @description  其它业务表id
         */
        otherId?: number;
        /**
         * @description  其它业务表类型 336表示应收  337表示应支
         */
        otherType: number;
        /**
         * @description  备注
         */
        remarks?: string;
        /**
         * @description  房间id(集中传房间id)
         */
        roomId?: number;
      }
      /**
       * @name 备注表修改
       * @param id 备注表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/remark/update/${id}`,
          data
        );
      }
    }
    export namespace out {
      export namespace repairAddress {
        /**
         * @name 清空所有的地址列表
         */
        export function deleteAll() {
          return request.delete<ResponseResult<object>>(
            "/other-v100001/out/repairAddress/deleteAll"
          );
        }
        export interface DeleteRepairAddressParams {
          /**
           * @description  id
           */
          id: number;
        }
        /**
         * @name 根据id删除单条记录
         * @param params
         */
        export function deleteRepairAddress(params: DeleteRepairAddressParams) {
          return request.delete<ResponseResult<object>>(
            "/other-v100001/out/repairAddress/deleteRepairAddress",
            { params }
          );
        }
        export interface GetRepairAddressByIdParams {
          /**
           * @description  id
           */
          id: number;
        }
        /**
         * @name 根据id获取单条记录
         * @param params
         */
        export function getRepairAddressById(
          params: GetRepairAddressByIdParams
        ) {
          return request.get<ResponseResult<object>>(
            "/other-v100001/out/repairAddress/getRepairAddressById",
            { params }
          );
        }
        /**
         * @name 通过用户id查询他自己的地址列表
         */
        export function getRepairAddressList() {
          return request.get<ResponseResult<object>>(
            "/other-v100001/out/repairAddress/getRepairAddressList"
          );
        }
        export interface SaveRepairAddressParams {
          /**
           * @description  区域名称id
           */
          areaId?: number;
          /**
           * @description  区域名称
           */
          areaName?: string;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  物业地区id
           */
          detailId?: string;
          /**
           * @description  物业地区名称
           */
          detailName?: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door?: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
        }
        /**
         * @name 添加外部维修保洁地址管理
         * @param data
         */
        export function saveRepairAddress(data: SaveRepairAddressParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repairAddress/saveRepairAddress",
            data
          );
        }
        export interface UpdateRepairAddressParams {
          /**
           * @description  区域名称id
           */
          areaId?: number;
          /**
           * @description  区域名称
           */
          areaName?: string;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  物业地区id
           */
          detailId?: string;
          /**
           * @description  物业地区名称
           */
          detailName?: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door?: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  id
           */
          id: number;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
        }
        /**
         * @name 根据id修改单条记录
         * @param data
         */
        export function updateRepairAddress(data: UpdateRepairAddressParams) {
          return request.put<ResponseResult<object>>(
            "/other-v100001/out/repairAddress/updateRepairAddress",
            data
          );
        }
      }
      export namespace repair {
        export interface CancelParams {
          /**
           * @description  id
           */
          id?: number;
        }
        /**
         * @name 未接单之前用户可以取消
         * @param data
         */
        export function cancel(data: CancelParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/cancel",
            data
          );
        }
        export interface MyUserListParams {
          /**
           * @description  创建人电话
           */
          createPhone?: number;
          /**
           * @description  创建时间结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建时间开始
           */
          createTimeStart?: string;
          /**
           * @description  处理人id
           */
          dealId?: number;
          /**
           * @description  物业地区id
           */
          detailId?: string;
          /**
           * @description  完成时间结束
           */
          finishTimeEnd?: string;
          /**
           * @description  完成时间开始
           */
          finishTimeStart?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  是否删除（1-删除；0-未删除）
           */
          isDelete?: number;
          /**
           * @description  1接单，2派单，3未接单，4拒绝
           */
          isOrder?: number;
          /**
           * @description  撤销状态：0为未撤销，1为已撤销
           */
          isRevoke?: number;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  支付状态：1未付，3已付
           */
          payStatus?: number;
          /**
           * @description  支付时间
           */
          payTimeEnd?: string;
          /**
           * @description  支付时间
           */
          payTimeStart?: string;
          /**
           * @description  报表查询时间
           */
          reportTime?: number;
          /**
           * @description  派单人id
           */
          sendOrderId?: number;
          /**
           * @description  派单/接单时间结束
           */
          sendOrderTimeEnd?: string;
          /**
           * @description  派单/接单时间开始
           */
          sendOrderTimeStart?: string;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  状态：1未处理 2已处理 3开始处理 4暂停 5维修中 6其它
           */
          status?: number;
          /**
           * @description  客户名称
           */
          tenantsName?: string;
          /**
           * @description  客户电话
           */
          tenantsPhone?: string;
          /**
           * @description  维修上门时间结束
           */
          theDoorTime?: string;
          /**
           * @description  维修上门时间开始
           */
          theDoorTimeStart?: string;
          /**
           * @description  1表示维修 2保洁
           */
          typeId?: number;
          /**
           * @description  回访结果：pid:689
           */
          visitDealStatusId?: number;
          /**
           * @description  是否私下收费：1是，2否
           */
          visitIsCharge?: number;
          /**
           * @description  是否按时间完：1是，2否
           */
          visitOnTime?: number;
          /**
           * @description  服务星评：1特别差,2差，3一般，4好，5非常好
           */
          visitServiceId?: number;
          /**
           * @description  回访状态：1未回访 2已回访
           */
          visitStatus?: number;
          /**
           * @description  回访时间
           */
          visitTimeEnd?: string;
          /**
           * @description  回访时间
           */
          visitTimeStart?: string;
          /**
           * @description  回访人
           */
          visitUserId?: number;
        }
        /**
         * @name 小程序用户端：我的维修列表
         * @param params
         */
        export function myUserList(params: MyUserListParams) {
          return request.get<ResponseResult<PageList<Repair>>>(
            "/other-v100001/out/repair/myUserList",
            { params }
          );
        }
        export interface VisitParams {
          /**
           * @description  主键
           */
          id: number;
          /**
           * @description  回访结果：pid:689 默认已处理
           */
          visitDealStatusId: number;
          /**
           * @description  是否私下收费：1是，2否 默认否
           */
          visitIsCharge: number;
          /**
           * @description  是否按时间完成：1是，2否  默认是
           */
          visitOnTime: number;
          /**
           * @description  回访备注
           */
          visitRemark?: string;
          /**
           * @description  服务星评：1特别差,2差，3一般，4好，5非常好
           */
          visitServiceId: number;
        }
        /**
         * @name 员工回访功能，租客评价
         * @param data
         */
        export function visit(data: VisitParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/visit",
            data
          );
        }
        export interface AcceptOrderParams {
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  1：为接单，2：为拒绝接单
           */
          orderType?: string;
          /**
           * @description  接单备注
           */
          sendOrderDesc?: string;
          /**
           * @description  维修上门时间段
           */
          theDoorHouseTime?: string;
          /**
           * @description  维修上门时间
           */
          theDoorTime?: string;
        }
        /**
         * @name 工程师：接单
         * @param data
         */
        export function acceptOrder(data: AcceptOrderParams) {
          return request.put<ResponseResult<object>>(
            "/other-v100001/out/repair/acceptOrder",
            data
          );
        }
        export interface DealParams {
          /**
           * @description  材料费金额(非公司)
           */
          dealAmount?: number;
          /**
           * @description  非公司材料提成
           */
          dealCommission?: number;
          /**
           * @description  处理说明
           */
          dealDesc?: string;
          /**
           * @description  主键
           */
          id: number;
          /**
           * @description  维修材料(非公司)
           */
          material?: string;
          /**
           * @description  处理后图片
           */
          newImg?: string;
          /**
           * @description  本单提成
           */
          orderCommission?: number;
          maintenanceDealMaterialDtoList: Array<{
            commission: number;
            id: number;
            name: string;
            num: number;
            price: number;
            sumAmount: number;
          }>;
        }
        /**
         * @name 保洁或者维修人反馈处理结果
         * @param data
         */
        export function deal(data: DealParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/deal",
            data
          );
        }
        export interface DealCleanParams {
          /**
           * @description  公司承担费用
           */
          companyAmount?: number;
          /**
           * @description  处理说明
           */
          dealDesc?: string;
          /**
           * @description  主键
           */
          id: number;
          /**
           * @description  处理后图片
           */
          newImg?: string;
          /**
           * @description  业主承担金额
           */
          ownerAmount?: number;
          /**
           * @description  房东是否生成欠款(1，生成；2，不生成)
           */
          roomStatus?: string;
          /**
           * @description  租客承担费用
           */
          tenantsAmount?: number;
          /**
           * @description  租客是否生成欠款(1，生成；2，不生成)
           */
          tenantsStatus?: string;
          /**
           * @description  员工工时
           */
          workHour?: number;
        }
        /**
         * @name 保洁反馈处理结果
         * @param data
         */
        export function dealClean(data: DealCleanParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/dealClean",
            data
          );
        }
        /**
         * @name 外部维修删除
         * @param id 外部维修删除
         */
        export function deleteById(id: string | number) {
          return request.delete<ResponseResult<object>>(
            `/other-v100001/out/repair/delete/${id}`
          );
        }
        export interface ExportExcelParams {
          /**
           * @description  创建人电话
           */
          createPhone?: number;
          /**
           * @description  创建时间结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建时间开始
           */
          createTimeStart?: string;
          /**
           * @description  处理人id
           */
          dealId?: number;
          /**
           * @description  物业地区id
           */
          detailId?: string;
          /**
           * @description  完成时间结束
           */
          finishTimeEnd?: string;
          /**
           * @description  完成时间开始
           */
          finishTimeStart?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  是否删除（1-删除；0-未删除）
           */
          isDelete?: number;
          /**
           * @description  1接单，2派单，3未接单，4拒绝
           */
          isOrder?: number;
          /**
           * @description  撤销状态：0为未撤销，1为已撤销
           */
          isRevoke?: number;
          /**
           * @description  支付状态：1未付，3已付
           */
          payStatus?: number;
          /**
           * @description  支付时间
           */
          payTimeEnd?: string;
          /**
           * @description  支付时间
           */
          payTimeStart?: string;
          /**
           * @description  报表查询时间
           */
          reportTime?: number;
          /**
           * @description  派单人id
           */
          sendOrderId?: number;
          /**
           * @description  派单/接单时间结束
           */
          sendOrderTimeEnd?: string;
          /**
           * @description  派单/接单时间开始
           */
          sendOrderTimeStart?: string;
          /**
           * @description  状态：1未处理 2已处理 3开始处理 4暂停 5维修中 6其它
           */
          status?: number;
          /**
           * @description  客户名称
           */
          tenantsName?: string;
          /**
           * @description  客户电话
           */
          tenantsPhone?: string;
          /**
           * @description  维修上门时间结束
           */
          theDoorTime?: string;
          /**
           * @description  维修上门时间开始
           */
          theDoorTimeStart?: string;
          /**
           * @description  1表示维修 2保洁
           */
          typeId?: number;
          /**
           * @description  回访结果：pid:689
           */
          visitDealStatusId?: number;
          /**
           * @description  是否私下收费：1是，2否
           */
          visitIsCharge?: number;
          /**
           * @description  是否按时间完：1是，2否
           */
          visitOnTime?: number;
          /**
           * @description  服务星评：1特别差,2差，3一般，4好，5非常好
           */
          visitServiceId?: number;
          /**
           * @description  回访状态：1未回访 2已回访
           */
          visitStatus?: number;
          /**
           * @description  回访时间
           */
          visitTimeEnd?: string;
          /**
           * @description  回访时间
           */
          visitTimeStart?: string;
          /**
           * @description  回访人
           */
          visitUserId?: number;
        }
        /**
         * @name 外部保洁报表导出
         * @param params
         */
        export function exportExcel(params: ExportExcelParams) {
          return request.get("/other-v100001/out/repair/exportExcel", {
            params
          });
        }
        /**
         * @name 外部维修详情
         * @param id 外部维修详情
         */
        export function info(id: string | number) {
          return request.get<ResponseResult<Repair>>(
            `/other-v100001/out/repair/info/${id}`
          );
        }
        export interface ListParams {
          /**
           * @description  创建人电话
           */
          createPhone?: number;
          /**
           * @description  创建时间结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建时间开始
           */
          createTimeStart?: string;
          /**
           * @description  处理人id
           */
          dealId?: number;
          /**
           * @description  物业地区id
           */
          detailId?: string;
          /**
           * @description  完成时间结束
           */
          finishTimeEnd?: string;
          /**
           * @description  完成时间开始
           */
          finishTimeStart?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  是否删除（1-删除；0-未删除）
           */
          isDelete?: number;
          /**
           * @description  1接单，2派单，3未接单，4拒绝
           */
          isOrder?: number;
          /**
           * @description  撤销状态：0为未撤销，1为已撤销
           */
          isRevoke?: number;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  支付状态：1未付，3已付
           */
          payStatus?: number;
          /**
           * @description  支付时间
           */
          payTimeEnd?: string;
          /**
           * @description  支付时间
           */
          payTimeStart?: string;
          /**
           * @description  报表查询时间
           */
          reportTime?: number;
          /**
           * @description  派单人id
           */
          sendOrderId?: number;
          /**
           * @description  派单/接单时间结束
           */
          sendOrderTimeEnd?: string;
          /**
           * @description  派单/接单时间开始
           */
          sendOrderTimeStart?: string;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  状态：1未处理 2已处理 3开始处理 4暂停 5维修中 6其它
           */
          status?: number;
          /**
           * @description  客户名称
           */
          tenantsName?: string;
          /**
           * @description  客户电话
           */
          tenantsPhone?: string;
          /**
           * @description  维修上门时间结束
           */
          theDoorTime?: string;
          /**
           * @description  维修上门时间开始
           */
          theDoorTimeStart?: string;
          /**
           * @description  1表示维修 2保洁
           */
          typeId?: number;
          /**
           * @description  回访结果：pid:689
           */
          visitDealStatusId?: number;
          /**
           * @description  是否私下收费：1是，2否
           */
          visitIsCharge?: number;
          /**
           * @description  是否按时间完：1是，2否
           */
          visitOnTime?: number;
          /**
           * @description  服务星评：1特别差,2差，3一般，4好，5非常好
           */
          visitServiceId?: number;
          /**
           * @description  回访状态：1未回访 2已回访
           */
          visitStatus?: number;
          /**
           * @description  回访时间
           */
          visitTimeEnd?: string;
          /**
           * @description  回访时间
           */
          visitTimeStart?: string;
          /**
           * @description  回访人
           */
          visitUserId?: number;
        }
        /**
         * @name 管理后台：外部维修列表
         * @param params
         */
        export function list(params: ListParams) {
          return request.get<ResponseResult<PageList<Repair>>>(
            "/other-v100001/out/repair/list",
            { params }
          );
        }
        export interface RejectOrderParams {
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  拒绝备注
           */
          sendOrderDesc?: string;
        }
        /**
         * @name 后台：拒绝
         * @param data
         */
        export function rejectOrder(data: RejectOrderParams) {
          return request.put<ResponseResult<object>>(
            "/other-v100001/out/repair/rejectOrder",
            data
          );
        }
        export interface SaveParams {
          /**
           * @description  区域名称id
           */
          areaId: number;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  物业地区id
           */
          detailId: string;
          /**
           * @description  物业地区名称
           */
          detailName: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door: string;
          /**
           * @description  期望时间时间段 8:00-10:00 10:00-12:00 13:00-15:00  15:00-17:00  17:00-19:00
           */
          expectHouseTime: string;
          /**
           * @description  期望时间
           */
          expectTime: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor: string;
          /**
           * @description  报修图片
           */
          maintenanceImg?: string;
          /**
           * @description  报修内容
           */
          remark: string;
          /**
           * @description  客户名称
           */
          tenantsName: string;
          /**
           * @description  客户电话
           */
          tenantsPhone: string;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
        }
        /**
         * @name 外部维修新增
         * @param data
         */
        export function save(data: SaveParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/save",
            data
          );
        }
        export interface SaveCleanParams {
          /**
           * @description  区域名称id
           */
          areaId: number;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  保洁档次Id
           */
          cleanGradeId?: number;
          /**
           * @description  物业地区id
           */
          detailId: string;
          /**
           * @description  物业地区名称
           */
          detailName: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door: string;
          /**
           * @description  期望时间时间段 8:00-10:00 10:00-12:00 13:00-15:00  15:00-17:00  17:00-19:00
           */
          expectHouseTime: string;
          /**
           * @description  期望时间
           */
          expectTime: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor: string;
          /**
           * @description  保洁图片
           */
          maintenanceImg?: string;
          /**
           * @description  报修内容
           */
          remark: string;
          /**
           * @description  清洗类目ids
           */
          repairCategoryIds?: string;
          /**
           * @description  客户名称
           */
          tenantsName: string;
          /**
           * @description  客户电话
           */
          tenantsPhone: string;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
          /**
           * @description  保洁单价（每小时）
           */
          unitPrice?: number;
          /**
           * @description  员工工时
           */
          workHour?: number;
        }
        /**
         * @name 保洁新增
         * @param data
         */
        export function saveClean(data: SaveCleanParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/saveClean",
            data
          );
        }
        export interface SaveFollowParams {
          /**
           * @description  维修表主键
           */
          id: number;
          /**
           * @description  备注
           */
          remark: string;
          /**
           * @description  状态：3正在处理 4暂停  5维修中(默认这个)
           */
          statusId?: number;
          /**
           * @description  预计暂停结束时间
           */
          stopEndDate?: string;
        }
        /**
         * @name 跟进记录表新增
         * @param data
         */
        export function saveFollow(data: SaveFollowParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/saveFollow",
            data
          );
        }
        export interface SaveWashParams {
          /**
           * @description  区域名称id
           */
          areaId: number;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  保洁档次Id
           */
          cleanGradeId?: number;
          /**
           * @description  物业地区id
           */
          detailId: string;
          /**
           * @description  物业地区名称
           */
          detailName: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door: string;
          /**
           * @description  期望时间时间段 8:00-10:00 10:00-12:00 13:00-15:00  15:00-17:00  17:00-19:00
           */
          expectHouseTime: string;
          /**
           * @description  期望时间
           */
          expectTime: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor: string;
          /**
           * @description  保洁图片
           */
          maintenanceImg?: string;
          /**
           * @description  报修内容
           */
          remark: string;
          /**
           * @description  清洗类目ids
           */
          repairCategoryIds?: string;
          /**
           * @description  客户名称
           */
          tenantsName: string;
          /**
           * @description  客户电话
           */
          tenantsPhone: string;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
          /**
           * @description  保洁单价（每小时）
           */
          unitPrice?: number;
          /**
           * @description  员工工时
           */
          workHour?: number;
        }
        /**
         * @name 清洗新增
         * @param data
         */
        export function saveWash(data: SaveWashParams) {
          return request.post<ResponseResult<object>>(
            "/other-v100001/out/repair/saveWash",
            data
          );
        }
        export interface SendOrderParams {
          /**
           * @description  维修人id
           */
          dealId?: number;
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  派单备注
           */
          sendOrderDesc?: string;
          /**
           * @description  维修上门时间段
           */
          theDoorHouseTime?: string;
          /**
           * @description  维修上门时间
           */
          theDoorTime?: string;
          /**
           * @description  1：派单 2转派
           */
          type?: number;
        }
        /**
         * @name 工程师：派单：转派
         * @param data
         */
        export function sendOrder(data: SendOrderParams) {
          return request.put<ResponseResult<object>>(
            "/other-v100001/out/repair/sendOrder",
            data
          );
        }
        export interface UpdateParams {
          /**
           * @description  区域名称id
           */
          areaId: number;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  物业地区id
           */
          detailId: string;
          /**
           * @description  物业地区名称
           */
          detailName: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door: string;
          /**
           * @description  期望时间时间段 8:00-10:00 10:00-12:00 13:00-15:00  15:00-17:00  17:00-19:00
           */
          expectHouseTime: string;
          /**
           * @description  期望时间
           */
          expectTime: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor: string;
          /**
           * @description  报修图片
           */
          maintenanceImg?: string;
          /**
           * @description  报修内容
           */
          remark: string;
          /**
           * @description  客户名称
           */
          tenantsName: string;
          /**
           * @description  客户电话
           */
          tenantsPhone: string;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
        }
        /**
         * @name 外部维修修改
         * @param id 外部维修修改
         * @param data
         */
        export function update(id: string | number, data: UpdateParams) {
          return request.put<ResponseResult<object>>(
            `/other-v100001/out/repair/update/${id}`,
            data
          );
        }
        export interface UpdateCleanParams {
          /**
           * @description  区域名称id
           */
          areaId: number;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  保洁档次Id
           */
          cleanGradeId?: number;
          /**
           * @description  物业地区id
           */
          detailId: string;
          /**
           * @description  物业地区名称
           */
          detailName: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door: string;
          /**
           * @description  期望时间时间段 8:00-10:00 10:00-12:00 13:00-15:00  15:00-17:00  17:00-19:00
           */
          expectHouseTime: string;
          /**
           * @description  期望时间
           */
          expectTime: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor: string;
          /**
           * @description  保洁图片
           */
          maintenanceImg?: string;
          /**
           * @description  报修内容
           */
          remark: string;
          /**
           * @description  清洗类目ids
           */
          repairCategoryIds?: string;
          /**
           * @description  客户名称
           */
          tenantsName: string;
          /**
           * @description  客户电话
           */
          tenantsPhone: string;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
          /**
           * @description  保洁单价（每小时）
           */
          unitPrice?: number;
          /**
           * @description  员工工时
           */
          workHour?: number;
        }
        /**
         * @name 保洁新增
         * @param id 保洁新增
         * @param data
         */
        export function updateClean(
          id: string | number,
          data: UpdateCleanParams
        ) {
          return request.post<ResponseResult<object>>(
            `/other-v100001/out/repair/updateClean/${id}`,
            data
          );
        }
        export interface UpdateWashParams {
          /**
           * @description  区域名称id
           */
          areaId: number;
          /**
           * @description  座栋(修改时回显用) 随便填
           */
          building?: string;
          /**
           * @description  保洁档次Id
           */
          cleanGradeId?: number;
          /**
           * @description  物业地区id
           */
          detailId: string;
          /**
           * @description  物业地区名称
           */
          detailName: string;
          /**
           * @description  门牌(修改时回显用) 只能2位数字
           */
          door: string;
          /**
           * @description  期望时间时间段 8:00-10:00 10:00-12:00 13:00-15:00  15:00-17:00  17:00-19:00
           */
          expectHouseTime: string;
          /**
           * @description  期望时间
           */
          expectTime: string;
          /**
           * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
           */
          floor: string;
          /**
           * @description  保洁图片
           */
          maintenanceImg?: string;
          /**
           * @description  报修内容
           */
          remark: string;
          /**
           * @description  清洗类目ids
           */
          repairCategoryIds?: string;
          /**
           * @description  客户名称
           */
          tenantsName: string;
          /**
           * @description  客户电话
           */
          tenantsPhone: string;
          /**
           * @description  单元(修改时回显用) 随便填
           */
          unit?: string;
          /**
           * @description  保洁单价（每小时）
           */
          unitPrice?: number;
          /**
           * @description  员工工时
           */
          workHour?: number;
        }
        /**
         * @name 保洁新增
         * @param id 保洁新增
         * @param data
         */
        export function updateWash(
          id: string | number,
          data: UpdateWashParams
        ) {
          return request.post<ResponseResult<object>>(
            `/other-v100001/out/repair/updateWash/${id}`,
            data
          );
        }
      }
    }
    export namespace reserveCustomers {
      export interface CheckPhoneParams {
        /**
         * @description  称呼类型Id
         */
        contactTypeId?: number;
        /**
         * @description  电话
         */
        phone?: string;
      }
      /**
       * @name 如果录入过，那么提示用户是否继续保存
       * @param params
       */
      export function checkPhone(params: CheckPhoneParams) {
        return request.get<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/checkPhone",
          { params }
        );
      }
      export interface CooperationListParams {
        /**
         * @description  租赁位置（手动输入）
         */
        addr?: string;
        /**
         * @description  称呼类型Id(pid:611)
         */
        callTypeId?: number;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(pid:613)
         */
        decorateTypeId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  跟进人id
         */
        followId?: number;
        /**
         * @description  跟进时间结束
         */
        followTimeEnd?: string;
        /**
         * @description  跟进时间开始
         */
        followTimeStart?: string;
        /**
         * @description  1整租  2合租  3办工
         */
        houseType?: number;
        /**
         * @description  1我的客源  2公共客源  3查看所有
         */
        isData?: number;
        /**
         * @description  1,私客  2公客  3即将跳公
         */
        isDataId?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  几室(可多选前端写鲜红)
         */
        room?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  紧急pid+
         */
        urgencyId?: number;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 合作潜在业主列表
       * @param params
       */
      export function cooperationList(params: CooperationListParams) {
        return request.get<ResponseResult<PageList<ReserveCustomers>>>(
          "/other-v100001/reserveCustomers/cooperationList",
          { params }
        );
      }
      /**
       * @name 潜在租客删除
       * @param id 潜在租客删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/reserveCustomers/delete/${id}`
        );
      }
      /**
       * @name 删除所有合作人
       * @param id 删除所有合作人
       */
      export function deleteCooperation(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/reserveCustomers/deleteCooperation/${id}`
        );
      }
      /**
       * @name 潜在租客详情
       * @param id 潜在租客详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<ReserveCustomers>>(
          `/other-v100001/reserveCustomers/info/${id}`
        );
      }
      export interface InsertFollowParams {
        /**
         * @description  跟进方式  1去电  2来电
         */
        followWayId: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  是否生成待办 1是  2否  如果选择是，才显示待办的下面的输入框
         */
        isWay?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向 pid 639
         */
        rentPurposeId?: number;
        /**
         * @description  租赁状态
         */
        statusId?: number;
        /**
         * @description  跟进类型：1，状态跟进；2，普通跟进
         */
        type?: number;
        /**
         * @description  紧急程度 pid:643
         */
        urgencyId?: number;
        /**
         * @description  待办描述
         */
        wayDesc?: string;
        /**
         * @description  待办时间
         */
        wayTime?: string;
      }
      /**
       * @name 普通类添加跟进
       * @param data
       */
      export function insertFollow(data: InsertFollowParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/insertFollow",
          data
        );
      }
      export interface InsertFollowV1Params {
        /**
         * @description  跟进方式  1去电  2来电
         */
        followWayId: number;
        /**
         * @description  是否生成待办 1是  2否  如果选择是，才显示待办的下面的输入框
         */
        isWay?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向 pid 639
         */
        rentPurposeId?: number;
        /**
         * @description  租赁状态
         */
        statusId?: number;
        /**
         * @description  跟进类型：1，状态跟进；2，普通跟进
         */
        type?: number;
        /**
         * @description  紧急程度 pid:643
         */
        urgencyId?: number;
        /**
         * @description  待办描述
         */
        wayDesc?: string;
        /**
         * @description  待办时间
         */
        wayTime?: string;
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 状态类添加跟进
       * @param data
       */
      export function insertFollowV1(data: InsertFollowV1Params) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/insertFollowV1",
          data
        );
      }
      export interface ListParams {
        /**
         * @description  租赁位置（手动输入）
         */
        addr?: string;
        /**
         * @description  称呼类型Id(pid:611)
         */
        callTypeId?: number;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(pid:613)
         */
        decorateTypeId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  跟进人id
         */
        followId?: number;
        /**
         * @description  跟进时间结束
         */
        followTimeEnd?: string;
        /**
         * @description  跟进时间开始
         */
        followTimeStart?: string;
        /**
         * @description  1整租  2合租  3办工
         */
        houseType?: number;
        /**
         * @description  1我的客源  2公共客源  3查看所有
         */
        isData?: number;
        /**
         * @description  1,私客  2公客  3即将跳公
         */
        isDataId?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  几室(可多选前端写鲜红)
         */
        room?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  紧急pid+
         */
        urgencyId?: number;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 潜在租客列表（前端做成功3个列表，一个所有客源（有权限的人能看）  潜在租客  公共客源）
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<ReserveCustomers>>>(
          "/other-v100001/reserveCustomers/list",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  租赁位置（手动输入）
         */
        addr?: string;
        /**
         * @description  称呼类型Id(pid:611)
         */
        callTypeId?: number;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  装修类型id(pid:613)
         */
        decorateTypeId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  跟进人id
         */
        followId?: number;
        /**
         * @description  跟进时间结束
         */
        followTimeEnd?: string;
        /**
         * @description  跟进时间开始
         */
        followTimeStart?: string;
        /**
         * @description  1整租  2合租  3办工
         */
        houseType?: number;
        /**
         * @description  1我的客源  2公共客源  3查看所有
         */
        isData?: number;
        /**
         * @description  1,私客  2公客  3即将跳公
         */
        isDataId?: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  几室(可多选前端写鲜红)
         */
        room?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  紧急pid+
         */
        urgencyId?: number;
        /**
         * @description  用途id(pid:177)
         */
        useId?: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 潜在业主数据导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/other-v100001/reserveCustomers/reportExport", {
          params
        });
      }
      export interface SaveParams {
        /**
         * @description  面积区间最大
         */
        acreageMax?: string;
        /**
         * @description  面积区间最小
         */
        acreageMin?: string;
        /**
         * @description  租赁位置（手动输入）
         */
        addr?: string;
        /**
         * @description  称呼类型Id(pid:611)
         */
        callTypeId: number;
        /**
         * @description  联系方式类型id(pid:612)
         */
        contactTypeId: number;
        /**
         * @description  装修类型id(pid:613)
         */
        decorateTypeId?: number;
        /**
         * @description  1整租  2合租  3办工
         */
        houseType: number;
        /**
         * @description  最高价格
         */
        maxAmount: number;
        /**
         * @description  最低价格
         */
        minAmount: number;
        /**
         * @description  姓氏
         */
        name?: string;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  几室(可多选前端写鲜红)
         */
        room: string;
        /**
         * @description  门店id
         */
        storeId: number;
        /**
         * @description  紧急字典
         */
        urgencyId: number;
        /**
         * @description  用途id (字典)(pid:177)
         */
        useId: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 潜在租客新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/save",
          data
        );
      }
      export interface SaveOrUpdateCooperationParams {
        /**
         * @description  id
         */
        id?: number;
        /**
         * @description  userIds
         */
        userIds?: string;
      }
      /**
       * @name 添加以及修改合作人
       * @param data
       */
      export function saveOrUpdateCooperation(
        data: SaveOrUpdateCooperationParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/saveOrUpdateCooperation",
          data
        );
      }
      export interface SaveTakeLookParams {
        /**
         * @description  1整租 2合租  3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id: number;
        /**
         * @description  房间id多个以逗号隔开
         */
        roomId?: string;
        /**
         * @description  带看反馈
         */
        taskLookDesc?: string;
      }
      /**
       * @name 添加带看
       * @param data
       */
      export function saveTakeLook(data: SaveTakeLookParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/saveTakeLook",
          data
        );
      }
      /**
       * @name syncReserve
       */
      export function syncReserve() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/syncReserve"
        );
      }
      export interface UpdateParams {
        /**
         * @description  面积区间最大
         */
        acreageMax?: string;
        /**
         * @description  面积区间最小
         */
        acreageMin?: string;
        /**
         * @description  租赁位置（手动输入）
         */
        addr?: string;
        /**
         * @description  称呼类型Id(pid:611)
         */
        callTypeId: number;
        /**
         * @description  联系方式类型id(pid:612)
         */
        contactTypeId: number;
        /**
         * @description  装修类型id(pid:613)
         */
        decorateTypeId?: number;
        /**
         * @description  1整租  2合租  3办工
         */
        houseType: number;
        /**
         * @description  最高价格
         */
        maxAmount: number;
        /**
         * @description  最低价格
         */
        minAmount: number;
        /**
         * @description  姓氏
         */
        name?: string;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  几室(可多选前端写鲜红)
         */
        room: string;
        /**
         * @description  门店id
         */
        storeId: number;
        /**
         * @description  紧急字典
         */
        urgencyId: number;
        /**
         * @description  用途id (字典)(pid:177)
         */
        useId: number;
        /**
         * @description  客户来源id(pid:610)
         */
        userSourceId?: number;
      }
      /**
       * @name 潜在租客修改（不做权限控制）
       * @param id 潜在租客修改（不做权限控制）
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/reserveCustomers/update/${id}`,
          data
        );
      }
      export interface UpdateBatchCreateUserParams {
        /**
         * @description  新业务员id
         */
        newUserId: number;
        /**
         * @description  原来业务员id
         */
        oldUserId: number;
      }
      /**
       * @name 批量修改业务员
       * @param data
       */
      export function updateBatchCreateUser(data: UpdateBatchCreateUserParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/updateBatchCreateUser",
          data
        );
      }
      export interface UpdateIsDataParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  公私客标记
         */
        isData?: number;
      }
      /**
       * @name 拉私客和标记为公客
       * @param data
       */
      export function updateIsData(data: UpdateIsDataParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/updateIsData",
          data
        );
      }
      /**
       * @name updateOther
       */
      export function updateOther() {
        return request.post("/other-v100001/reserveCustomers/updateOther");
      }
      export interface UpdateStatusParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  状态字典：pid:302
         */
        statusId?: number;
      }
      /**
       * @name 修改状态
       * @param data
       */
      export function updateStatus(data: UpdateStatusParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveCustomers/updateStatus",
          data
        );
      }
    }
    export namespace reserveFollow {
      /**
       * @name 储备跟进记录表删除
       * @param id 储备跟进记录表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/reserveFollow/delete/${id}`
        );
      }
      /**
       * @name 储备跟进记录表详情
       * @param id 储备跟进记录表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<ReserveFollow>>(
          `/other-v100001/reserveFollow/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  1储备客源 2储备房源
         */
        followType?: number;
        /**
         * @description  其它表主键
         */
        otherId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  租赁状态
         */
        statusId?: number;
      }
      /**
       * @name 储备跟进记录表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<ReserveFollow>>>(
          "/other-v100001/reserveFollow/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  跟进方式  1去电  2来电
         */
        followWayId: number;
        /**
         * @description  是否生成待办 1是  2否  如果选择是，才显示待办的下面的输入框
         */
        isWay?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向 pid 639
         */
        rentPurposeId?: number;
        /**
         * @description  租赁状态
         */
        statusId?: number;
        /**
         * @description  跟进类型：1，状态跟进；2，普通跟进
         */
        type?: number;
        /**
         * @description  紧急程度 pid:643
         */
        urgencyId?: number;
        /**
         * @description  待办描述
         */
        wayDesc?: string;
        /**
         * @description  待办时间
         */
        wayTime?: string;
      }
      /**
       * @name 储备跟进记录表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveFollow/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  跟进方式  1去电  2来电
         */
        followWayId: number;
        /**
         * @description  是否生成待办 1是  2否  如果选择是，才显示待办的下面的输入框
         */
        isWay?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向 pid 639
         */
        rentPurposeId?: number;
        /**
         * @description  租赁状态
         */
        statusId?: number;
        /**
         * @description  跟进类型：1，状态跟进；2，普通跟进
         */
        type?: number;
        /**
         * @description  紧急程度 pid:643
         */
        urgencyId?: number;
        /**
         * @description  待办描述
         */
        wayDesc?: string;
        /**
         * @description  待办时间
         */
        wayTime?: string;
      }
      /**
       * @name 储备跟进记录表修改
       * @param id 储备跟进记录表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/reserveFollow/update/${id}`,
          data
        );
      }
    }
    export namespace reserveHouse {
      export interface CooperationListParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  跟进人id
         */
        followId?: number;
        /**
         * @description  跟进时间结束
         */
        followTimeEnd?: string;
        /**
         * @description  跟进时间开始
         */
        followTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  1我的客源  2公共客源  3查看所有
         */
        isData?: number;
        /**
         * @description  1,私客  2公客  3即将跳公
         */
        isDataId?: number;
        /**
         * @description  是否成交:0未成交；1已成交
         */
        isDeal?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  是否有效：0，有效；1，非有效
         */
        statusType?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  房源类型id
         */
        typeId?: number;
      }
      /**
       * @name 合作潜在业主列表
       * @param params
       */
      export function cooperationList(params: CooperationListParams) {
        return request.get<ResponseResult<PageList<ReserveHouse>>>(
          "/other-v100001/reserveHouse/cooperationList",
          { params }
        );
      }
      /**
       * @name 潜在业主删除
       * @param id 潜在业主删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/reserveHouse/delete/${id}`
        );
      }
      /**
       * @name 删除所有合作人
       * @param id 删除所有合作人
       */
      export function deleteCooperation(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/reserveHouse/deleteCooperation/${id}`
        );
      }
      /**
       * @name 潜在业主详情
       * @param id 潜在业主详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<ReserveHouse>>(
          `/other-v100001/reserveHouse/info/${id}`
        );
      }
      export interface InsertFollowParams {
        /**
         * @description  跟进方式  1去电  2来电
         */
        followWayId: number;
        /**
         * @description  是否生成待办 1是  2否  如果选择是，才显示待办的下面的输入框
         */
        isWay?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向 pid 639
         */
        rentPurposeId?: number;
        /**
         * @description  租赁状态
         */
        statusId?: number;
        /**
         * @description  跟进类型：1，状态跟进；2，普通跟进
         */
        type?: number;
        /**
         * @description  紧急程度 pid:643
         */
        urgencyId?: number;
        /**
         * @description  待办描述
         */
        wayDesc?: string;
        /**
         * @description  待办时间
         */
        wayTime?: string;
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 添加跟进
       * @param data
       */
      export function insertFollow(data: InsertFollowParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/insertFollow",
          data
        );
      }
      export interface InsertFollowV1Params {
        /**
         * @description  跟进方式  1去电  2来电
         */
        followWayId: number;
        /**
         * @description  是否生成待办 1是  2否  如果选择是，才显示待办的下面的输入框
         */
        isWay?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向 pid 639
         */
        rentPurposeId?: number;
        /**
         * @description  租赁状态
         */
        statusId?: number;
        /**
         * @description  跟进类型：1，状态跟进；2，普通跟进
         */
        type?: number;
        /**
         * @description  紧急程度 pid:643
         */
        urgencyId?: number;
        /**
         * @description  待办描述
         */
        wayDesc?: string;
        /**
         * @description  待办时间
         */
        wayTime?: string;
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 添加跟进
       * @param data
       */
      export function insertFollowV1(data: InsertFollowV1Params) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/insertFollowV1",
          data
        );
      }
      export interface ListParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  跟进人id
         */
        followId?: number;
        /**
         * @description  跟进时间结束
         */
        followTimeEnd?: string;
        /**
         * @description  跟进时间开始
         */
        followTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  1我的客源  2公共客源  3查看所有
         */
        isData?: number;
        /**
         * @description  1,私客  2公客  3即将跳公
         */
        isDataId?: number;
        /**
         * @description  是否成交:0未成交；1已成交
         */
        isDeal?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  是否有效：0，有效；1，非有效
         */
        statusType?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  房源类型id
         */
        typeId?: number;
      }
      /**
       * @name 潜在业主列表 （前端做成功3个列表，一个所有房源（有权限的人能看）  潜在业主  公共房源）
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<ReserveHouse>>>(
          "/other-v100001/reserveHouse/list",
          { params }
        );
      }
      export interface ReportExportParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  跟进人id
         */
        followId?: number;
        /**
         * @description  跟进时间结束
         */
        followTimeEnd?: string;
        /**
         * @description  跟进时间开始
         */
        followTimeStart?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  1我的客源  2公共客源  3查看所有
         */
        isData?: number;
        /**
         * @description  1,私客  2公客  3即将跳公
         */
        isDataId?: number;
        /**
         * @description  是否成交:0未成交；1已成交
         */
        isDeal?: number;
        /**
         * @description  出租意向 字典
         */
        rentPurposeId?: number;
        /**
         * @description  状态
         */
        statusId?: number;
        /**
         * @description  是否有效：0，有效；1，非有效
         */
        statusType?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  房源类型id
         */
        typeId?: number;
      }
      /**
       * @name 潜在业主数据导出
       * @param params
       */
      export function reportExport(params: ReportExportParams) {
        return request.get("/other-v100001/reserveHouse/reportExport", {
          params
        });
      }
      export interface SaveParams {
        /**
         * @description  面积
         */
        acreage?: string;
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId: number;
        /**
         * @description  座栋(修改时回显用) 随便填
         */
        building?: string;
        /**
         * @description  称呼类型Id(pid:611)
         */
        callTypeId: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  物业地区名称
         */
        detailName?: string;
        /**
         * @description  门牌(修改时回显用) 只能2位数字
         */
        door: string;
        /**
         * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
         */
        floor: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  报价
         */
        houseAmount: number;
        /**
         * @description  房源图片（9张）
         */
        houseImg?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  1整租  2合租  3办工
         */
        houseType: number;
        /**
         * @description  几厨
         */
        kitchen?: number;
        /**
         * @description  房源经度
         */
        lat?: string;
        /**
         * @description  房源纬度
         */
        lng?: string;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向pid 639
         */
        rentPurposeId: number;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  门店id
         */
        storeId: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  单元(修改时回显用) 随便填
         */
        unit?: string;
        /**
         * @description  业主来源id(pid:610)
         */
        userSourceId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 潜在业主新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/save",
          data
        );
      }
      export interface SaveOrUpdateCooperationParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  合作人ids，id用逗号拼接
         */
        userIds?: string;
      }
      /**
       * @name 添加以及修改合作人
       * @param data
       */
      export function saveOrUpdateCooperation(
        data: SaveOrUpdateCooperationParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/saveOrUpdateCooperation",
          data
        );
      }
      /**
       * @name syncReserve
       */
      export function syncReserve() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/syncReserve"
        );
      }
      export interface UpdateParams {
        /**
         * @description  面积
         */
        acreage?: string;
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId: number;
        /**
         * @description  座栋(修改时回显用) 随便填
         */
        building?: string;
        /**
         * @description  称呼类型Id(pid:611)
         */
        callTypeId: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  物业地区名称
         */
        detailName?: string;
        /**
         * @description  门牌(修改时回显用) 只能2位数字
         */
        door: string;
        /**
         * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
         */
        floor: string;
        /**
         * @description  几厅
         */
        hall?: number;
        /**
         * @description  报价
         */
        houseAmount: number;
        /**
         * @description  房源图片（9张）
         */
        houseImg?: string;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  手机号
         */
        housePhone?: string;
        /**
         * @description  1整租  2合租  3办工
         */
        houseType: number;
        /**
         * @description  几厨
         */
        kitchen?: number;
        /**
         * @description  房源经度
         */
        lat?: string;
        /**
         * @description  房源纬度
         */
        lng?: string;
        /**
         * @description  缴费方式间隔(月)
         */
        payTypeId?: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  出租意向pid 639
         */
        rentPurposeId: number;
        /**
         * @description  几室
         */
        room?: number;
        /**
         * @description  门店id
         */
        storeId: number;
        /**
         * @description  房源类型id
         */
        typeId?: number;
        /**
         * @description  单元(修改时回显用) 随便填
         */
        unit?: string;
        /**
         * @description  业主来源id(pid:610)
         */
        userSourceId?: number;
        /**
         * @description  几卫
         */
        who?: number;
      }
      /**
       * @name 潜在业主修改
       * @param id 潜在业主修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/reserveHouse/update/${id}`,
          data
        );
      }
      export interface UpdateBatchCreateUserParams {
        /**
         * @description  新业务员id
         */
        newUserId: number;
        /**
         * @description  原来业务员id
         */
        oldUserId: number;
      }
      /**
       * @name 批量修改业务员
       * @param data
       */
      export function updateBatchCreateUser(data: UpdateBatchCreateUserParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/updateBatchCreateUser",
          data
        );
      }
      /**
       * @name updateDealId
       */
      export function updateDealId() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/updateDealId"
        );
      }
      export interface UpdateIsDataParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  公私客标记
         */
        isData?: number;
      }
      /**
       * @name 拉私客和标记为公客
       * @param data
       */
      export function updateIsData(data: UpdateIsDataParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/updateIsData",
          data
        );
      }
      /**
       * @name updateOther
       */
      export function updateOther() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/reserveHouse/updateOther"
        );
      }
    }
    export namespace smsLog {
      export interface ListParams {
        /**
         * @description  发送人姓名
         */
        createName?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  接受人电话
         */
        receivePhone?: string;
        /**
         * @description  接受人姓名
         */
        receiveUserName?: string;
        /**
         * @description  报表统计时间
         */
        reportTime?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  短信返回状态
         */
        status?: number;
        /**
         * @description  模块id 字典pid:341
         */
        typeId?: number;
      }
      /**
       * @name 短信日志表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<SmsLog>>>(
          "/other-v100001/smsLog/list",
          { params }
        );
      }
    }
    export namespace smsTemplate {
      /**
       * @name 短信模板CODE  ,添加短信时候使用，客户才知道短信编码
       * @param catId 短信模板CODE  ,添加短信时候使用，客户才知道短信编码
       */
      export function code(catId: string | number) {
        return request.get<ResponseResult<Array<SmsTemplateCodeDto>>>(
          `/other-v100001/smsTemplate/code/${catId}`
        );
      }
      /**
       * @name 短信模板删除
       * @param id 短信模板删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/smsTemplate/delete/${id}`
        );
      }
      /**
       * @name 短信模板详情
       * @param id 短信模板详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<SmsTemplate>>(
          `/other-v100001/smsTemplate/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  模板名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  类型 1房东  2租客
         */
        type?: number;
      }
      /**
       * @name 短信模板分页列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<SmsTemplate>>>(
          "/other-v100001/smsTemplate/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  模板分类ID
         */
        catId: number;
        /**
         * @description  模板内容
         */
        content: string;
        /**
         * @description  模板名称
         */
        name: string;
        /**
         * @description  类型 1房东  2租客
         */
        type: number;
      }
      /**
       * @name 短信模板新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/smsTemplate/save",
          data
        );
      }
      export interface SelectParams {
        /**
         * @description  模板名称
         */
        name?: string;
        /**
         * @description  类型 1房东  2租客
         */
        type?: number;
      }
      /**
       * @name 短信模板下拉接口，真正发送的短信的选择加好的模版
       * @param params
       */
      export function select(params: SelectParams) {
        return request.get<ResponseResult<Array<SmsTemplate>>>(
          "/other-v100001/smsTemplate/select",
          { params }
        );
      }
      export interface SendParams {
        /**
         * @description  模板ID
         */
        templateId: number;
        /**
         * @description  业务ID
         */
        busId: number;
      }
      /**
       * @name 单条短信发送
       * @param params
       */
      export function send(params: SendParams) {
        return request.get<ResponseResult<object>>(
          "/other-v100001/smsTemplate/send",
          { params }
        );
      }
      export interface SendBatchParams {
        /**
         * @description  模板ID
         */
        templateId: number;
        /**
         * @description  店面id
         */
        storeId?: number;
      }
      /**
       * @name 群发短信发送
       * @param params
       */
      export function sendBatch(params: SendBatchParams) {
        return request.get<ResponseResult<object>>(
          "/other-v100001/smsTemplate/sendBatch",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  模板分类ID
         */
        catId: number;
        /**
         * @description  模板内容
         */
        content: string;
        /**
         * @description  模板名称
         */
        name: string;
        /**
         * @description  类型 1房东  2租客
         */
        type: number;
      }
      /**
       * @name 短信模板修改
       * @param id 短信模板修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/smsTemplate/update/${id}`,
          data
        );
      }
    }
    export namespace stewardChange {
      /**
       * @name 管家变更表删除
       * @param id 管家变更表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/stewardChange/delete/${id}`
        );
      }
      export interface ExportStewardChangeParams {
        /**
         * @description  类型：1为管家，2为第二管家
         */
        changeType?: number;
        /**
         * @description  创建结束时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建结束开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  原管家id
         */
        oldStewardId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  新管家id
         */
        stewardId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 管家变更表列表导出
       * @param params
       */
      export function exportStewardChange(params: ExportStewardChangeParams) {
        return request.get("/other-v100001/stewardChange/exportStewardChange", {
          params
        });
      }
      export interface ReportListParams {
        /**
         * @description  类型：1为管家，2为第二管家
         */
        changeType?: number;
        /**
         * @description  创建结束时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建结束开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  原管家id
         */
        oldStewardId?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  新管家id
         */
        stewardId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 管家变更表列表
       * @param params
       */
      export function reportList(params: ReportListParams) {
        return request.get<ResponseResult<PageList<StewardChange>>>(
          "/other-v100001/stewardChange/reportList",
          { params }
        );
      }
    }
    export namespace takeLook {
      /**
       * @name copyDate
       */
      export function copyDate() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/takeLook/copyDate"
        );
      }
      /**
       * @name 带看房源记录表删除
       * @param id 带看房源记录表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/takeLook/delete/${id}`
        );
      }
      /**
       * @name 带看房源记录表详情
       * @param id 带看房源记录表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<TakeLook>>(
          `/other-v100001/takeLook/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  创建日期
         */
        createDate?: string;
        /**
         * @description  创建时间结束
         */
        createDateEnd?: string;
        /**
         * @description  创建时间开始
         */
        createDateStart?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  1正常 2异常
         */
        isException?: number;
        /**
         * @description  带看次数：1次 2次  到  10次
         */
        lookCount?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 带看房源记录表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<TakeLook>>>(
          "/other-v100001/takeLook/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  跟进说明
         */
        followDesc?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  照片
         */
        img?: string;
        /**
         * @description  当前定位经度
         */
        locationLat?: string;
        /**
         * @description  当前定位纬度
         */
        locationLng?: string;
        /**
         * @description  客户称呼
         */
        name?: string;
        /**
         * @description  客户电话
         */
        phone?: string;
        /**
         * @description  带看反馈
         */
        remark?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
      }
      /**
       * @name 小程序带看房源记录表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/takeLook/save",
          data
        );
      }
    }
    export namespace tenantsExitApply {
      export interface AuditParams {
        /**
         * @description  回复说明
         */
        auditDesc?: string;
        /**
         * @description  主键
         */
        id: number;
        /**
         * @description  1通过 2不通过
         */
        status: number;
      }
      /**
       * @name 审核租客退房或转租申请
       * @param data
       */
      export function audit(data: AuditParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/tenantsExitApply/audit",
          data
        );
      }
      export interface CancelParams {
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 租客：取消申请
       * @param data
       */
      export function cancel(data: CancelParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/tenantsExitApply/cancel",
          data
        );
      }
      /**
       * @name 租客退房或转租申请删除
       * @param id 租客退房或转租申请删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/tenantsExitApply/delete/${id}`
        );
      }
      export interface ExportExcelParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  处理人2id
         */
        dealId2?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        /**
         * @description  1通过 2不通过  3申请中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  1表示正常退房 2违约退房 3转租
         */
        typeId?: number;
      }
      /**
       * @name 客户后台：租客退房或转租申请列表导出
       * @param params
       */
      export function exportExcel(params: ExportExcelParams) {
        return request.get("/other-v100001/tenantsExitApply/exportExcel", {
          params
        });
      }
      /**
       * @name 租客退房或转租申请详情
       * @param id 租客退房或转租申请详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<TenantsExitApply>>(
          `/other-v100001/tenantsExitApply/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  处理人2id
         */
        dealId2?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1通过 2不通过  3申请中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  1表示正常退房 2违约退房 3转租
         */
        typeId?: number;
      }
      /**
       * @name 租客退房或转租申请列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<TenantsExitApply>>>(
          "/other-v100001/tenantsExitApply/list",
          { params }
        );
      }
      export interface MyTenantsExitApplyListParams {
        /**
         * @description  区id(通过物业地址获取)
         */
        areaId?: number;
        /**
         * @description  处理人id
         */
        dealId?: number;
        /**
         * @description  处理人2id
         */
        dealId2?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租，2合租，3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  房间号(合租)
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1通过 2不通过  3申请中
         */
        status?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  客户名称
         */
        tenantsName?: string;
        /**
         * @description  客户电话
         */
        tenantsPhone?: string;
        /**
         * @description  1表示正常退房 2违约退房 3转租
         */
        typeId?: number;
      }
      /**
       * @name 租客：我的退房或转租申请列表
       * @param params
       */
      export function myTenantsExitApplyList(
        params: MyTenantsExitApplyListParams
      ) {
        return request.get<ResponseResult<PageList<TenantsExitApply>>>(
          "/other-v100001/tenantsExitApply/myTenantsExitApplyList",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  期望时间  如果是违约退与转租  选择 1天内  3天内 5天内 7天内  15天内 1个月内
         */
        expectTime?: string;
        /**
         * @description  申请说明
         */
        remark?: string;
        /**
         * @description  1表示正常退房 2违约退房 3转租
         */
        typeId: number;
      }
      /**
       * @name 租客退房或转租申请新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/tenantsExitApply/save",
          data
        );
      }
      /**
       * @name setDeal2
       */
      export function setDeal2() {
        return request.post<ResponseResult<object>>(
          "/other-v100001/tenantsExitApply/setDeal2"
        );
      }
      export interface UpdateParams {
        /**
         * @description  期望时间  如果是违约退与转租  选择 1天内  3天内 5天内 7天内  15天内 1个月内
         */
        expectTime?: string;
        /**
         * @description  申请说明
         */
        remark?: string;
        /**
         * @description  1表示正常退房 2违约退房 3转租
         */
        typeId: number;
      }
      /**
       * @name 租客退房或转租申请修改
       * @param id 租客退房或转租申请修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/tenantsExitApply/update/${id}`,
          data
        );
      }
    }
    export namespace oss {
      export interface DeleteByIdParams {
        /**
         * @description  filePath
         */
        filePath?: string;
      }
      /**
       * @name 文件删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<Array<string>>>(
          "/other-v100001/oss/delete",
          { params }
        );
      }
      export interface DownloadFileParams {
        /**
         * @description  url
         */
        url?: string;
      }
      /**
       * @name 下载文件不支持批量下载
       * @param params
       */
      export function downloadFile(params: DownloadFileParams) {
        return request.get("/other-v100001/oss/downloadFile", { params });
      }
      /**
       * @name 获取签名
       */
      export function osspolicy() {
        return request.get<ResponseResult<OssPolicyResult>>(
          "/other-v100001/oss/osspolicy"
        );
      }
      /**
       * @name 文件上传
       */
      export function upload() {
        return request.post<ResponseResult<string>>(
          "/other-v100001/oss/upload"
        );
      }
    }
    export namespace userLog {
      /**
       * @name 登陆日志表删除
       * @param id 登陆日志表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/userLog/delete/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  客户id(全局标识)
         */
        companyId?: number;
        /**
         * @description  日志描述(干了什么事)
         */
        content?: string;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  最后登陆IP
         */
        lastIp?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  登陆账号
         */
        phone?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1pc  2租客 3房东 4管理端小程序
         */
        type?: number;
        /**
         * @description  1pc  2租客 3房东 4管理端小程序
         */
        typeName?: string;
        /**
         * @description  用户id
         */
        userId?: number;
        /**
         * @description  用户名称
         */
        userName?: string;
      }
      /**
       * @name 登陆日志表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<UserLog>>>(
          "/other-v100001/userLog/list",
          { params }
        );
      }
    }
    export namespace wait {
      export interface AdminListParams {
        /**
         * @description  审批状态值：1通过 2不通过
         */
        auditStatus?: number;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  待办处理人id
         */
        dealId?: number;
        /**
         * @description  是否是审批数据：1，是；2，不是
         */
        isAudit?: number;
        /**
         * @description  1已读  2未读
         */
        isRead?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1已处理   2未处理
         */
        status?: number;
        /**
         * @description  类型id
         */
        typeId?: number;
        /**
         * @description  提醒时间
         */
        wayTime?: string;
      }
      /**
       * @name 管理待办记录表列表 (列表最好能看到图片)
       * @param params
       */
      export function adminList(params: AdminListParams) {
        return request.get<ResponseResult<PageList<Wait>>>(
          "/other-v100001/wait/adminList",
          { params }
        );
      }
      /**
       * @name 待办记录表删除
       * @param id 待办记录表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/wait/delete/${id}`
        );
      }
      /**
       * @name 待办记录表详情
       * @param id 待办记录表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Wait>>(
          `/other-v100001/wait/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  审批状态值：1通过 2不通过
         */
        auditStatus?: number;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  待办处理人id
         */
        dealId?: number;
        /**
         * @description  是否是审批数据：1，是；2，不是
         */
        isAudit?: number;
        /**
         * @description  1已读  2未读
         */
        isRead?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1已处理   2未处理
         */
        status?: number;
        /**
         * @description  类型id
         */
        typeId?: number;
        /**
         * @description  提醒时间
         */
        wayTime?: string;
      }
      /**
       * @name 首页小框待办记录表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Wait>>>(
          "/other-v100001/wait/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  待办处理人id
         */
        dealId?: number;
        /**
         * @description  图片url
         */
        imgs?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  类型id 字典pid:192  默认选中子级字典300 bug选项
         */
        typeId: number;
        /**
         * @description  完成时间
         */
        wayTime: string;
      }
      /**
       * @name 待办记录表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/wait/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  待办处理人id
         */
        dealId?: number;
        /**
         * @description  图片url
         */
        imgs?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  类型id 字典pid:192  默认选中子级字典300 bug选项
         */
        typeId: number;
        /**
         * @description  完成时间
         */
        wayTime: string;
      }
      /**
       * @name 待办记录修改
       * @param id 待办记录修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/wait/update/${id}`,
          data
        );
      }
      export interface UpdateStatusParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 标记已解决
       * @param data
       */
      export function updateStatus(data: UpdateStatusParams) {
        return request.put<ResponseResult<object>>(
          "/other-v100001/wait/updateStatus",
          data
        );
      }
    }
    export namespace warehouse {
      /**
       * @name 库房表删除
       * @param id 库房表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/warehouse/delete/${id}`
        );
      }
      /**
       * @name 库房表详情
       * @param id 库房表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Warehouse>>(
          `/other-v100001/warehouse/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  库房名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  使用人id(单选)
         */
        userIds?: string;
      }
      /**
       * @name 库房表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Warehouse>>>(
          "/other-v100001/warehouse/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  库房地址
         */
        addr: string;
        /**
         * @description  库房名称
         */
        name: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  服务门店id(多选)
         */
        serviceStoreId?: string;
        /**
         * @description  使用人id(多选)
         */
        userIds: string;
      }
      /**
       * @name 库房表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouse/save",
          data
        );
      }
      /**
       * @name 库房下拉框选择
       */
      export function selectVo() {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/other-v100001/warehouse/selectVo"
        );
      }
      export interface UpdateParams {
        /**
         * @description  库房地址
         */
        addr: string;
        /**
         * @description  库房名称
         */
        name: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  服务门店id(多选)
         */
        serviceStoreId?: string;
        /**
         * @description  使用人id(多选)
         */
        userIds: string;
      }
      /**
       * @name 库房表修改
       * @param id 库房表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/warehouse/update/${id}`,
          data
        );
      }
    }
    export namespace warehouseInventory {
      /**
       * @name 添加入库选择供应商，前端模糊搜索支持，如果匹配上把电话和地址填上去
       */
      export function getMerchantName() {
        return request.get<ResponseResult<Array<WarehouseSupplier>>>(
          "/other-v100001/warehouseInventory/getMerchantName"
        );
      }
      export interface InWarehouseInventoryParams {
        /**
         * @description  成本价（入）
         */
        costAmount: number;
        /**
         * @description  材料主键id
         */
        materialId: number;
        /**
         * @description  供应商地址
         */
        merchantAddr?: string;
        /**
         * @description  供应商名称
         */
        merchantName?: string;
        /**
         * @description  供应商电话
         */
        merchantPhone?: string;
        /**
         * @description  本次数量
         */
        num: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  库房id
         */
        warehouseId?: number;
        /**
         * @description  库房名称
         */
        warehouseName?: string;
      }
      /**
       * @name 材料入库新增
       * @param data
       */
      export function inWarehouseInventory(data: InWarehouseInventoryParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouseInventory/inWarehouseInventory",
          data
        );
      }
      /**
       * @name 库存出入表详情
       * @param id 库存出入表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<WarehouseInventory>>(
          `/other-v100001/warehouseInventory/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  维修id
         */
        maintenanceId?: number;
        /**
         * @description  材料主键id
         */
        materialId?: number;
        /**
         * @description  供应商名称
         */
        merchantName?: string;
        /**
         * @description  供应商电话
         */
        merchantPhone?: string;
        /**
         * @description  型号（入）
         */
        model?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  1表示入库 2表示出库  3丢失  4损坏
         */
        typeId?: number;
        /**
         * @description  使用人id
         */
        userId?: number;
        /**
         * @description  库房id
         */
        warehouseId?: number;
        /**
         * @description  库房名称
         */
        warehouseName?: string;
      }
      /**
       * @name 库存出入表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<WarehouseInventory>>>(
          "/other-v100001/warehouseInventory/list",
          { params }
        );
      }
      export interface OutInventoryParams {
        /**
         * @description  材料主键id
         */
        materialId: number;
        /**
         * @description  本次数量
         */
        num: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  类型：3丢失  4损坏
         */
        typeId: number;
        /**
         * @description  库房id
         */
        warehouseId?: number;
        /**
         * @description  库房名称
         */
        warehouseName?: string;
      }
      /**
       * @name 材料入库丢失或者损坏
       * @param data
       */
      export function outInventory(data: OutInventoryParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouseInventory/outInventory",
          data
        );
      }
      export interface OutMaintenanceInventoryParams {
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  材料主键id
         */
        materialId: number;
        /**
         * @description  本次数量
         */
        num: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  使用人id
         */
        userId?: number;
        /**
         * @description  库房id
         */
        warehouseId?: number;
        /**
         * @description  库房名称
         */
        warehouseName?: string;
      }
      /**
       * @name 物品维修出库
       * @param data
       */
      export function outMaintenanceInventory(
        data: OutMaintenanceInventoryParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouseInventory/outMaintenanceInventory",
          data
        );
      }
    }
    export namespace warehouseMaterial {
      /**
       * @name 材料明细表删除
       * @param id 材料明细表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/other-v100001/warehouseMaterial/delete/${id}`
        );
      }
      export interface GetMyWareHouseMaintenanceParams {
        /**
         * @description  分类id:pid 685
         */
        classifyId?: number;
        /**
         * @description  型号搜索条件
         */
        model?: string;
        /**
         * @description  物品名称
         */
        name?: string;
        /**
         * @description  仓库id
         */
        wareHouseId?: number;
      }
      /**
       * @name 维修结案使用：根据使用人仓库获取材料列表
       * @param params
       */
      export function getMyWareHouseMaintenance(
        params: GetMyWareHouseMaintenanceParams
      ) {
        return request.get<ResponseResult<Array<WarehouseMaterial>>>(
          "/other-v100001/warehouseMaterial/getMyWareHouseMaintenance",
          { params }
        );
      }
      /**
       * @name 材料明细表详情
       * @param id 材料明细表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<WarehouseMaterial>>(
          `/other-v100001/warehouseMaterial/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  分类id:pid:685
         */
        classifyId?: number;
        /**
         * @description  型号
         */
        model?: string;
        /**
         * @description  物品名称
         */
        name?: string;
        /**
         * @description  剩余数量不足
         */
        num?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  库房id
         */
        warehouseId?: number;
        /**
         * @description  库房名称
         */
        warehouseName?: string;
      }
      /**
       * @name 材料明细表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<WarehouseMaterial>>>(
          "/other-v100001/warehouseMaterial/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  分类id:pid:685
         */
        classifyId: number;
        /**
         * @description  材料提成价格
         */
        commission: number;
        /**
         * @description  成本价
         */
        costAmount: number;
        /**
         * @description  物品图片
         */
        img?: string;
        /**
         * @description  材料编号
         */
        materialNo: string;
        /**
         * @description  材料类型总表id
         */
        materialTypeId?: number;
        /**
         * @description  型号
         */
        model?: string;
        /**
         * @description  物品名称
         */
        name: string;
        /**
         * @description  初始数量
         */
        num: number;
        /**
         * @description  销售价
         */
        price: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  单位
         */
        unit: string;
        /**
         * @description  库房id
         */
        warehouseId: number;
      }
      /**
       * @name 材料明细表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouseMaterial/save",
          data
        );
      }
      export interface SetByTypeIdParams {
        /**
         * @description  主键id
         */
        id?: number;
        /**
         * @description  材料类型总表id
         */
        materialTypeId?: number;
      }
      /**
       * @name 材料绑定材料类型总表id
       * @param data
       */
      export function setByTypeId(data: SetByTypeIdParams) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouseMaterial/setByTypeId",
          data
        );
      }
      export interface SetMaterialNoParams {
        /**
         * @description  材料编号
         */
        materialNo?: string;
      }
      /**
       * @name setMaterialNo
       * @param id setMaterialNo
       * @param data
       */
      export function setMaterialNo(
        id: string | number,
        data: SetMaterialNoParams
      ) {
        return request.post<ResponseResult<object>>(
          `/other-v100001/warehouseMaterial/setMaterialNo/${id}`,
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  分类id:pid:685
         */
        classifyId: number;
        /**
         * @description  材料提成价格
         */
        commission: number;
        /**
         * @description  成本价
         */
        costAmount: number;
        /**
         * @description  物品图片
         */
        img?: string;
        /**
         * @description  材料编号
         */
        materialNo: string;
        /**
         * @description  材料类型总表id
         */
        materialTypeId?: number;
        /**
         * @description  型号
         */
        model?: string;
        /**
         * @description  物品名称
         */
        name: string;
        /**
         * @description  初始数量
         */
        num: number;
        /**
         * @description  销售价
         */
        price: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  单位
         */
        unit: string;
        /**
         * @description  库房id
         */
        warehouseId: number;
      }
      /**
       * @name 材料明细表修改
       * @param id 材料明细表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/other-v100001/warehouseMaterial/update/${id}`,
          data
        );
      }
      /**
       * @name 物品导入模版下载
       */
      export function createExcelTemplate() {
        return request.get<ResponseEntity>(
          "/other-v100001/warehouseMaterial/createExcelTemplate"
        );
      }
      export interface CreateExcelTemplateByMaterialNoParams {
        /**
         * @description  库房id
         */
        warehouseId?: number;
      }
      /**
       * @name 根据物品编号导入物品数量模版下载
       * @param params
       */
      export function createExcelTemplateByMaterialNo(
        params: CreateExcelTemplateByMaterialNoParams
      ) {
        return request.get(
          "/other-v100001/warehouseMaterial/createExcelTemplateByMaterialNo",
          { params }
        );
      }
      /**
       * @name 物品数据导入
       */
      export function insertImplMaterial(data: any) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouseMaterial/insertImplMaterial",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
      /**
       * @name 物品入库数量导入
       */
      export function insertImplMaterialV1(data: any) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/warehouseMaterial/insertImplMaterialV1",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
    }
    export namespace wxUserInfo {
      /**
       * @name automaticReplyList
       */
      export function automaticReplyList() {
        return request.get<ResponseResult<Array<WxAutomaticReply>>>(
          "/other-v100001/wxUserInfo/automaticReplyList"
        );
      }
      export interface GetWxUserInfoControllerParams {
        /**
         * @description  openid
         */
        openid?: string;
      }
      /**
       * @name 主动获取公众信息
       * @param data
       */
      export function getWxUserInfoController(
        data: GetWxUserInfoControllerParams
      ) {
        return request.post<ResponseResult<object>>(
          "/other-v100001/wxUserInfo/getWxUserInfoController",
          data
        );
      }
    }
  }
}
